import { Card, CardContent, Divider } from "@mui/material";
import {
  IHistoryVitalsPlotData,
  ISession,
  SleepStagesOptionsType,
} from "interfaces/sleephistory.interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelStyled } from "styled/CommonStyled";
import SleepHistoryStagesChart from "./charts/SleepHistoryStagesChart";
import SleepHistoryVitalsChart from "./charts/SleepHistoryVitalsChart";
import SessionDetailedView from "./components/SessionDetailedView";
import SleepHistoryToolBar from "./components/SleepHistoryToolBar";
import {
  chartType,
  ChartType,
  SleepHistorySelectedDates,
} from "./sleepHistoryTypes";
import { IFilter } from "../../interfaces/filters.interface";

interface HistoryModeProps {
  onNextPrev: (type: "next" | "prev") => void;
  isNoSleepData: boolean;
  sleepStagesOptions: SleepStagesOptionsType | undefined;
  sleepVitalsOptions: IHistoryVitalsPlotData | undefined;
  currentSession: ISession | undefined;
  sessions: ISession[] | undefined;
  selectedDates: SleepHistorySelectedDates;
  setSelectedDates: (dates: SleepHistorySelectedDates) => void;
  activeChart: ChartType;
  setActiveChart: (chart: ChartType) => void;
  setCurrentSession: (session?: ISession) => void;
  disableNext: boolean;
  disablePrev: boolean;
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: (update: (prev: IFilter) => IFilter) => void;
}

const HistoryMode = ({
  onNextPrev,
  isNoSleepData,
  sleepStagesOptions,
  sleepVitalsOptions,
  currentSession,
  sessions,
  selectedDates,
  setSelectedDates,
  activeChart,
  setActiveChart,
  setCurrentSession,
  disableNext,
  disablePrev,
  sleepHistoryFilters,
  setSleepHistoryFilters,
}: HistoryModeProps) => {
  const { t } = useTranslation();
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  const onChartMouseOver = (e: Event) => {
    if (e.target === null) return;
    const target: EventTarget & { sessionId?: string } = e.target;

    const sessionId = target.sessionId;
    const session = sessions?.find((item) => item.id === sessionId);
    if (session) {
      setCurrentSession(session);
    }
  };

  useEffect(() => {
    if (sessions === undefined) return;
    setMinDate(
      sessions
        .map((s: ISession) => new Date(s.session_start))
        .sort((a, b) => a.getTime() - b.getTime())[0],
    );
    setMaxDate(
      sessions
        .map((s: ISession) => new Date(s.session_start))
        .sort((a, b) => b.getTime() - a.getTime())[0],
    );
  }, [sessions]);

  return (
    <>
      <SleepHistoryToolBar
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        activeChart={activeChart}
        setActiveChart={setActiveChart}
        sleepHistoryFilters={sleepHistoryFilters}
        setSleepHistoryFilters={setSleepHistoryFilters}
      />
      <Card sx={{ height: "auto", marginBottom: 3 }}>
        {isNoSleepData ? (
          <LabelStyled sx={{ padding: 2, fontSize: 18 }}>
            {sessions?.length === 0
              ? t("no_sleep_data_to_show")
              : t("no_sleep_data_to_show_based_on_the_filter")}
          </LabelStyled>
        ) : (
          <>
            <CardContent>
              {sleepStagesOptions && (
                <>
                  {/* Workaround for https://vitalthings.atlassian.net/browse/WT-227  */}
                  {activeChart === chartType.area_chart && (
                    <SleepHistoryStagesChart
                      onChartMouseOver={onChartMouseOver}
                      options={sleepStagesOptions}
                      selectedDates={selectedDates}
                      chartType={chartType.area_chart}
                    />
                  )}
                  {activeChart === chartType.column_chart && (
                    <SleepHistoryStagesChart
                      onChartMouseOver={onChartMouseOver}
                      options={sleepStagesOptions}
                      selectedDates={selectedDates}
                      chartType={chartType.column_chart}
                    />
                  )}
                </>
              )}
            </CardContent>
            <Divider />
            <CardContent>
              {sleepVitalsOptions && (
                <SleepHistoryVitalsChart
                  onChartMouseOver={onChartMouseOver}
                  plotData={sleepVitalsOptions}
                  selectedDates={selectedDates}
                />
              )}
            </CardContent>
          </>
        )}
      </Card>
      {currentSession && (
        <SessionDetailedView
          disableNext={disableNext}
          disablePrev={disablePrev}
          session={currentSession}
          setCurrentSession={setCurrentSession}
          sessions={sessions}
          onNextPrev={onNextPrev}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </>
  );
};

export default HistoryMode;
