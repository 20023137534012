export const USER_SETTINGS_CONSTANTS = {
  all: "all",
  users_per_page: "users_per_page",
  enable_heart_rate_mean: "enable_heart_rate_mean",
  devices_per_page: "devices_per_page",
  employees_per_page: "employees_per_page",
  groups_per_page: "groups_per_page",
  reports_per_page: "reports_per_page",
  user_notifications_per_page: "user_notifications_per_page",
  user_notifications_recievers_per_page:
    "user_notifications_recievers_per_page",
  notification_default_user: "notification_default_user",
  set_history_view_mode: "set_history_view_mode",
  vital_signs_settings: "vital_signs_settings",
  sleep_epoch_settings: "sleep_epoch_settings",
};
