import Cookies from "js-cookie";
import { Route, Routes } from "react-router-dom";

import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useNavigate } from "hooks/useNavigate";
import rawRoutes from "../routes/routes";
import { ApplyBackround } from "styled/StylesStyled";
import { useEffect } from "react";

function AuthNew() {
  const { authUser } = useAuthenticatedUser();
  const navigate = useNavigate();

  const accessToken = Cookies.get("access_token");

  useEffect(() => {
    if (accessToken) {
      navigate("/monitor/overview");
    }
  }, [accessToken, navigate]);

  if (authUser === undefined) {
    return <div />;
  }

  return (
    <ApplyBackround>
      <Routes>
        {rawRoutes.map((route) => (
          <Route key={"path" in route ? route.path : ""} {...route} />
        ))}
      </Routes>
    </ApplyBackround>
  );
}
export default AuthNew;
