import { ISession } from "interfaces/sleephistory.interface";
import { roundToNearestMinutes } from "date-fns";

export type SleepStatisticsPlotData = {
  series: {
    timeAtStart: Date;
    timeAtSleep: Date | null;
    timeAtWakeup: Date | null;
    timeAsleepString: string;
    sleepOnsetString: string;
    sleepEfficiency: number | string;
    timesAwakeAway: string;
    timesAway: number;
    epochsWithMovement: number | string;
    nonREMMeanHeartRate: number | string;
    nonREMMeanExternalHeartRate: number;
    nonREMMeanRespirationRate: number | string;
    externalSpO2Mean: number;
  };
};

export const getSleepStatisticsPlotData = (
  session: ISession,
  formatDuration: (seconds: number) => string,
): SleepStatisticsPlotData | null => {
  if (!session) {
    return null;
  }
  const sleepReport = session;

  const timeAtStart = roundToNearestMinutes(
    new Date(sleepReport.time_at_intended_sleep),
  );

  const timeAtSleep = sleepReport.time_at_sleep_onset
    ? roundToNearestMinutes(new Date(sleepReport.time_at_sleep_onset))
    : null;
  const timeAtWakeup = sleepReport.time_at_wakeup
    ? roundToNearestMinutes(new Date(sleepReport.time_at_wakeup))
    : null;

  const sec =
    sleepReport.time_asleep > 0
      ? (sleepReport.sleep_onset_latency ?? 0)
      : sleepReport.time_in_bed;

  return {
    series: {
      timeAtStart,
      timeAtSleep,
      timeAtWakeup,
      timeAsleepString: formatDuration(sleepReport.time_asleep),
      sleepOnsetString: formatDuration(sec),
      sleepEfficiency: sleepReport.sleep_efficiency
        ? Math.floor(sleepReport.sleep_efficiency)
        : "",
      timesAwakeAway: [
        sleepReport.number_of_times_awake_long.toString(),
        sleepReport.number_of_times_no_presence,
      ].join("/"),
      timesAway: sleepReport.number_of_times_no_presence,
      epochsWithMovement: sleepReport.epochs_with_movement_pct
        ? Math.round(sleepReport.epochs_with_movement_pct)
        : "",
      nonREMMeanHeartRate: sleepReport.heart_rate_non_rem_filtered_mean
        ? Math.round(sleepReport.heart_rate_non_rem_filtered_mean)
        : "",
      nonREMMeanExternalHeartRate:
        sleepReport.external_heart_rate_non_rem_filtered_mean ?? 0,
      nonREMMeanRespirationRate: sleepReport.rpm_non_rem_filtered_mean
        ? Math.round(sleepReport.rpm_non_rem_filtered_mean)
        : "",
      externalSpO2Mean: sleepReport.external_spo2_mean ?? 0,
    },
  };
};
