import { useState } from "react";
import {
  Button,
  Menu,
  Stack,
  ListItemButton,
  ListItemIcon,
  Divider,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

import { getLocale } from "utils/date.util";

const ListIcon = styled(ListItemIcon)({
  minWidth: "28px",
});

type Interval = { start: Date; end: Date };

type Value = Interval & { type: string };

const getDateLabel = (value: Interval, formatter: Intl.DateTimeFormat) => {
  const from = formatter.format(value.start);
  const to = formatter.format(value.end);

  return `${from} - ${to}`;
};

const defaultFormatter = new Intl.DateTimeFormat(getLocale(), {
  month: "long",
  day: "numeric",
  year: "numeric",
});

type DateRangePickerProps = {
  value: Value;
  onChange: (value: Value) => void;
  options: {
    key: string;
    label: string;
    value: Interval;
  }[];
  formatter?: Intl.DateTimeFormat;
};

export function DateRangePicker({
  value,
  onChange,
  options,
  formatter = defaultFormatter,
}: DateRangePickerProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [showCustom, setShowCustom] = useState(value.type === "custom");

  const [customValue, setCustomValue] = useState<Value>({
    ...value,
    type: "custom",
  });

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        {getDateLabel(value, formatter)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        slotProps={{ paper: { sx: { width: "350px" } } }}
      >
        {options.map((option) => (
          <ListItemButton
            key={option.key}
            selected={value.type === option.key}
            onClick={() => {
              onChange({ ...option.value, type: option.key });
              setShowCustom(false);
              setAnchorEl(null);
            }}
          >
            <ListIcon>{value.type === option.key && <CheckIcon />}</ListIcon>
            {option.label}
          </ListItemButton>
        ))}
        <ListItemButton
          selected={value.type === "custom"}
          onClick={() => setShowCustom(true)}
        >
          <ListIcon>{value.type === "custom" && <CheckIcon />}</ListIcon>
          {t("custom")}
        </ListItemButton>
        {showCustom && (
          <div>
            <Divider />
            <Stack gap={1} paddingBlockStart={2} paddingInline={2}>
              <Stack direction={"row"} gap={1}>
                <DatePicker
                  onChange={(value) =>
                    value && setCustomValue((val) => ({ ...val, start: value }))
                  }
                  value={customValue.start}
                  maxDate={customValue.end ?? new Date()}
                />
                <DatePicker
                  onChange={(value) =>
                    value && setCustomValue((val) => ({ ...val, end: value }))
                  }
                  value={customValue.end}
                  minDate={customValue.start ?? undefined}
                  maxDate={new Date()}
                />
              </Stack>
              <Button
                variant={"contained"}
                onClick={() => {
                  onChange(customValue);
                  setAnchorEl(null);
                }}
                sx={{ alignSelf: "end" }}
              >
                {t("apply_filter")}
              </Button>
            </Stack>
          </div>
        )}
      </Menu>
    </>
  );
}
