import _ from "lodash";

import { IZone } from "interfaces/zone.interface";

const buildTree = (zones: IZone[], parentId: string | null = null) => {
  const filteredZones = zones.filter(
    (item: IZone) => item.parent_id === parentId,
  );
  return filteredZones.map((item: IZone): IZone => {
    const children = buildTree(zones, item.id);
    if (children.length > 0) item.children = children;
    return item;
  });
};

export const makeZoneTree = (data: IZone[]): IZone[] => {
  const deepClonedData = JSON.parse(JSON.stringify(data));
  return buildTree(
    deepClonedData.sort((a: IZone, b: IZone) =>
      Intl.Collator("nb", { sensitivity: "base" }).compare(
        a.name.toLocaleLowerCase(),
        b.name.toLocaleLowerCase(),
      ),
    ),
  );
};

// memoize zone tree
// export const makeZoneTree = memoize(makeZoneTreeFun);
/**
 * This function convert zone array as options
 *
 * @param data Array of type IZone
 * @return Options array [{id,name,value}[]]
 */
export const convertZonesAsOptions = (
  zoneList: IZone[] = [],
  authorizedZones: string[],
): {
  id: string;
  name: string;
  value: string;
  padding: number;
  disabled?: boolean;
}[] => {
  if (!zoneList.length) {
    return [];
  }
  return zoneList.map((zon: IZone) => {
    return {
      id: zon.id,
      name: zon.name,
      value: zon.id,
      padding: zon.level ?? 0,
      disabled: !authorizedZones.includes(zon.id),
    };
  });
};

/**
 * This function convert zone array as dropdwon options
 *
 * @param zoneList zone list array
 * @return Option[] dropwdown option list
 */
export const getZoneDropDownOptions = (
  zoneList: IZone[],
  authorizedZones: string[] = [],
) => {
  const zoneTree: IZone[] = makeZoneTree(zoneList);
  return convertZonesAsOptions(flatten(zoneTree[0], []), authorizedZones);
};

/**
 * This function convert tree array to flatten with omitting children
 *
 * @param item zone tree object
 * @return flattened array
 */
export const flatten = (item: IZone, arr: IZone[] = []) => {
  const childItem = { ...item };
  delete childItem.children;
  arr.push(childItem);
  if (item?.children?.length) {
    item.children.map((child: IZone) => flatten(child, arr));
  }
  return arr;
};

/**
 * This search through the tree function
 *
 * @param  element:tree item, id: match element id
 * @return tree element or null
 */

export const searchTree = (
  element: IZone,
  matchingID: string,
): IZone | null => {
  if (!element) return null;

  if (element.id === matchingID) return element;

  if (element.children === undefined) return null;

  let result: IZone | null = null;
  for (let i = 0; result == null && i < element.children.length; i += 1) {
    result = searchTree(element.children[i], matchingID);
  }
  return result;
};

/**
 * This function will update a single tree property
 *
 * @param id - id of the tree node
 *        data - tree node
 *        property - tree property
 *        value - tree property value
 * @return updated tree node
 */
export const updatePropertyById = (
  id: string,
  data: any,
  property: any,
  value: any,
) => {
  if (data.id === id) {
    data[property] = value;
  }
  if (data.children !== undefined && data.children.length > 0) {
    for (let i = 0; i < data.children.length; i += 1) {
      data.children[i] = updatePropertyById(
        id,
        data.children[i],
        property,
        value,
      );
    }
  }
  return data;
};

/**
 * This function convert tree array to flatten with omitting children
 * This function will filter out collapsed nodes and childs
 *
 * @param item zone tree object
 * @return flattened array
 */
function flattenWithNoCollapsed(item: IZone, arr: IZone[] = []) {
  const childItem = { ...item };
  delete childItem.children;
  arr.push(childItem);
  if (item?.children?.length) {
    const activeChilds =
      item?.children.filter((elem: IZone) => !elem.collapsed) || [];

    const collapsedParents =
      item?.children.filter((elem: IZone) => elem.collapsed) || [];

    collapsedParents.forEach((elem: IZone) => {
      const elemCopy = elem;
      delete elemCopy.children;
      arr.push(elemCopy);
    });

    activeChilds.map((child: IZone) => {
      return flattenWithNoCollapsed(child, arr);
    });
  }
  return arr;
}

/**
 * This function will count the number of open child nodes,
 * each node has a proprty collpased[true if node open / false id the node is collpased state]
 *
 * @param item zone tree object
 * @return number of opened child nodes
 */
export const numberOfOpenNodes = (item: IZone): number => {
  const flattenArr = flattenWithNoCollapsed(_.cloneDeep(item), []);
  return flattenArr.length || 0;
};

export const canAccessZone = (
  currentUserZones: string[],
  nodePaths: string[],
) => nodePaths.some((nodePath) => currentUserZones.includes(nodePath));

// get authorized parent zones
export const authorizedZones = (zones: IZone[], authUserZones: IZone[]) => {
  let selected: IZone[] = [];
  const zoneTree = makeZoneTree(zones);
  authUserZones.forEach((item) => {
    const parent = searchTree(zoneTree[0], item.id);
    if (parent) {
      const flattenArr = flatten(parent);
      selected = selected.concat(flattenArr);
    }
  });
  return selected;
};
