import CloseIcon from "@mui/icons-material/Close";
import { ChipProps, SvgIcon, Typography, Box } from "@mui/material";
import { StyledChip } from "styled/CommonStyled";

type FilterTagProps = ChipProps & {
  filterLabel: { filter?: string; text: string };
  background: "teal" | "blue" | "purple";
};

function FilterTag({
  filterLabel,
  background,
  onClick,
  onDelete,
}: FilterTagProps) {
  return (
    <StyledChip
      label={
        <Box sx={{ display: "flex", gap: 1 }}>
          {filterLabel.filter && (
            <Typography variant={"body2"}>{filterLabel.filter}</Typography>
          )}
          <Typography variant={"body2"} fontWeight={"500"}>
            {filterLabel.text}
          </Typography>
        </Box>
      }
      clickable
      onDelete={onDelete}
      onClick={onClick}
      deleteIcon={
        <SvgIcon>
          <CloseIcon sx={{ color: "#FFF" }} />
        </SvgIcon>
      }
      background={background}
    />
  );
}

export default FilterTag;
