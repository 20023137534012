import { Card } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

import { IColorValue } from "interfaces/color.interface";
import { CSSProperties } from "react";

interface BoxSubSectionStyledProps {
  width?: number | string;
  height?: string | number;
  bg?: "light" | "dark";
}
export const BoxSubSectionStyled = styled("div")(({
  width,
  height,
  bg,
}: BoxSubSectionStyledProps) => {
  const theme = useTheme();
  let background = "#FFF";
  if (bg === "light") {
    background = "#FFF";
  }
  return {
    border: `1px solid ${theme.palette.neutral[5]}`,
    boxSizing: "border-box",
    borderRadius: 8,
    float: "left",
    width: width || "100%",
    height: height || "100%",
    background,
  };
});

export const BoxSubSectionContentStyled = styled("div")(({
  width,
  padding,
}: {
  width?: string | number;
  padding?: string | number;
}) => {
  return {
    padding: padding || "16px 24px 34px 16px",
    float: "left",
    width: width || "100%",
    height: "100%",
  };
});

export const WrapperStyled = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  return {
    width: "100%",
    float: "left",
    ...sx,
  };
});

export const ContentWrapper = styled("div")(() => {
  return {
    height: "100%",
    width: "100%",
  };
});

export const MonitorCardStyled = styled(Card)(({
  pointer,
  state,
}: {
  pointer: number;
  state: string;
}) => {
  const theme = useTheme();
  let background: IColorValue;
  switch (state) {
    case "device_offline":
      background = theme.palette.neutral[5];
      break;
    case "device_inactive":
      background = theme.palette.neutral[5];
      break;
    case "device_sleep_tracking_disabled":
      background = theme.palette.neutral[5];
      break;
    case "not_in_bed":
      background = theme.palette.orange[30];
      break;
    case "awake_in_bed":
      background = theme.palette.blue[20];
      break;
    case "asleep":
      background = theme.palette.neutral[20];
      break;

    default:
      background = theme.palette.neutral[5];
  }
  return {
    background,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    cursor: pointer ? "pointer" : "not-allowed",
  };
});
