import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Popover } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { MouseEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AuthorizationContext,
  AuthorizationPermissionsType,
  UserPermissionEnum,
} from "authorization/AuthorizationContext";
import {
  ZoneUserRolesEnum,
  administrativeRoles,
  getAdministrativeUserRoles,
  getOperativeRoles,
  getServiceMonitorRole,
  operativeRoles,
} from "constants/userContstants";
import { IRole } from "interfaces/user.interface";
import { ChipStyled, FlexCenter } from "styled/CommonStyled";
import { ApplyMargin, ErrorMessage } from "styled/StylesStyled";

import RoleInformation, { TitleStyled } from "./RoleInformationPopover";
import { ControllerFieldState } from "react-hook-form";

export const RolesPermissionWrapper = styled(Box)(() => {
  const theme = useTheme();
  return {
    fontSize: 14,
    padding: "16px 16px 10px 16px",
    boxShadow:
      "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
    minWidth: 508,
    background: "#FFF",
    border: `4px solid ${theme.palette.main[70]}`,
    borderRadius: 4,
  };
});

export const RolesSelectedWrapper = styled(Box)(() => {
  return {
    fontSize: 14,
    padding: 0,
    background: "#FFF",
    marginTop: 16,
    float: "left",
  };
});

function UserRoles({
  roles,
  fieldState,
  onRolesUpdate,
  disabled = false,
  authUserRoleHigherOrder,
}: {
  roles: ZoneUserRolesEnum[];
  fieldState: ControllerFieldState;
  onRolesUpdate: (roles: ZoneUserRolesEnum[]) => void;
  disabled?: boolean;
  authUserRoleHigherOrder: number;
}) {
  const { t } = useTranslation();
  const { permissions } = useContext(AuthorizationContext);
  const [selectedRoles, setSelectedRoles] = useState<ZoneUserRolesEnum[]>(
    roles || [],
  );

  const [selectedHigherOrder, setSelectedHigherOrder] = useState<{
    administrative: number;
    operative: number;
  }>({ administrative: 0, operative: 0 });

  const [currentPopoverRole, setCurrentPopoverRole] = useState<IRole>();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: MouseEvent<HTMLElement>,
    currentRole: IRole,
  ) => {
    setCurrentPopoverRole(currentRole);
    setAnchorEl(event.currentTarget);
  };

  // handle popover close
  const handlePopoverClose = () => {
    setCurrentPopoverRole(undefined);
    setAnchorEl(null);
  };

  //
  useEffect(() => {
    let adminHigherOrder = selectedHigherOrder.administrative;
    let operativeHigherOrder = selectedHigherOrder.operative;
    if (!selectedRoles.length) {
      operativeHigherOrder = 0;
      adminHigherOrder = 0;
    }
    selectedRoles.forEach((item) => {
      if (
        (item === "DATA_READER_LIMITED" || item === "DATA_READER") &&
        operativeRoles[item] > operativeHigherOrder
      ) {
        operativeHigherOrder = operativeRoles[item];
      }
      ///
      if (
        (item === "MANAGER_LIMITED" ||
          item === "MANAGER" ||
          item === "ADMIN" ||
          item === "OWNER") &&
        administrativeRoles[item] > adminHigherOrder
      ) {
        adminHigherOrder = administrativeRoles[item];
      }
    });
    setSelectedHigherOrder({
      administrative: adminHigherOrder,
      operative: operativeHigherOrder,
    });
  }, [
    selectedRoles,
    selectedHigherOrder.administrative,
    selectedHigherOrder.operative,
  ]);

  useEffect(() => {
    // set user roles
    setSelectedRoles(roles);
  }, [roles]);

  // roles click
  const bulkDelete = (items: IRole[], selected: ZoneUserRolesEnum[]) => {
    items.forEach((item) => {
      if (selected.indexOf(item.value) !== -1) {
        selected.splice(selected.indexOf(item.value), 1);
      }
    });
    return selected;
  };
  const onRolesClick = (role: IRole) => {
    let selected = [...selectedRoles];
    if (selected.indexOf(role.value) === -1) {
      selected = bulkDelete(
        role.type === "operative"
          ? getOperativeRoles(t)
          : getAdministrativeUserRoles(t),
        selected,
      );
      selected.push(role.value);
    } else {
      selected.splice(selected.indexOf(role.value), 1);
    }

    // update the form
    onRolesUpdate(selected);
    setSelectedRoles(selected);
  };

  const { error } = fieldState;
  const isActive = (roleValue: ZoneUserRolesEnum) => {
    return selectedRoles.indexOf(roleValue) !== -1;
  };

  const isHigherorderSelected = (currenRole: IRole) => {
    if (
      currenRole.type === "administrative" &&
      currenRole.order < selectedHigherOrder.administrative
    ) {
      return true;
    }
    if (
      currenRole.type === "operative" &&
      currenRole.order < selectedHigherOrder.operative
    ) {
      return true;
    }
    return false;
  };

  // auth user not allowed
  const isAuthUserHasLessPrivileged = (currenRole: IRole) => {
    if (
      currenRole.type === "administrative" &&
      authUserRoleHigherOrder < selectedHigherOrder.administrative
    ) {
      return true;
    }
    return false;
  };

  const userCanAlterRole = (role: IRole) => {
    if (disabled) {
      return false;
    }

    // if auth user not allowed to edit
    if (isAuthUserHasLessPrivileged(role)) {
      return false;
    }

    const policy: AuthorizationPermissionsType =
      UserPermissionEnum[`user.manage_role_assignment:${role.value}`];
    if (permissions.includes(policy)) {
      return true;
    }
    return false;
  };

  const roleIsCompatible = (role: IRole) => {
    // current role is not Service Account, and Service Account is selected
    if (
      role.value !== ZoneUserRolesEnum.SERVICE_MONITOR &&
      selectedRoles.includes(ZoneUserRolesEnum.SERVICE_MONITOR)
    ) {
      return false;
    }

    // current role is Service Account and at least one other role is selected
    if (
      role.value === ZoneUserRolesEnum.SERVICE_MONITOR &&
      !selectedRoles.includes(ZoneUserRolesEnum.SERVICE_MONITOR) &&
      selectedRoles.length > 0
    ) {
      return false;
    }

    return true;
  };

  const roleIsEnabled = (role: IRole) => {
    return userCanAlterRole(role) && roleIsCompatible(role);
  };

  const getPopoverTitle = (role: IRole) => {
    if (!role) {
      return "";
    } else if (!userCanAlterRole(role)) {
      return t("you_do_not_have_permission_to_change_this_role");
    } else if (!roleIsCompatible(role)) {
      if (role.value === ZoneUserRolesEnum.SERVICE_MONITOR) {
        return t("this_role_is_not_compatible_with_other_roles");
      } else {
        return t("this_role_is_incompatible_with_a_currently_selected_role");
      }
    } else {
      return "";
    }
  };

  const getChipIcon = (role: IRole) => {
    if (isActive(role.value)) {
      return (
        <FlexCenter>
          {!roleIsEnabled(role) && <LockIcon fontSize="small" />}
          <CheckIcon
            fontSize="small"
            style={{ marginLeft: 0, marginRight: 10 }}
          />
        </FlexCenter>
      );
    }
    if (!roleIsEnabled(role)) {
      return (
        <LockIcon fontSize="small" style={{ marginLeft: 0, marginRight: 6 }} />
      );
    }

    // is higherorder selected
    if (isHigherorderSelected(role)) {
      return undefined;
    }
    return undefined;
  };

  const chipOnClick = (role: IRole) => {
    if (roleIsEnabled(role)) {
      onRolesClick(role);
    }
  };

  const getBgTheme = (role: IRole) => {
    if (isActive(role.value)) {
      return role.activeChipTheme;
    }
    if (!roleIsEnabled(role)) {
      return "disabled";
    }
    if (isHigherorderSelected(role)) {
      return "grey";
    }

    return "grey";
  };

  const getPopoverHeight = (role: IRole) => {
    if (!roleIsEnabled(role)) {
      return 479;
    }
    return 420;
  };

  const open = Boolean(anchorEl);
  const ref = useRef<any>(null);
  const popoverContentRef = useRef<any>(null);
  const serviceMonitorRole = getServiceMonitorRole(t);
  return (
    <div>
      {currentPopoverRole && (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          // open
          container={ref.current}
          anchorEl={anchorEl}
          // anchorOrigin={{
          //   vertical: "top",
          //   horizontal: "left"
          // }}
          transformOrigin={{
            vertical: getPopoverHeight(currentPopoverRole) || "bottom",
            horizontal: "left",
          }}
          marginThreshold={0}
          disableRestoreFocus
        >
          <RolesPermissionWrapper>
            <RoleInformation
              notAllowedTitle={getPopoverTitle(currentPopoverRole)}
              roles={[currentPopoverRole.value]}
              title={t("this_role_allows_the_user_to")}
              contentRef={popoverContentRef}
            />
          </RolesPermissionWrapper>
        </Popover>
      )}
      <ApplyMargin top={10} style={{ position: "relative" }} ref={ref}>
        <ApplyMargin top={12}>
          <TitleStyled>{t("administrative_roles")}</TitleStyled>
          {getAdministrativeUserRoles(t).map((role) => {
            return (
              // <Tooltip placement="top" title={getTooltip(role)} disableInteractive>
              <ChipStyled
                key={role.name}
                bg={getBgTheme(role)}
                // @ts-ignore
                sx={{
                  marginRight: "8px",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
                label={role.name}
                style={{ pointerEvents: "auto" }}
                onClick={() => chipOnClick(role)}
                icon={getChipIcon(role)}
                onMouseEnter={(e) => {
                  handlePopoverOpen(e, role);
                }}
                onMouseLeave={handlePopoverClose}
                // onMouseEnter={()=>onMouseEnter(role)} onMouseOut={()=>onMouseOut(role)}
              />
              // </Tooltip>
            );
          })}
        </ApplyMargin>
        <ApplyMargin top={12}>
          <TitleStyled>{t("operative_roles")}</TitleStyled>
          {getOperativeRoles(t).map((role) => {
            return (
              <ChipStyled
                key={role.name}
                bg={getBgTheme(role)}
                label={role.name}
                sx={{
                  marginRight: "8px",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
                style={{ pointerEvents: "auto" }}
                onClick={() => chipOnClick(role)}
                icon={getChipIcon(role)}
                onMouseEnter={(e) => {
                  handlePopoverOpen(e, role);
                }}
                onMouseLeave={handlePopoverClose}
              />
            );
          })}
        </ApplyMargin>
        <ApplyMargin top={12}>
          <TitleStyled>{t("service_account_role_category")}</TitleStyled>
          <ChipStyled
            key={serviceMonitorRole.name}
            bg={getBgTheme(serviceMonitorRole)}
            label={serviceMonitorRole.name}
            sx={{ marginRight: "8px", marginBottom: "8px", cursor: "pointer" }}
            style={{ pointerEvents: "auto" }}
            onClick={() => chipOnClick(serviceMonitorRole)}
            icon={getChipIcon(serviceMonitorRole)}
            onMouseEnter={(e) => {
              handlePopoverOpen(e, serviceMonitorRole);
            }}
            onMouseLeave={handlePopoverClose}
          />
        </ApplyMargin>
        {error?.message ? <ErrorMessage>{error?.message}</ErrorMessage> : ""}
        {selectedRoles?.length ? (
          <RolesSelectedWrapper>
            <RoleInformation
              roles={selectedRoles}
              title={t("the_selected_role_allows_the_user_to")}
            />
          </RolesSelectedWrapper>
        ) : (
          ""
        )}
      </ApplyMargin>
    </div>
  );
}
export default UserRoles;

//  disableMouseover={isDisabled(role) ? 1 : 0}
// const getTooltip = (role: IRole) => {
//   if (isDisabled(role)) {
//     return (
//       <span>
//         {role.tooltip}{" "}
//         {`${t("you_do_not_have_permission_to_change_this_role")}`}
//       </span>
//     );
//   }
//   return role.tooltip;
// };
