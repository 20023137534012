import { Alert, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import {
  getAdminRedirectPath,
  getUserPermission,
  isAllowed,
} from "authorization/authorization.util";
import {
  AuthorizationContext,
  AuthorizationPermissionsType,
} from "authorization/AuthorizationContext";
import SMAdminAppBar from "components/SMAdminAppBar/SMAdminAppBar";
import useNetworkStatus from "hooks/UseNetworkStatus";
import { AppRoute } from "interfaces/router.interface";
import ConfirmDialogProvider from "providers/ConfirmDialogProvider";
import DialogProvider from "providers/DialogProvider";

import routes from "../routes/admin.routes";
import RequireAuth from "../services/utils/RequireAuth";
import { useAppSelector } from "store";

const FallbackRedirect = () => {
  const params = useParams();

  return <Navigate to={"/administration/" + params["*"]} />;
};

function AdminLayout() {
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const authState = useAppSelector((state) => state.authReducerV1);
  const [permissions, setPermissions] = useState<
    AuthorizationPermissionsType[]
  >([]);

  useEffect(() => {
    if (!authState.user) return;
    const userPermissions = getUserPermission(authState?.user);
    setPermissions(userPermissions);
  }, [authState.user]);

  const filteredRoutes = (rawRoutes: AppRoute[]) =>
    rawRoutes.filter(
      (route) =>
        authState.user && isAllowed(authState.user, route.allowedRoles ?? []),
    );

  return (
    <AuthorizationContext.Provider
      value={{
        permissions,
      }}
    >
      <DialogProvider>
        <ConfirmDialogProvider>
          <Container maxWidth={false} disableGutters>
            <SMAdminAppBar
              screens={filteredRoutes(routes) || []}
              loginedUser={authState.user}
            />
            {!isOnline && (
              <Alert variant="filled" severity="error" sx={{ borderRadius: 0 }}>
                {t("No internet connection")}
              </Alert>
            )}
            <Routes>
              {/* TODO: Remove once we are certain old URL are no longer used */}
              <Route
                path={"adminstration"}
                element={<Navigate to={"/administration"} />}
              />
              <Route path={"adminstration/*"} element={<FallbackRedirect />} />
              {routes.map((route) => (
                <Route
                  key={"path" in route ? route.path : ""}
                  element={<RequireAuth roles={route.allowedRoles} />}
                >
                  <Route {...route} />
                </Route>
              ))}
              <Route
                path={"*"}
                element={<Navigate to={getAdminRedirectPath()} />}
              />
            </Routes>
          </Container>
        </ConfirmDialogProvider>
      </DialogProvider>
    </AuthorizationContext.Provider>
  );
}

export default AdminLayout;
