import { combineReducers } from "redux";

import { api } from "api";

import deviceReducer from "./administration/deviceReducer";
import subjectReducer from "./administration/subjectReducer";
import tenantReducer from "./administration/tenantReducer";
import usersReducer from "./administration/userReducer";
import zoneReducer from "./administration/zoneReducer";
import authReducerV1 from "./auth/authReducerV1";
import liveMonitorReducer from "./dashboard/monitorReducer";
import nightReportReducer from "./dashboard/nightReportReducer";
import notificationLogReducer from "./notification-logs/logsReducer";
import notificationReducer from "./notifications/notificationsReducer";
import rulesReducer from "./rules/rulesReducer";
import sleepEpochReducer from "./sleephistory/sleepEpochReducer";
import sleepHistoryReducer from "./sleephistory/sleephistoryReducer";

export const rootReducer = combineReducers({
  zoneReducer,
  subjectReducer,
  tenantReducer,
  deviceReducer,
  usersReducer,
  liveMonitorReducer,
  nightReportReducer,
  authReducerV1,
  sleepHistoryReducer,
  sleepEpochReducer,
  notificationReducer,
  rulesReducer,
  notificationLogReducer,
  [api.reducerPath]: api.reducer,
});
