import { CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { clearSleepHistory } from "store/actions/sleephistory/sleephistoryActions";
import { setLocalSettings } from "utils/localStorage";
import { useAppDispatch } from "store";
import { ISession } from "interfaces/sleephistory.interface";

import { type ViewMode } from "../sleepHistoryTypes";

type SleepHistoryHeaderProps = {
  viewMode: ViewMode;
  setViewMode: (string: ViewMode) => void;
  title: string;
  showSleepHistory: boolean;
  setCurrentSession: (session?: ISession) => void;
};

function SleepHistoryHeader({
  viewMode,
  setViewMode,
  title,
  showSleepHistory,
  setCurrentSession,
}: SleepHistoryHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleAlignment = (
    event: MouseEvent<HTMLElement>,
    newMode: ViewMode,
  ) => {
    if (newMode !== null) {
      // reducer cache fix
      setCurrentSession(undefined);
      dispatch(clearSleepHistory());
      // end
      setViewMode(newMode);
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: newMode,
      });
    }
  };

  return (
    <CardHeader
      title={title}
      action={
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleAlignment}
        >
          {showSleepHistory && (
            <ToggleButton value="history">{t("History")}</ToggleButton>
          )}
          <ToggleButton value="last_night">{t("Last night")}</ToggleButton>
        </ToggleButtonGroup>
      }
    />
  );
}

export default SleepHistoryHeader;
