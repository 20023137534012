import { AxiosResponse } from "axios";

import { clearCookies } from "utils/auth/auth.util";
import { toastErrorPositionCenter } from "utils/toast.util";

// Success handler
export async function handleResponse(response: any) {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  ) {
    return response.data;
  }
  if (response.status === 400) {
    // const error = await response.text();
    throw new Error("server error");
  }

  throw new Error("Network response was not ok.");
}

// Error Handler. In a prod app, would likely call an error logging service.
export function handleError(error: any) {
  if (error?.response?.status === 401) {
    clearCookies();
    window.location.reload();
    // gotTo(`${window.location.origin}/${LOGIN_PAGE}?redirectreason=unauthorize`, false);
  }
  console.error(`API call failed. ${error}`);
}

export const showErrorFeedback = (error: any) => {
  if (error?.response?.status === 400) {
    // show error toast here
  }
  if (error?.response?.status === 403) {
    // show error toast here
  }
  if (error?.response?.status === 500) {
    // show error toast here
    toastErrorPositionCenter(
      `API call failed. Error: Request failed with status code 500`,
    );
  }
  if (error?.response?.status === 404) {
    // show error toast here
  }
  const { detail } = error?.response?.data || { detail: null };
  if (detail && Array.isArray(detail)) {
    detail.forEach((item: any) => {
      if (item?.msg) {
        toastErrorPositionCenter(`${item.msg}`);
      }
    });
  }

  if (typeof detail === "string") {
    toastErrorPositionCenter(detail);
  }
};

export const showMultipleErrorFeedback = (
  response:
    | PromiseSettledResult<AxiosResponse<any>>[]
    | PromiseRejectedResult[],
) => {
  const errors: PromiseRejectedResult[] = response.filter(
    (item) => item.status === "rejected",
  ) as PromiseRejectedResult[];
  if (errors && errors?.length) {
    errors.forEach((error) => {
      const resp = error.reason || "";
      if (resp) {
        showErrorFeedback(resp);
      }
    });
  }
};
