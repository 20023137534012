import { AppBar, Box, Grid, Stack } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { useTheme, styled } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { LogoIcon } from "components/icons";
import SMButtonNew from "components/SMButton/SMButtonNew";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import WIPMessage from "components/WIPMessage/WIPMessage";
import { PROD_API_BASE_URL } from "constants/constants";
import { fetchTenants, resetTenantState } from "store/actions/auth/authActions";
import { ApplyMargin, ErrorMessage } from "styled/StylesStyled";
import * as LOCAL_STORAGE from "utils/localStorage";

import APISelector from "./APISelector";
import { useNavigate } from "hooks/useNavigate";
import { useAppDispatch, useAppSelector } from "store";

const SMLogo = styled(LogoIcon)(() => {
  return {
    marginRight: 35,
    cursor: "pointer",
  };
});

const BodyBg = styled("div")(() => {
  const theme = useTheme();
  return {
    background: theme.palette.neutral[3],
  };
});

const StyledP = styled("p")(() => {
  const theme = useTheme();
  return {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 32,
    lineHeight: "40px",
    color: theme.palette.neutral[80],
    margin: 0,
    marginBottom: 37,
  };
});

function LoginLanding() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.authReducerV1);
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectreason = new URLSearchParams(search).get("redirectreason");
  const [loginEmail, setLoginEmail] = useState<string>();
  const defaultApiEndpoint = LOCAL_STORAGE.getCustomAPIEndPoint() || "";
  const defaultAuthBackend = LOCAL_STORAGE.getCustomAuthBackend() || "";

  // last email
  const lastEmail: string | null = localStorage.getItem("LAST_USED_EMAIL");

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: lastEmail ?? "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    return () => {
      dispatch(resetTenantState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (authState.tenants?.length && loginEmail) {
      navigate(`/auth/tenants`, {
        state: { tenants: authState.tenants, email: loginEmail },
      });
    }
  }, [authState, loginEmail, navigate]);

  const onSubmit = ({ email }: { email: string }) => {
    if (email) {
      // set login email
      setLoginEmail(email);
      dispatch(fetchTenants(email || ""));
    }
  };

  const onUpdateAPIEndPoint = (endpoint: string) => {
    LOCAL_STORAGE.setCustomAPIEndPoint(endpoint);
    axios.defaults.baseURL = endpoint || PROD_API_BASE_URL;
  };

  const onUpdateAuthBackend = (backend: string) => {
    LOCAL_STORAGE.setCustomAuthBackend(backend);
  };

  const emailWatch = watch("email");

  return (
    <BodyBg>
      <AppBar
        position="fixed"
        sx={{ background: "#FFFFFF", boxShadow: "none" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <SMLogo />
          {import.meta.env.VITE_AUTH_BACKENDS &&
            import.meta.env.VITE_API_END_POINTS && (
              <Stack direction="row" spacing={1}>
                <APISelector
                  defaultApi={defaultAuthBackend}
                  urls={import.meta.env.VITE_AUTH_BACKENDS.split(",")}
                  label="Auth Backend"
                  onUpdate={onUpdateAuthBackend}
                />
                <APISelector
                  defaultApi={defaultApiEndpoint}
                  urls={import.meta.env.VITE_API_END_POINTS.split(",")}
                  label="API Endpoint"
                  onUpdate={onUpdateAPIEndPoint}
                />
              </Stack>
            )}
        </Toolbar>
      </AppBar>

      {/* Warning message */}
      <WIPMessage />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} textAlign="center">
            {authState.tenants !== undefined && !authState.tenants?.length && (
              <ErrorMessage sx={{ marginBottom: 15 }}>
                {t("no_tenants_for_this_email")}
              </ErrorMessage>
            )}
            <ErrorMessage sx={{ marginBottom: 15 }}>
              {redirectreason === "unauthorize"
                ? t("your_session_has_expired_please_login_again_to_continue")
                : ""}
            </ErrorMessage>

            <StyledP>{t("your_email_address")}</StyledP>
            <Box sx={{ width: "80%", margin: "0 auto" }}>
              <Controller
                rules={{
                  required: `${t("error_message_your_email")}`,
                }}
                name="email"
                control={control}
                render={({ field, fieldState, formState }) => (
                  <SMFormTextField
                    field={field}
                    placeholder={t("email_id_placeholder")}
                    formState={formState}
                    fieldState={fieldState}
                    inlineEdit={false}
                    withFocusStyle={!!field.value}
                    onCancel={() => {}}
                    onUpdate={() => {
                      handleSubmit(onSubmit)();
                    }}
                  />
                )}
              />
            </Box>
            <ApplyMargin top={37}>
              <SMButtonNew
                text={t("Continue")}
                onClick={() => {}}
                disabled={!!errors.email || !emailWatch}
                submit
              />
            </ApplyMargin>
          </Grid>
        </Grid>
      </form>
    </BodyBg>
  );
}
export default LoginLanding;
