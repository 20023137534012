import { Grid } from "@mui/material";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import SMConfirmDialogNoLabel from "components/SMDialogs/SMConfirmDialogNoLabel";
import Maintitle from "components/SMTitles/MainTitle";
import SMUpdateCancel from "components/SMUpdateCancel/SMUpdateCancel";
import UseAdminContext from "hooks/UseAdminContext";
import { ISubject } from "interfaces/subject.interface";
import { useCallback, useEffect, useState } from "react";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  createSubject,
  updateSubject,
} from "store/actions/administration/subjectActions";
import { getTenantByID } from "store/actions/administration/tenantActions";
import { resetRulesState } from "store/actions/rules/rules";
import { ApplyMargin } from "styled/StylesStyled";
import getErrorInputElement from "utils/common/getErrorInputElement";
import { useNavigate } from "hooks/useNavigate";
import { setZone } from "utils/localStorage";
import { toastSuccessPositionCenter } from "utils/toast.util";
import AdditionalSubjectInformation from "./components/AdditionalSubjectInformationCreate";
import SubjectInformation from "./components/SubjectInformationCreate";
import { useAppDispatch, useAppSelector } from "store";

function CreateSubject() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [subject, setSubject] = useState<ISubject>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // context state
  const { selectedZone, setSelectedZone, zoneEntity } = UseAdminContext();

  const tenantState = useAppSelector((state) => state.tenantReducer);

  // fetch tenant if not loaded
  useEffect(() => {
    if (zoneEntity && !selectedZone) {
      const zones = Object.keys(zoneEntity);
      if (setSelectedZone && zones.length !== 0) {
        setSelectedZone(zones[0]);
      }
    }
    if (
      zoneEntity &&
      selectedZone &&
      zoneEntity[selectedZone] &&
      zoneEntity[selectedZone]?.tenant_id
    ) {
      const tenant_id = zoneEntity[selectedZone].tenant_id;
      dispatch(getTenantByID(tenant_id));
    }
  }, [zoneEntity, selectedZone, setSelectedZone, dispatch]);

  // Set fields to their default values
  const custom_fields_dict: { [key: string]: string } = {};
  if (tenantState.tenant?.custom_fields) {
    for (const key in Object.keys(tenantState.tenant.custom_fields)) {
      const customField = tenantState.tenant.custom_fields[key];
      if (customField.default) {
        custom_fields_dict[customField.id] = `${customField.default}`;
      }
    }
  }

  if (subject === undefined) {
    const emptySubject: ISubject = {
      id: "",
      zone_id: "",
      height: "",
      weight: "",
      identifier: "",
      sex: "0",
      birth_year: "",
      custom_fields: custom_fields_dict,
    };
    setSubject(emptySubject);
  }

  const { handleSubmit, control, reset, resetField, getValues } =
    useForm<ISubject>({
      defaultValues: {
        ...subject,
        // preset-values
        zone_id: selectedZone || subject?.zone_id,

        // to fix null value
        name: subject?.name || "",
        sex: subject?.sex || "0",
        birth_year: subject?.birth_year || "",
        height: subject?.height || "",
        weight: subject?.weight || "",
        custom_fields: subject?.custom_fields || custom_fields_dict,
      },
      mode: "onTouched",
    });

  const updateSubjectErrorCB = () => {
    resetFormFields();
  };

  const resetFormFields = useCallback(() => {
    reset({
      ...subject,
      // preset-values
      zone_id: selectedZone || "",

      // to fix null value
      name: subject?.name || "",
      birth_year: subject?.birth_year || "",
      height: subject?.height || "",
      weight: subject?.weight || "",
      custom_fields: subject?.custom_fields || {},
    });
  }, [reset, selectedZone, subject]);

  useEffect(() => {
    resetFormFields();
  }, [subject, resetFormFields]);

  const successCallback = () => {
    const data: ISubject = getValues();
    if (setSelectedZone) {
      setSelectedZone(data.zone_id);
    }
  };

  // dispatch subject
  const dispatchUpdateSubject = (
    sub: ISubject,
    removeDevice: boolean,
    refreshRules?: boolean,
  ) => {
    if (refreshRules) {
      // reset rules, becuase zone changed
      dispatch(resetRulesState());
    }
    dispatch(
      updateSubject({
        subject: sub,
        removeDevice,
        updateSubjectErrorCB,
        successCallback,
        refreshRules: !!refreshRules,
      }),
    );
  };

  // if the zone is changed then we should updated the devices associated
  // also update the rules
  const onUpdateZone = () => {
    const data: ISubject = getValues();
    dispatchUpdateSubject(data, true, true);
  };

  // Create subject success callback
  const createSubjectSuccessCB = (item: ISubject) => {
    // shpw toast message
    toastSuccessPositionCenter(t("Subject created successfully"));

    // set zone and redirect to the subject view page
    if (item && item.id && item.zone_id) {
      if (setSelectedZone) {
        setSelectedZone(item.zone_id);
      }
      setZone(item.zone_id);
      navigate(`/administration/zones/subjects/${item.id}`);
    }
  };

  // auto focus first element on Error
  const errorFocusOrder: { [key: string]: string } = {
    identifier: "a",
    sex: "b",
    birth_year: "c",
  };

  // on Error
  function onErrors(err: FieldErrors<ISubject>) {
    const { elem, key } = getErrorInputElement<ISubject>(err, errorFocusOrder);
    // set the focus
    if (elem) {
      if (key === "birth_year") {
        const firstInput = Array.from(elem.querySelectorAll("input")).find(
          () => true,
        );
        if (firstInput) {
          firstInput.focus();
        }
      } else {
        elem.focus();
      }
    }
  }

  // reset the field if the user doesnt want to change the zone
  const cancelZoneUpdate = () => {
    resetField("zone_id");
  };

  const onSubmit: SubmitHandler<ISubject> = (data: ISubject) => {
    for (const key in data.custom_fields) {
      if (key && data.custom_fields[key] && data.custom_fields[key] === "0") {
        delete data.custom_fields[key];
      }
    }
    dispatch(createSubject(data, createSubjectSuccessCB));
    reset();
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <BoxPadding pad={24}>
            <ApplyMargin bottom={20}>
              <Maintitle text={t("Create Subject")} showBack />
            </ApplyMargin>

            <Grid container rowSpacing={0} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                {subject && (
                  <SubjectInformation
                    create={true}
                    subject={subject}
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    control={control}
                    resetField={resetField}
                    setOpenDialog={setOpenDialog}
                  />
                )}
              </Grid>
            </Grid>

            {subject &&
              tenantState?.tenant &&
              tenantState?.tenant?.custom_fields &&
              tenantState?.tenant?.custom_fields?.length !== 0 && (
                <Grid item xs={12} md={12} sx={{ marginTop: 2.5 }}>
                  {subject && (
                    <AdditionalSubjectInformation
                      create={true}
                      tenant={tenantState.tenant}
                      onSubmit={onSubmit}
                      handleSubmit={handleSubmit}
                      control={control}
                    />
                  )}
                </Grid>
              )}

            <SMConfirmDialogNoLabel
              title={t("update_subject_change_zone_popup_title")}
              dialogDesc={`${t("update_subject_change_zone_popup_body")}`}
              onDelete={onUpdateZone}
              buttonOk={t("ok")}
              buttonCancel={t("Cancel")}
              okButtonBg="green"
              open={openDialog}
              setOpen={setOpenDialog}
              onCancel={cancelZoneUpdate}
            />

            <SMUpdateCancel
              onCancel={() => navigate(-1)}
              onUpdate={handleSubmit(onSubmit, onErrors)}
              create={true}
            />
          </BoxPadding>
        </form>
      </div>
    </>
  );
}
export default CreateSubject;
