import axios from "axios";

import { API_SERVICES } from "../../constants/constants";
import { handleError, handleResponse } from "../responseHandler";
import { SUPPORTED_DEVICE_TYPES } from "constants/deviceConstants";

export const monitorServices = {
  fetchLiveMonitor,
};

/**
 * This function fetch live monitor data
 * @param none
 * @return all the monitor data
 */
async function fetchLiveMonitor(zones: string[]) {
  const deviceTypesParam = `device_types=${SUPPORTED_DEVICE_TYPES.join(
    "&device_types=",
  )}`;
  const zonesParam = `&zones=${zones.join("&zones=")}`;
  const url = `${API_SERVICES.liveMonitoring}?${deviceTypesParam}&include_unassigned_devices=true${zonesParam}`;

  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
