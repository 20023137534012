import {
  Checkbox,
  FormControlLabel,
  Grid,
  checkboxClasses,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SMFormDiscreteSlider from "components/SMFormComponents/SMFormDiscreteSlider";
import TreeViewWithCheckbox from "components/TreeView/TreeViewWithCheckbox/TreeViewWithCheckbox";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { InputLabelStyled } from "styled/CommonStyled";

import { ITileSettings } from "./DashboardScreen";

const FormControlLabelStyled = styled(FormControlLabel)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    fontSize: 16,
    padding: 6,
    margin: 0,
    textTransform: "none",
    "& .MuiFormControlLabel-label": {
      fontSize: 16,
    },
    [`& .${checkboxClasses.root}`]: {
      color: theme.palette.main[70],
      width: 12,
      height: 12,
      marginLeft: 1,
      marginRight: 10,
      p: 0,
      borderRadius: 0,
    },
  };
});

type IProps = {
  type: "select_zones" | "settings" | "";
  authUser: IUser;
  zones: IZone[];
  onSetSelectedZones: (selected: IZone[]) => void;
  selectedZones: IZone[] | undefined;
};
function DashboardPopup({
  type,
  authUser,
  zones,
  onSetSelectedZones,
  selectedZones,
}: IProps) {
  const { t } = useTranslation();
  const { control } = useFormContext<ITileSettings>();
  const getTreeHeight = () => {
    if (window.innerHeight && window.innerHeight / 2 < 600) {
      return `${window.innerHeight / 2}px`;
    }
    return "600px";
  };
  return (
    <div>
      {type === "select_zones" && (
        <div style={{ maxHeight: 350 }}>
          {authUser && zones && (
            <TreeViewWithCheckbox
              showSearchbox={false}
              treeHeight={getTreeHeight()}
              onSave={onSetSelectedZones}
              textsave={t("show_selected")}
              defaultValues={selectedZones}
              placeholder={t("search_for_zones")}
              items={zones || []}
              currentUserZones={authUser.zones?.data.map(
                (item: IZone) => item.id,
              )}
            />
          )}
        </div>
      )}

      {type === "settings" && (
        <div>
          <Grid container rowSpacing={2} padding={1.5} sx={{ paddingTop: 0 }}>
            <Grid item xs={12} md={12}>
              <InputLabelStyled maginbottom={10}>
                <b>{t("Monitor Display")}</b>
              </InputLabelStyled>
              <Grid item xs={12} md={6}>
                <FormControlLabelStyled
                  checked
                  control={
                    <Controller
                      name="showDeviceName"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={t("show_device_name") as string}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabelStyled
                  checked
                  control={
                    <Controller
                      name="showSubjectId"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={t("show_subjectId") as string}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabelStyled
                  control={
                    <Controller
                      name="showIcon"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={t("show_icon") as string}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabelStyled
                  checked
                  control={
                    <Controller
                      name="showDuration"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={t("show_duration") as string}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabelStyled
                  checked
                  control={
                    <Controller
                      name="showVitals"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={t("show_vitals") as string}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
              <InputLabelStyled maginbottom={35}>
                <b>{t("Monitor Scale")}</b>
              </InputLabelStyled>
              <Controller
                name="tileScale"
                control={control}
                render={({ field }) => (
                  <SMFormDiscreteSlider
                    field={field}
                    steps={1}
                    min={25}
                    max={150}
                    valueLabelDisplay="on"
                    onChangeCommitted={() => {}}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default DashboardPopup;
