import { Box, IconButton, Stack, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";

import { ChartIcon } from "components/icons";
import { FlexCenter } from "styled/CommonStyled";
import { SMMenuProps } from "interfaces/menu.interface";
import CustomizedMenus from "components/StyledMenu/StyledMenu";
import SessionFilterTags from "components/StyledMenu/SessionFilterTags";
import SleepHistoryFilterDropdown from "components/StyledMenu/SleepHistoryFilterDropdown";
import { IFilter } from "interfaces/filters.interface";

import SMRangePicker from "./SMRangePicker";
import {
  chartType,
  ChartType,
  SleepHistorySelectedDates,
} from "../sleepHistoryTypes";

type SleepHistoryToolBarProps = {
  selectedDates: SleepHistorySelectedDates;
  setSelectedDates: (dates: SleepHistorySelectedDates) => void;
  activeChart: ChartType;
  setActiveChart: (chart: ChartType) => void;
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: (update: (prev: IFilter) => IFilter) => void;
};

function SleepHistoryToolBar({
  selectedDates,
  setSelectedDates,
  activeChart,
  setActiveChart,
  sleepHistoryFilters,
  setSleepHistoryFilters,
}: SleepHistoryToolBarProps) {
  const { t } = useTranslation();
  const appTheme: Theme = useTheme();

  const chartMenu: SMMenuProps[] = [
    {
      label: t("area_chart"),
      value: "area_chart",
      icon:
        activeChart === chartType.area_chart ? (
          <DoneIcon />
        ) : (
          <Box sx={{ width: "32px" }} />
        ),
    },
    {
      label: t("column_chart"),
      value: "column_chart",
      icon:
        activeChart === chartType.column_chart ? (
          <DoneIcon />
        ) : (
          <Box sx={{ width: "32px" }} />
        ),
    },
  ];

  const onClick = (route: SMMenuProps) => {
    setActiveChart(route.value as ChartType);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            margin: "20px 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FlexCenter
            columnCenter={0}
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {t("history_of_sleep_sessions")}
          </FlexCenter>
          <Box>
            <Stack direction="row" padding="4px">
              <FlexCenter
                columnCenter={0}
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >{`${t("showing_sessions_with_start_date")}:`}</FlexCenter>
              <Box>
                <SMRangePicker
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <SessionFilterTags
            sleepHistoryFilters={sleepHistoryFilters}
            setSleepHistoryFilters={setSleepHistoryFilters}
          />
        </Box>

        {/* Chart switch  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between;",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ m: "8px 0" }}>
            <SleepHistoryFilterDropdown
              sleepHistoryFilters={sleepHistoryFilters}
              setSleepHistoryFilters={setSleepHistoryFilters}
            />
          </Box>
          <CustomizedMenus
            onClick={onClick}
            menus={chartMenu}
            title={t("chart_type")}
            hoverColor={appTheme.palette.main[2]}
            manuLabelComponent={
              <IconButton
                sx={{
                  borderRadius: "4px",
                  border: `1px solid ${appTheme.palette.main[70]}`,
                  cursor: "pointer",
                  "&:hover": {
                    background: appTheme.palette.main[2],
                  },
                }}
              >
                <ChartIcon />
              </IconButton>
            }
          />
        </Box>
      </Box>
    </>
  );
}
export default SleepHistoryToolBar;
