import { ReactElement } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

import UserIcon from "@mui/icons-material/Person";
import Landing from "@modules/landing/Landing";

const Home = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div style={{ backgroundColor: "#121217" }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          color: "white",
          boxShadow: "unset !important",
        }}
      >
        <Toolbar
          sx={{
            color: "white",
            "& :last-child": {
              marginLeft: "auto",
            },
          }}
        >
          <Button component={Link} to={"/auth/login"} color="inherit">
            {" "}
            {t("Log in")}
            <div style={{ marginLeft: "12px !important" }}>
              <UserIcon />
            </div>
          </Button>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route index element={<Landing />} />
      </Routes>
    </div>
  );
};

export default Home;
