import { useTheme, styled } from "@mui/material/styles";
import { CSSProperties } from "react";

export const ApplyFloat = styled("div")(({
  float = "none",
  sx,
}: {
  float: "left" | "right" | "none";
  sx?: CSSProperties;
}) => {
  return {
    float: float || "none",
    ...sx,
  };
});

export const ApplyMargin = styled("div")(({
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  width,
}: {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  width?: number | string;
}) => {
  return {
    marginTop: top || 0,
    marginRight: right,
    marginBottom: bottom,
    marginLeft: left,
    float: "left",
    width: width || `calc(100% - ${left + right}px)`,
  };
});

export const ApplyBackround = styled("div")(() => {
  return {
    width: "100%",
    height: "100%",
    background: "#FFF",
  };
});

export const StyledP = styled("p")(() => {
  const theme = useTheme();
  return {
    fontSize: 16,
    color: theme.palette.neutral[80],
    margin: 0,
    padding: 0,
    "@media print": {
      fontSize: "12px !important",
    },
  };
});

export const ErrorMessage = styled("p")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    fontSize: 14,
    color: theme.palette.red[50],
    bottom: 0,
    margin: 0,
    padding: 0,
    ...sx,
  };
});
