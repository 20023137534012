import { IFilter } from "interfaces/filters.interface";

import i18n from "i18n/config";

export function getTagNames(): Record<keyof IFilter, string> {
  return {
    timeInBed: i18n.t("time_in_bed"),
    totalSleepTime: i18n.t("time_asleep"),
    sessionStartTime: i18n.t("session_start_time"),
  };
}

export function getSessionStartTimeOptions() {
  return [
    {
      key: i18n.t("20_to_08"),
      value: "20:00_08:00",
    },
  ];
}

// get sleep history options
export function getSleephistrotFilterOptions() {
  return [
    {
      key: i18n.t("4_hours_or_more"),
      value: "240",
    },
    {
      key: i18n.t("2_hours_or_more"),
      value: "120",
    },
    {
      key: i18n.t("1_hour_or_more"),
      value: "60",
    },
    {
      key: i18n.t("30_minutes_or_more"),
      value: "30",
    },
  ];
}
