import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getLocalSettings, setLocalSettings } from "utils/localStorage";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";

export const useEnableHeartRateMean = (): { enableHeartRateMean: boolean } => {
  const location = useLocation();

  const [isEnabled, setIsEnabled] = useState<boolean>(
    getLocalSettings(USER_SETTINGS_CONSTANTS.enable_heart_rate_mean) === true,
  );

  useEffect(() => {
    const enable_heart_rate_mean = new URLSearchParams(location.search).get(
      "enable_heart_rate_mean",
    );

    if (enable_heart_rate_mean) {
      const value = enable_heart_rate_mean === "true";

      setIsEnabled(value);
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.enable_heart_rate_mean]: value,
      });
    }
  }, [location]);

  return { enableHeartRateMean: isEnabled };
};
