import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";

const LOCAL_SETTINGS = "LOCAL_SETTINGS";
const SELECTED_ZONE = "SELECTED_ZONE";
const CLIENT_ID = "CLIENT_ID";
const MONITOR_SETTIGNS = "MONITOR_SETTIGNS";
const DASHBOARD_ACTIVE_ZONES = "DASHBOARD_ACTIVE_ZONES";
const ADMIN_SETTINGS = "ADMIN_SETTINGS";
const API_END_POINT = "API_END_POINT";
const AUTH_BACKEND = "AUTH_BACKEND";
const SLEEP_HISTORY_SETTINGS = "SLEEP_HISTORY_SETTINGS";
const SLEEP_HISTORY_CHAT_TYPE = "SLEEP_HISTORY_CHAT_TYPE";
const SLEEP_HISTORY_DATE_FILTER_TYPE = "SLEEP_HISTORY_DATE_FILTER_TYPE";
const NIGHTREPORT_FILTER = "NIGHTREPORT_FILTER";

export const LANG_KEY = "LANG_KEY";

export const setLocalSettings = (settings: Record<string, unknown>) => {
  const currentSettings =
    JSON.parse(<string>localStorage.getItem(LOCAL_SETTINGS)) || {};
  const merged = { ...currentSettings, ...settings };
  localStorage.setItem(LOCAL_SETTINGS, JSON.stringify(merged));
};

export const getLocalSettings = (key: string) => {
  if (key === USER_SETTINGS_CONSTANTS.all) {
    return localStorage.getItem(LOCAL_SETTINGS) || {};
  }
  const currentSettings =
    JSON.parse(<string>localStorage.getItem(LOCAL_SETTINGS)) || {};
  return currentSettings[key];
};

export const setLanguage = (lang: string) => {
  localStorage.setItem(LANG_KEY, lang);
};

export const getLanguage = () => {
  return localStorage.getItem(LANG_KEY);
};

export const removeLanguagePreference = () => {
  return localStorage.removeItem(LANG_KEY);
};

export const setZone = (zone: string) => {
  localStorage.setItem(SELECTED_ZONE, zone);
};

export const getZone = () => {
  return localStorage.getItem(SELECTED_ZONE);
};

export const removeZone = () => {
  return localStorage.removeItem(SELECTED_ZONE);
};

export const setClientId = (value: string) => {
  return localStorage.setItem(CLIENT_ID, value);
};

export const getClientId = () => {
  return localStorage.getItem(CLIENT_ID);
};

export const setMonitorSettings = (value: Record<string, unknown>) => {
  localStorage.setItem(MONITOR_SETTIGNS, JSON.stringify(value));
};

export const getMonitorSettings = () => {
  return JSON.parse(<string>localStorage.getItem(MONITOR_SETTIGNS));
};

export const setDashboardZones = (value: Record<string, unknown>) => {
  localStorage.setItem(DASHBOARD_ACTIVE_ZONES, JSON.stringify(value));
};

export const getDashboardZones = () => {
  return JSON.parse(<string>localStorage.getItem(DASHBOARD_ACTIVE_ZONES));
};

export const setAdminSettings = (value: Record<string, unknown>) => {
  localStorage.setItem(ADMIN_SETTINGS, JSON.stringify(value));
};

export const getAdminSettings = () => {
  return JSON.parse(<string>localStorage.getItem(ADMIN_SETTINGS));
};

export const setCustomAPIEndPoint = (value: string) => {
  localStorage.setItem(API_END_POINT, value);
};

export const getCustomAPIEndPoint = () => {
  return localStorage.getItem(API_END_POINT);
};

export const setCustomAuthBackend = (value: string) => {
  localStorage.setItem(AUTH_BACKEND, value);
};

export const getCustomAuthBackend = () => {
  return localStorage.getItem(AUTH_BACKEND);
};

export const setSleepHistorySettings = (value: Record<string, unknown>) => {
  localStorage.setItem(SLEEP_HISTORY_SETTINGS, JSON.stringify(value));
};

export const getSleepHistorySettings = () => {
  return JSON.parse(<string>localStorage.getItem(SLEEP_HISTORY_SETTINGS));
};

export const setSleepHistoryChartType = (value: string) => {
  localStorage.setItem(SLEEP_HISTORY_CHAT_TYPE, value);
};

export const getSleepHistoryChartType = () => {
  return localStorage.getItem(SLEEP_HISTORY_CHAT_TYPE);
};

export const setSleepHistoryDateFilterType = (value?: string) => {
  localStorage.setItem(SLEEP_HISTORY_DATE_FILTER_TYPE, <string>value);
};

export const getSleepHistoryDateFilterType = () => {
  return localStorage.getItem(SLEEP_HISTORY_DATE_FILTER_TYPE);
};

export const setNightReportFilter = (value: any) => {
  localStorage.setItem(NIGHTREPORT_FILTER, JSON.stringify(value));
};

export const getNightReportFilter = () => {
  const nightReportFilter = localStorage.getItem(NIGHTREPORT_FILTER);
  if (nightReportFilter !== "undefined") {
    return JSON.parse(<string>nightReportFilter) || {};
  }
  return null;
};
