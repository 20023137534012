import { ISession } from "interfaces/sleephistory.interface";
import { sleepHistoryServices } from "services/sleephistory/sleephistoryService";
import { type AppDispatch } from "store";

import * as types from "../../actionTypes/sleephistory/sleepEpochActionTypes";

export const clearSleepEpochAction = (): types.clearSleepEpochActionType => {
  return {
    type: types.CLEAR_STATE,
  };
};

export const fetchSleepEpochAction = (): types.fetchSleepEpochActionType => {
  return {
    type: types.FETCH_SLEEP_EPOCH_DATA,
  };
};

export const fetchSleepEpochActionSuccess = (
  data: ISession,
): types.fetchSleepEpochSuccessActionType => {
  return {
    type: types.SLEEP_EPOCH_DATA_SUCCESS,
    payload: data,
  };
};

export function clearSleepHistory() {
  return function (dispatch: AppDispatch) {
    // clearLiveMonitoring
    dispatch(clearSleepEpochAction());
  };
}

export function fetchSleepEpoch(id: string) {
  return function (dispatch: AppDispatch) {
    dispatch(fetchSleepEpochAction());
    sleepHistoryServices.fetchSleepDetails(id).then((response: any) => {
      if (response?.data) {
        dispatch(fetchSleepEpochActionSuccess(response.data));
      }
    });
  };
}
