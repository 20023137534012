import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  Stack,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ISession } from "interfaces/sleephistory.interface";
import { getLocale } from "utils/date.util";

import DateSelector from "@modules/monitor/NightReport/components/DateSelector";
import { ButtonAsTextStlyled } from "styled/CommonStyled";
import EnvironmentDetails from "./EnvironmentDetails";
import SessionDetails from "./SessionDetails";
import SleepEpochData from "./SleepEpochData";

function SessionDetailedViewMemo({
  session,
  sessions,
  onNextPrev,
  minDate,
  maxDate,
  setCurrentSession,
  disableNext,
  disablePrev,
}: {
  session: ISession;
  sessions?: ISession[];
  onNextPrev?: (type: "next" | "prev") => void;
  minDate?: Date;
  maxDate?: Date;
  setCurrentSession: (session?: ISession) => void;
  disableNext: boolean;
  disablePrev: boolean;
}) {
  const { t } = useTranslation();
  const [showHypnogram, setShowHypnogram] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date(session.session_start));

  const equalDates = (a: Date, b: Date) => {
    return (
      a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear()
    );
  };

  useEffect(() => {
    setShowHypnogram(false);
    if (equalDates(new Date(session.session_start), new Date(date))) return;
    setDate(new Date(session.session_start));
  }, [session, date]);

  const sessionContainsDate = (date: Date) => {
    if (sessions === undefined) return false;
    return !sessions.some((item) =>
      equalDates(new Date(item.session_start), date),
    );
  };

  const updateDate = (newDate: Date) => {
    if (sessions === undefined || equalDates(new Date(newDate), new Date(date)))
      return;

    const newSession = sessions.find((item) =>
      equalDates(new Date(item.session_start), new Date(newDate)),
    );

    if (newSession === undefined) return;

    setShowHypnogram(false);
    setDate(new Date(newSession.session_start));
    setCurrentSession(newSession);
  };

  const getCurrentDay = () => {
    if (!session) return "";

    return new Intl.DateTimeFormat(getLocale(), {
      dateStyle: "full",
    }).format(new Date(session.session_start));
  };

  return (
    <>
      <Stack direction="column" spacing={0} sx={{ marginBottom: 2 }}>
        <CardHeader
          title={t("Session Details")}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
        />
        <Stack direction="row">
          {!onNextPrev ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pl: 2 }}
            >
              {getCurrentDay()}
            </Box>
          ) : (
            <DateSelector
              date={date}
              setDate={updateDate}
              leftArrowClick={() => onNextPrev("next")}
              disableLeftArrow={disableNext}
              rightArrowClick={() => onNextPrev("prev")}
              disableRightArrow={disablePrev}
              minDate={minDate}
              maxDate={maxDate}
              dateFilter={sessionContainsDate}
            />
          )}
        </Stack>
      </Stack>

      <SessionDetails session={session} />

      <Card sx={{ height: "auto", marginBottom: 3, marginTop: 2 }}>
        <EnvironmentDetails session={session} />
      </Card>
      <Card sx={{ height: "auto", marginBottom: 3 }}>
        <CardActions sx={{ justifyContent: "space-between" }}>
          <CardHeader title={`${t("Hypnogram")}`} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ButtonAsTextStlyled
              onClick={() => setShowHypnogram((prev) => !prev)}
            >
              <p>
                {showHypnogram ? t("Hide") : t("Show")} {t("Hypnogram")}
              </p>
              {showHypnogram ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ButtonAsTextStlyled>
          </Box>
        </CardActions>
        <Collapse in={showHypnogram} mountOnEnter unmountOnExit>
          <SleepEpochData sessionId={session.id} />
        </Collapse>
      </Card>
    </>
  );
}
const SessionDetailedView = memo(SessionDetailedViewMemo);
export default SessionDetailedView;
