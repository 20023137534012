import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import {
  IHistoryStagePiePlotData,
  ISession,
} from "interfaces/sleephistory.interface";
import { getSleepStagePiePlotData } from "utils/sleepHistory/sleepHistory";
import {
  getSleepStatisticsPlotData,
  SleepStatisticsPlotData,
} from "services/utils/formatters/SleepStatisticsFormatter";
import { useFormatDuration } from "hooks/useFormatDuration";

import SleepScoreGauge from "../charts/SleepScoreGaugeChart";
import SleepStatisticsComponent from "../components/SleepStatistics";
import SleepStagesPieChart from "../charts/SleepStagesPieChart";

function SessionDetails({ session }: { session: ISession }) {
  const { t } = useTranslation();
  const formatDuration = useFormatDuration();

  const [sleepScore, setSleepScore] = useState<number>();
  const [sleepStatistics, setSleepStatistics] =
    useState<SleepStatisticsPlotData | null>(null);
  const [sleepStages, setSleepStages] = useState<IHistoryStagePiePlotData>();

  useEffect(() => {
    if (session) {
      setSleepScore(Math.round(session.sleep_score_standard));
    }
    const statistics = getSleepStatisticsPlotData(session, formatDuration);
    setSleepStatistics(statistics);

    // sleep stages
    const stages = getSleepStagePiePlotData(session);
    if (stages) {
      setSleepStages(stages);
    }
  }, [formatDuration, session]);

  return (
    <Grid item container spacing={2} alignItems="stretch">
      <Grid item lg={4} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardHeader title={t("Sleep stages")} />
          <CardContent>
            <div>
              {sleepStages && <SleepStagesPieChart plotData={sleepStages} />}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardHeader title={t("Sleep Statistics")} />
          <CardContent>
            <>
              {sleepStatistics && (
                <SleepStatisticsComponent series={sleepStatistics.series} />
              )}
            </>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardHeader title={t("Sleep Score")} />
          <CardContent>
            <SleepScoreGauge sleepScore={sleepScore} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
export default SessionDetails;
