import { ReactElement } from "react";

const escape = document.createElement("textarea");
function escapeHTML(html: string) {
  escape.textContent = html;
  return escape.innerHTML;
}

type HighlightedProps = {
  text?: string;
  highlight?: string;
};

function Highlighted({
  text = "",
  highlight = "",
}: HighlightedProps): ReactElement {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const query = highlight.replace(/(?=[() ])/g, "\\");
  const regex = new RegExp(`(${escapeHTML(query)})`, "gi");
  const parts = text.split(regex);

  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span
              style={{ textDecoration: "underline", fontWeight: "bold" }}
              key={i}
            >
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
    </span>
  );
}

export default Highlighted;
