import { Menu, Box } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { IFilter } from "interfaces/filters.interface";

import { getTagNames } from "./SMMenuComponents/MenuOptions";
import RenderSleepHistoryFilterOptions from "./SMMenuComponents/RenderSleepHistoryFilterOptions";
import FilterTag from "./FilterTag";

const bgMap = {
  timeInBed: "teal",
  totalSleepTime: "blue",
  sessionStartTime: "purple",
} as const;

type ISessionRange = { start: string; end: string };

type SessionFilterTagsProps = {
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: (update: (prev: IFilter) => IFilter) => void;
};

function SessionFilterTags({
  sleepHistoryFilters,
  setSleepHistoryFilters,
}: SessionFilterTagsProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [dropDownType, setDropDownType] = useState<keyof IFilter | null>();

  const onDeleteFilter = (key: keyof IFilter) => {
    const value = key === "sessionStartTime" ? { start: "", end: "" } : "";
    setSleepHistoryFilters((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const onChipClick = (e: MouseEvent<HTMLDivElement>, key: keyof IFilter) => {
    if (anchorEl) {
      setAnchorEl(null);
      setDropDownType(null);
    } else {
      setAnchorEl(e.currentTarget);
      setDropDownType(key);
    }
  };

  // isNotEmpty
  const isNOTEmpty = (key: keyof IFilter, value: string | ISessionRange) => {
    if (key === "sessionStartTime") {
      const val = value as ISessionRange;
      return val.start !== "" && val.end !== "";
    }
    return (value as string) !== "";
  };

  const getFilterTagText = (
    key: keyof IFilter,
    value: string | ISessionRange,
  ) => {
    if (key === "sessionStartTime") {
      // value should be start, end
      const { start, end } = value as { start: string; end: string };
      return `${start} - ${end}`;
    }
    if (typeof +(value as string) === "number") {
      const hours = +value / 60;
      return `${hours} ${t("hours_lc")} ${t("or_more")}`;
    }
    return "";
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (
    !sleepHistoryFilters ||
    (!sleepHistoryFilters.timeInBed &&
      !sleepHistoryFilters.totalSleepTime &&
      (sleepHistoryFilters.sessionStartTime?.start === "" ||
        sleepHistoryFilters.sessionStartTime?.end === ""))
  ) {
    return <Box>{t("no_session_filters_applied")}</Box>;
  }

  const tagNames = getTagNames();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {Object.entries(sleepHistoryFilters)
        .filter(([key, value]: [string, ISessionRange | string]) => {
          return isNOTEmpty(key as keyof IFilter, value);
        })
        .map(([key, value]: [string, ISessionRange | string]) => {
          const k = key as keyof IFilter;
          const text = getFilterTagText(k, value);
          return (
            // Removed div here
            <FilterTag
              filterLabel={{ filter: `${tagNames[k]}: `, text }}
              background={bgMap[k]}
              onDelete={() => onDeleteFilter(k)}
              onClick={(e) => onChipClick(e, k)}
              key={k}
              id={k}
            />
          );
        })}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {dropDownType ? (
          <RenderSleepHistoryFilterOptions
            type={dropDownType}
            handleMenuClose={handleMenuClose}
            sleepHistoryFilters={sleepHistoryFilters}
            setSleepHistoryFilters={setSleepHistoryFilters}
          />
        ) : null}
      </Menu>
    </Box>
  );
}
export default SessionFilterTags;
