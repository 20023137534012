import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { can } from "authorization/authorization.util";
import {
  AuthorizationContext,
  DevicePermissionEnum,
} from "authorization/AuthorizationContext";
import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import SMConfirmDialogNoLabel from "components/SMDialogs/SMConfirmDialogNoLabel";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import UseAdminContext from "hooks/UseAdminContext";
import { IDevice } from "interfaces/device.interface";
import { IZone } from "interfaces/zone.interface";
import { patchDevice } from "store/actions/administration/deviceActions";
import { InputLabelStyled, SubTitleStyled } from "styled/CommonStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import { ApplyMargin } from "styled/StylesStyled";
import { getZoneDropDownOptions } from "utils/zone.util";
import { useAppDispatch, useAppSelector } from "store";

function DeviceInformation({
  device,
  zones,
}: {
  device: IDevice;
  zones: IZone[];
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { permissions } = useContext(AuthorizationContext);

  const { selectedZone, setSelectedZone } = UseAdminContext();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const authUserZones = useAppSelector((state) => {
    // TODO: Fix defaulting
    return state.authReducerV1.authorizedZones || [];
  });
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<IDevice>({
    defaultValues: {
      ...device,
    },
    reValidateMode: device.id ? "onChange" : "onSubmit",
    mode: device.id ? "onChange" : "onSubmit",
  });

  useEffect(() => {
    reset({ ...device });
  }, [device, reset]);

  const updateForm = (data: IDevice) => {
    if (!errors.name && !errors.serial_number && !errors.zone_id) {
      const requestData = {
        type: data.type,
        zone_id: data.zone_id,
        name: data.name,
      };
      dispatchPatchDevice(data.id, requestData);
    }
  };

  const setCurrentZone = (data: IDevice) => {
    if (data.zone_id && data.zone_id !== selectedZone && setSelectedZone) {
      setSelectedZone(data.zone_id);
    }
  };

  // if the device is assigned to subcet then we should remove it
  const confirmZoneUpdate = () => {
    const data: IDevice = getValues();
    const requestData = {
      type: data.type,
      zone_id: data.zone_id,
      name: data.name,
      subject_id: null,
    };
    dispatchPatchDevice(data.id, requestData);
  };

  const updateDeviceErrorCallBack = () => {
    reset({ ...device });
  };

  const updateDeviceSuccessCallback = () => {
    const data: IDevice = getValues();
    setCurrentZone(data);
  };

  const dispatchPatchDevice = (id: string, param: any) => {
    dispatch(
      patchDevice(
        id,
        param,
        updateDeviceErrorCallBack,
        updateDeviceSuccessCallback,
      ),
    );
  };

  const cancelZoneUpdate = () => {
    resetField("zone_id");
  };

  return (
    <BoxSubSectionStyled>
      <SubTitleStyled>{t("Device Informtation")}</SubTitleStyled>
      <form onSubmit={handleSubmit(updateForm)}>
        <BoxSubSectionContentStyled>
          <Grid container rowSpacing={0}>
            <Grid item xs={12} md={12}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>{t("Zone")}</InputLabelStyled>
                <Controller
                  name="zone_id"
                  control={control}
                  rules={{ required: `${t("error_message_device_zone_id")}` }}
                  render={({ field, fieldState, formState }) => (
                    <SMFormDropDown
                      field={field}
                      fieldState={fieldState}
                      formState={formState}
                      disabled={
                        !can(permissions, DevicePermissionEnum["device.update"])
                      }
                      placeholder={t("Select a zone")}
                      options={getZoneDropDownOptions(
                        zones,
                        authUserZones.map((item) => item.id),
                      )}
                      width="100%"
                      onUpdate={() => {
                        if (device.subject_id) {
                          setOpenDialog(true);
                        } else {
                          handleSubmit(updateForm)();
                        }
                      }}
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>
            <Grid item xs={12} md={12}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>{t("Name")}</InputLabelStyled>
                <Controller
                  rules={{ required: `${t("error_message_device_name")}` }}
                  name="name"
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <SMFormTextField
                      field={field}
                      formState={formState}
                      disabled={
                        !can(permissions, DevicePermissionEnum["device.update"])
                      }
                      fieldState={fieldState}
                      inlineEdit={!!device.id}
                      onCancel={() => {
                        resetField("name");
                      }}
                      onUpdate={() => {
                        handleSubmit(updateForm)();
                      }}
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>

            <Grid item xs={12} md={12}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>
                  {t("Serial Number")}
                </InputLabelStyled>
                <Controller
                  rules={{ required: true }}
                  name="serial_number"
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <SMFormTextField
                      field={field}
                      formState={formState}
                      fieldState={fieldState}
                      disabled
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>
          </Grid>
        </BoxSubSectionContentStyled>
      </form>

      <SMConfirmDialogNoLabel
        title={t("device_update_change_zone_popup_title")}
        dialogDesc={`${t("device_update_change_zone_popup_body")}`}
        onDelete={confirmZoneUpdate}
        buttonOk={t("ok")}
        buttonCancel={t("Cancel")}
        okButtonBg="green"
        open={openDialog}
        setOpen={setOpenDialog}
        onCancel={cancelZoneUpdate}
      />
    </BoxSubSectionStyled>
  );
}
export default DeviceInformation;
