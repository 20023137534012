import { ReactNode } from "react";

import { Navigate } from "react-router-dom";

import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";

function RequireLogin({ children }: { children: ReactNode }) {
  const { authUser } = useAuthenticatedUser();

  if (authUser === undefined) {
    return <div />;
  }

  if (authUser && authUser.id) {
    return children;
  }

  return <Navigate to={"/auth/login"} />;
}

export default RequireLogin;
