import { TextField, TextFieldProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ChangeEvent, useEffect, useState, useRef, ReactNode } from "react";

export const TextFieldWrapper = styled("div")(() => {
  return {
    position: "relative",
  };
});

export const TextFieldStyled = styled(TextField)(({
  width,
}: {
  width?: string | number;
}) => {
  const theme = useTheme();
  return {
    width: "100%",
    minWidth: width || 150,
    height: 48,
    border: `1px solid ${theme.palette.neutral[5]}`,
    borderRadius: 4,
    "& .Mui-disabled": {
      cursor: "not-allowed",
      background: theme.palette.neutral[5],
      WebkitTextFillColor: theme.palette.neutral[80],
      borderRadius: "3px",
    },
    "& input": {
      padding: "12px 8px",
      fontSize: 16,
      color: theme.palette.neutral[70],
    },
  };
});

type IProps = Omit<
  TextFieldProps,
  "variant" | "autoComplete" | "InputProps"
> & {
  onUpdate?: (val: string) => void;
  endAdornment?: ReactNode;
  // onBlurHandler?: (val: string | undefined) => void;
  width?: number | string;
};

/**
 * Customized text field with no form
 * @params
 * @function onUpdate: called on every update.
 * @function  onBlur: called on evry blur.
 * @param  width: set width of this field
 */
function SMTextField({
  onUpdate,
  // onBlurHandler,
  width,
  endAdornment,
  ...textFieldProps
}: IProps) {
  const onUpdateRef = useRef(onUpdate);
  const [value, setValue] = useState<string>(
    (textFieldProps.defaultValue as string) ?? "",
  );
  useEffect(() => {
    setValue((textFieldProps.defaultValue as string) || "");
  }, [textFieldProps.defaultValue]);

  useEffect(() => {
    if (onUpdateRef?.current !== undefined) {
      onUpdateRef.current(value);
    }
  }, [value, onUpdateRef]);
  const { placeholder, disabled = false, sx } = textFieldProps;
  return (
    <TextFieldWrapper>
      <TextFieldStyled
        sx={sx}
        width={width}
        value={value}
        variant="standard"
        autoComplete="off"
        placeholder={placeholder || ""}
        onChange={
          textFieldProps.onChange
            ? textFieldProps.onChange
            : (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
        }
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
          endAdornment: endAdornment || null,
        }}
      />
    </TextFieldWrapper>
  );
}
export default SMTextField;
