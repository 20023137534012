import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Clock from "components/Clock/Clock";
import SingleTextField from "components/SingleTextField/SingleTextField";
import { memo } from "react";
import { SleepStatisticsPlotData } from "services/utils/formatters/SleepStatisticsFormatter";

const CLOCK_SIZE = 120;
const PREFIX = "SleepStatistics";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(() => {
  return {
    [`& .${classes.root}`]: {},
  };
});

type SleepStatisticsComponentProps = {
  series: SleepStatisticsPlotData["series"];
};

function SleepStatisticsComponent({ series }: SleepStatisticsComponentProps) {
  const { t } = useTranslation();

  return (
    <StyledGrid container spacing={3} direction="row">
      <Grid key="TimeAtStart" item xs={4}>
        <Clock
          title={t("Time at Start")}
          time={series.timeAtSleep ? series.timeAtStart : null}
          size={CLOCK_SIZE}
          datetime={true}
        />
      </Grid>
      <Grid key="TimeAtSleep" item xs={4}>
        <Clock
          title={t("First Asleep")}
          time={series.timeAtSleep}
          size={CLOCK_SIZE}
          datetime={false}
        />
      </Grid>
      <Grid key="TimeAtWakeup" item xs={4}>
        <Clock
          title={t("Woke up")}
          time={series.timeAtWakeup}
          size={CLOCK_SIZE}
          datetime={true}
        />
      </Grid>
      <Grid key="TotalTimeAsleep" item xs={4}>
        <SingleTextField
          title={t("time_asleep")}
          text={series.timeAsleepString}
        />
      </Grid>
      <Grid key="SleepOnset" item xs={4}>
        <SingleTextField
          title={!series.timeAtSleep ? t("time_in_bed") : t("Sleep Onset")}
          text={series.sleepOnsetString}
        />
      </Grid>
      <Grid key="SleepEfficiency" item xs={4}>
        <SingleTextField
          title={t("Sleep Efficiency")}
          text={`${series.sleepEfficiency}%`}
        />
      </Grid>
      <Grid key="OutOfBed" item xs={4}>
        <SingleTextField
          title={t("Out of Bed")}
          text={series.timesAway || "0"}
        />
      </Grid>
      <Grid key="Movement" item xs={4}>
        <SingleTextField
          title={t("Movement")}
          text={`${series.epochsWithMovement}%`}
        />
      </Grid>
      <Grid key="RestingRespirationRate" item xs={4}>
        <SingleTextField
          title={t("Resting Respiration Rate")}
          text={series.nonREMMeanRespirationRate}
        />
      </Grid>
    </StyledGrid>
  );
}

export default memo(SleepStatisticsComponent);
