import { Grid } from "@mui/material";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import Maintitle from "components/SMTitles/MainTitle";
import UseAdminContext from "hooks/UseAdminContext";
import { IUser } from "interfaces/user.interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplyMargin } from "styled/StylesStyled";
import UserInformation from "../zones/userView/components/UserInformation";
import { useAppSelector } from "store";

function CreateUser() {
  const { t } = useTranslation();
  const { zones, selectedZone } = UseAdminContext();
  const [user, setUser] = useState<IUser>();

  const authUser = useAppSelector((state) => state.authReducerV1.user);

  useEffect(() => {
    if (user === undefined) {
      const emptyUser: IUser = {
        id: "",
        title: "",
        email: "",
        first_name: "",
        last_name: "",
        profession: "",
        state: "active",
        preferences: {
          language: "",
        },
        roles: [],
        zones: {
          object: "list",
          data: authUser?.zones?.data || [],
        },
      };
      setUser(emptyUser);
    }
  }, [zones, selectedZone, authUser, user]);

  return (
    <BoxPadding pad={32}>
      <ApplyMargin bottom={33} top={1}>
        <Maintitle text={t("create_employee")} showBack />
      </ApplyMargin>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12} md={12}>
          {user && authUser && (
            <UserInformation user={user} authUser={authUser} />
          )}
        </Grid>
      </Grid>
    </BoxPadding>
  );
}
export default CreateUser;
