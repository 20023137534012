import axios, { AxiosResponse } from "axios";
import { IAuthResponse } from "interfaces/auth.interface";
import { AUTH_TOKEN_API, REDIRECT_URL } from "constants/auth.constants";
import { getAuthBaseUrl } from "utils/auth/auth.util";

import { createFormUrlEncodedString } from "./utilities";

export type GetAuthTokenData = {
  code: string;
  client_id: string;
  code_verifier: string;
};

export const getAuthToken = (
  data: GetAuthTokenData,
): Promise<AxiosResponse<IAuthResponse>> => {
  const url = getAuthBaseUrl() + AUTH_TOKEN_API;

  return axios({
    method: "post",
    url: url,
    timeout: 30000,
    data: createFormUrlEncodedString({
      ...data,
      redirect_uri: `${document.location.origin}${REDIRECT_URL}`,
      grant_type: "authorization_code",
      scope: "offline_access",
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export type RefreshTokenData = {
  client_id: string;
  refresh_token: string;
};

export const refreshAuthToken = (
  data: RefreshTokenData,
): Promise<AxiosResponse<IAuthResponse>> => {
  const url = getAuthBaseUrl() + AUTH_TOKEN_API;

  return axios({
    method: "post",
    url: url,
    timeout: 30000,
    data: createFormUrlEncodedString({ ...data, grant_type: "refresh_token" }),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
