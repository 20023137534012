import { SubTitleStyled } from "styled/CommonStyled";
import { WrapperStyled } from "styled/ContainerStylesStyled";

function SubTitle({ text }: { text: string }) {
  return (
    <WrapperStyled>
      <SubTitleStyled>
        <div style={{ textTransform: "uppercase" }}>{text}</div>
      </SubTitleStyled>
    </WrapperStyled>
  );
}

export default SubTitle;
