import {
  ISleepEpoch,
  ISleepEpochPlotData,
} from "interfaces/sleephistory.interface";

export const getSleepEpochDataPlotData = (
  epochData: ISleepEpoch,
): ISleepEpochPlotData => {
  const plotData: ISleepEpochPlotData = {
    hypnogram: [],
    timestamp: [],
    distanceMean: [],
    respirationRateMean: [],
    respirationRateVarMean: [],
    heartRateMean: [],
    heartRateVar: [],
    externalHeartRateMean: [],
    externalHeartRateVar: [],
    externalSpO2Mean: [],
    movementMean: [],
    signalQualityMean: [],
    lightAmbient: [],
    soundAmplitude: [],
  };
  let hasExternalHeartRate = false;
  let hasExternalSpO2 = false;
  let hasHeartRate = false;

  epochData.timestamp.forEach((data: string, ix: number) => {
    const timestamp = new Date(data).getTime();

    plotData.hypnogram.push({
      x: timestamp,
      y: epochData.sleep_stage[ix],
      ix,
    });

    plotData.distanceMean.push({
      x: timestamp,
      y: epochData.distance_mean[ix],
      ix,
    });

    plotData.respirationRateMean.push({
      x: timestamp,
      y:
        epochData.respiration_rate_mean[ix] > 0
          ? epochData.respiration_rate_mean[ix]
          : null,
      ix,
    });

    plotData.respirationRateVarMean.push({
      x: timestamp,
      y: epochData.respiration_rate_var[ix],
      ix,
    });

    if (epochData.heart_rate_mean) {
      plotData.heartRateMean.push({
        x: timestamp,
        y:
          epochData.heart_rate_mean[ix] > 0
            ? epochData.heart_rate_mean[ix]
            : null,
        ix,
      });

      plotData.heartRateVar.push({
        x: timestamp,
        y: epochData.heart_rate_var?.[ix] ?? 0,
        ix,
      });
      if (epochData.heart_rate_mean[ix] > 0) {
        hasHeartRate = true;
      }
    }

    if (epochData.external_heart_rate_mean) {
      plotData.externalHeartRateMean.push({
        x: timestamp,
        y:
          epochData.external_heart_rate_mean[ix] > 0
            ? epochData.external_heart_rate_mean[ix]
            : null,
        ix,
      });

      plotData.externalHeartRateVar.push({
        x: timestamp,
        y: epochData.external_heart_rate_var?.[ix] ?? 0,
        ix,
      });

      if (epochData.external_heart_rate_mean[ix] > 0) {
        hasExternalHeartRate = true;
      }
    }

    if (epochData.external_spo2_mean) {
      plotData.externalSpO2Mean.push({
        x: timestamp,
        y:
          epochData.external_spo2_mean[ix] > 0
            ? epochData.external_spo2_mean[ix]
            : null,
        ix,
      });
      if (epochData.external_spo2_mean[ix] > 0) {
        hasExternalSpO2 = true;
      }
    }

    plotData.movementMean.push({
      x: timestamp,
      y: epochData.movement_fast_mean[ix], //  movement_mean
      ix,
    });

    plotData.signalQualityMean.push({
      x: timestamp,
      y: epochData.signal_quality_mean[ix],
      ix,
    });

    plotData.lightAmbient.push({
      x: timestamp,
      y: epochData.light_ambient_mean[ix],
      ix,
    });

    plotData.soundAmplitude.push({
      x: timestamp,
      y: epochData.sound_amplitude_mean[ix],
      ix,
    });
  });
  if (!hasHeartRate) {
    plotData.heartRateMean = [];
    plotData.heartRateVar = [];
  }
  if (!hasExternalHeartRate) {
    plotData.externalHeartRateMean = [];
    plotData.externalHeartRateVar = [];
  }
  if (!hasExternalSpO2) {
    plotData.externalSpO2Mean = [];
  }
  return plotData;
};
