import { ITenantPublic } from "interfaces/tenant.interface";
import { tenantServices } from "services/administration/tenantService";
import { type AppDispatch } from "store";

import * as types from "../../actionTypes/administration/tenantActionTypes";

export const getTenantByIDAction = (): types.fetchTenantByIDActionType => {
  return {
    type: types.FETCH_TENANT_BY_ID,
  };
};

export const getTenantByIDActionSuccess = (
  data: ITenantPublic,
): types.fetchTenantByIDSuccessActionType => {
  return {
    type: types.FETCH_TENANT_BY_ID_SUCCESS,
    payload: data,
  };
};

export function getTenantByID(id?: string) {
  return function (dispatch: AppDispatch) {
    dispatch(getTenantByIDAction());
    tenantServices
      .fetchTenant(id)
      .then((data: { object: "list"; data: ITenantPublic }) => {
        if (data?.data) {
          dispatch(getTenantByIDActionSuccess(data.data));
        }
      });
  };
}
