import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

interface DateSelectorProps {
  date: Date;
  setDate: (date: Date) => void;
  leftArrowClick: () => void;
  disableLeftArrow?: boolean;
  rightArrowClick: () => void;
  disableRightArrow?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateFilter?: (date: Date) => boolean;
}
const DateSelector = ({
  date,
  setDate,
  leftArrowClick,
  disableLeftArrow,
  rightArrowClick,
  disableRightArrow,
  minDate,
  maxDate,
  dateFilter,
}: DateSelectorProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <IconButton
      sx={{ p: 1, fontSize: "16px", userSelect: "none" }}
      onClick={leftArrowClick}
      disabled={disableLeftArrow}
    >
      <ArrowBackIosNewIcon fontSize="inherit" />
    </IconButton>
    <div
      style={{
        fontSize: 16,
        marginLeft: "2px",
        marginRight: "2px",
      }}
    >
      <DatePicker
        value={date}
        onChange={(value) => value && setDate(value)}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={dateFilter}
      />
    </div>
    <IconButton
      sx={{ p: 1, fontSize: "16px", userSelect: "none" }}
      onClick={rightArrowClick}
      disabled={disableRightArrow}
    >
      <ArrowForwardIosIcon fontSize="inherit" />
    </IconButton>
  </div>
);
export default DateSelector;
