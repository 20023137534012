import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { IAccessTokenPayload } from "interfaces/auth.interface";
import { clearCookies, shouldRefreshAccessToken } from "utils/auth/auth.util";
import { getAuthToken, type GetAuthTokenData } from "api/authentication";
import { useNavigate } from "./useNavigate";
import * as LOCAL_STORAGE from "utils/localStorage";

export const useAuth = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<boolean>(false);

  const getToken = useCallback(
    (data: GetAuthTokenData) => {
      getAuthToken(data)
        .then((response) => {
          // set login error as false
          setLoginError(false);
          if (response.data?.access_token) {
            const decodedToken = jwtDecode<IAccessTokenPayload>(
              response.data.access_token,
            );
            const accessTokenLifetime = response.data.expires_in || 0;
            const accessTokenExpiryTime = new Date();
            accessTokenExpiryTime.setTime(
              accessTokenExpiryTime.getTime() + accessTokenLifetime * 1000,
            );
            Cookies.set("access_token", response.data.access_token, {
              expires: accessTokenExpiryTime,
            });
            Cookies.set("userId", response.data.userId, {
              expires: accessTokenExpiryTime,
            });

            if (shouldRefreshAccessToken(decodedToken.roles)) {
              Cookies.set("refresh_token", response.data.refresh_token);
            }

            // landing page
            navigate("/monitor/overview");
          }
        })
        .catch(() => {
          clearCookies();
          setLoginError(true);
        });
    },
    [navigate],
  );

  useEffect(() => {
    const code = new URLSearchParams(search).get("code");
    const responseState = new URLSearchParams(search).get("state");
    const userState = new URLSearchParams(search).get("userState");

    const state = sessionStorage.getItem("state");
    const code_verifier = sessionStorage.getItem("code_verifier");
    const client_id = LOCAL_STORAGE.getClientId();
    if (
      userState &&
      responseState &&
      state &&
      code &&
      client_id &&
      code_verifier &&
      !Cookies.get("access_token") &&
      userState === "Authenticated" &&
      state === responseState
    ) {
      getToken({ code, client_id, code_verifier });
    } else {
      navigate("/auth/login");
    }
  }, [getToken, navigate, search]);

  return { loginError };
};
