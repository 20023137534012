import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  AuthorizationContext,
  ZonePermissionEnum,
} from "authorization/AuthorizationContext";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { IFilter } from "interfaces/filters.interface";
import {
  IHistoryVitalsPlotData,
  ISession,
  SleepStagesOptionsType,
} from "interfaces/sleephistory.interface";
import {
  clearSleepHistory,
  fetchSleepHistory,
  fetchSleepHistoryByID,
} from "store/actions/sleephistory/sleephistoryActions";
import { toIsoString } from "utils/date.util";
import * as LOCAL_STORAGE from "utils/localStorage";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";
import {
  getFilteredSessionByTme,
  getSleepStagesPlotData,
  getSleepVitalsPlotData,
} from "utils/sleepHistory/sleepHistory";

import { useAppDispatch, useAppSelector } from "store";
import SleepHistoryHeader from "./components/SleepHistoryHeader";
import HistoryMode from "./HistoryMode";
import { LatestNightMode } from "./LatestNightMode";
import {
  ViewMode,
  SleepHistorySelectedDates,
  ChartType,
  chartType,
} from "./sleepHistoryTypes";

function SleepHistoryScreen({
  selectedSubjectId,
  userDisplayName,
  sessionID,
}: {
  selectedSubjectId?: string;
  userDisplayName: string;
  sessionID?: string;
}) {
  const dispatch = useAppDispatch();
  const { permissions } = useContext(AuthorizationContext);

  const [sessions, setSessions] = useState<ISession[]>();
  const [filteredSessions, setFilteredSessions] = useState<ISession[]>([]);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disablePrev, setDisablePrev] = useState<boolean>(false);

  const [viewMode, setViewMode] = useState<ViewMode>(
    getLocalSettings(USER_SETTINGS_CONSTANTS.set_history_view_mode) ||
      "last_night",
  );

  // If no filter is stored or stored filter type is "custom", use latest 90 days instead
  const storedDateFilterType =
    LOCAL_STORAGE.getSleepHistoryDateFilterType() || "90";
  const defaultDateFilterType = parseInt(storedDateFilterType, 10)
    ? storedDateFilterType
    : "90";

  const endOfToday = new Date().setHours(23, 59, 59, 999);
  const fromDate = useMemo(
    () =>
      new Date().setDate(
        new Date().getDate() - (parseInt(defaultDateFilterType) || 90),
      ),
    [defaultDateFilterType],
  );

  const startOfFromDate = new Date(fromDate).setHours(0, 0, 0, 999);
  const defaultDateFilter = {
    to: toIsoString(new Date(endOfToday)),
    from: toIsoString(new Date(startOfFromDate)),
    type: defaultDateFilterType,
  };
  const [selectedDates, setSelectedDates] =
    useState<SleepHistorySelectedDates>(defaultDateFilter);
  const [actualDates, setActualDates] = useState<SleepHistorySelectedDates>();

  // Time asleep filter
  const defaultFilters: IFilter = LOCAL_STORAGE.getSleepHistorySettings() || {
    timeInBed: "120",
    totalSleepTime: "",
    sessionStartTime: {
      start: "",
      end: "",
    },
  };
  const [sleepHistoryFilters, setSleepHistoryFilters] =
    useState<IFilter>(defaultFilters);

  const [sleepStagesOptions, setSleepStagesOptions] =
    useState<SleepStagesOptionsType>();

  const [sleepVitalsOptions, setSleepVitalsOptions] =
    useState<IHistoryVitalsPlotData>();
  const [currentSession, setCurrentSession] = useState<ISession>();

  // chart types
  const defaultChartType: ChartType =
    (LOCAL_STORAGE.getSleepHistoryChartType() as ChartType) ||
    chartType.area_chart;
  const [activeChart, setActiveChart] = useState<ChartType>(defaultChartType);

  const sleepHistoryData = useAppSelector(
    (state) => state.sleepHistoryReducer.sleepHistory,
  );

  // Show sleep history
  const showSleepHistory = useCallback(() => {
    return (
      permissions &&
      permissions.indexOf(ZonePermissionEnum["zone.sleep_history"]) !== -1
    );
  }, [permissions]);

  useEffect(() => {
    if (!showSleepHistory() && viewMode === "history") {
      // change the view mode
      setViewMode("last_night");
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: "last_night",
      });
    }

    return () => {
      // to fix the state caching issue
      dispatch(clearSleepHistory());
    };
  }, [dispatch, viewMode, showSleepHistory]);

  useEffect(() => {
    if (sleepHistoryData && sleepHistoryData.length) {
      setSessions(sleepHistoryData);

      const timeFilter = sleepHistoryFilters.sessionStartTime;
      if (viewMode === "history") {
        if (timeFilter.start && timeFilter.end) {
          const sessionsFilteredByTme = getFilteredSessionByTme({
            sessions: sleepHistoryData,
            from: timeFilter.start,
            to: timeFilter.end,
          });
          setFilteredSessions(sessionsFilteredByTme);

          // for component caching
          setSleepStagesOptions(getSleepStagesPlotData(sessionsFilteredByTme));
          setSleepVitalsOptions(getSleepVitalsPlotData(sessionsFilteredByTme));
        } else {
          setFilteredSessions([]);
          // for component caching
          setSleepStagesOptions(getSleepStagesPlotData(sleepHistoryData));
          setSleepVitalsOptions(getSleepVitalsPlotData(sleepHistoryData));
        }
      } else if (viewMode === "last_night") {
        if (!sessionID) {
          const lastSession = sleepHistoryData[0];
          setCurrentSession(lastSession);
        } else {
          const session = sleepHistoryData.find(
            (item: ISession) => item.id === sessionID,
          );
          if (session) {
            setCurrentSession(session);
          }
        }
      }
    } else if (sleepHistoryData !== undefined) {
      if (!sleepHistoryData.length) {
        resetSleepData();
      }
    }
  }, [
    sleepHistoryData,
    viewMode,
    sessionID,
    sleepHistoryFilters.sessionStartTime,
  ]);

  const resetSleepData = () => {
    setSleepStagesOptions(undefined);
    setSessions([]);
    setFilteredSessions([]);
    setSleepVitalsOptions(undefined);
    setCurrentSession(undefined);
  };

  const dispatchFetchSleepAction = useCallback(
    (from: string, to: string, subject_id: string) => {
      dispatch(
        fetchSleepHistory({
          from,
          to,
          subject_id,
          latestSession: viewMode === "last_night",
          totalSleepTime: sleepHistoryFilters.totalSleepTime,
          timeInBed: sleepHistoryFilters.timeInBed,
          sessionStartTime: sleepHistoryFilters.sessionStartTime || null,
        }),
      );
    },
    [dispatch, sleepHistoryFilters, viewMode],
  );

  const session = useMemo(
    () => sleepHistoryData?.find((item: ISession) => item.id === sessionID),
    [sleepHistoryData, sessionID],
  );

  const [latestSession, setLatestSession] = useState<ISession>();

  useEffect(() => {
    if (sessions === undefined) return;
    const newLatestSession = [...sessions].sort(
      (a, b) =>
        new Date(b.session_end).getTime() - new Date(a.session_end).getTime(),
    )[0];
    if (newLatestSession === undefined) return;
    if (
      latestSession !== undefined &&
      new Date(newLatestSession.session_end).getTime() <=
        new Date(latestSession.session_end).getTime()
    )
      return;
    setLatestSession(newLatestSession);
  }, [sessions, latestSession]);

  useEffect(() => {
    if (viewMode !== "last_night") {
      return;
    }
    if (latestSession !== undefined) return;
    if (session === undefined && sessionID !== undefined) {
      dispatch(fetchSleepHistoryByID({ id: sessionID }));
    }
    if (selectedSubjectId) {
      const to = toIsoString(new Date(endOfToday));
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 1 || 0);
      const from = toIsoString(currentDate);
      dispatchFetchSleepAction(from, to, selectedSubjectId);
    }
  }, [
    viewMode,
    latestSession,
    dispatch,
    sessionID,
    selectedSubjectId,
    endOfToday,
    dispatchFetchSleepAction,
    fromDate,
    session,
  ]);

  useEffect(() => {
    if (
      !selectedDates.from ||
      !selectedDates.to ||
      viewMode !== "history" ||
      selectedSubjectId === undefined ||
      selectedSubjectId === ""
    ) {
      return;
    }
    if (actualDates === undefined) {
      setActualDates(selectedDates);
      dispatchFetchSleepAction(
        selectedDates.from,
        selectedDates.to,
        selectedSubjectId,
      );
    } else if (
      new Date(selectedDates.from) < new Date(actualDates.from) ||
      new Date(selectedDates.to) > new Date(actualDates.to)
    ) {
      setActualDates(selectedDates);
      dispatchFetchSleepAction(
        selectedDates.from,
        selectedDates.to,
        selectedSubjectId,
      );
    }
  }, [
    selectedDates,
    viewMode,
    selectedSubjectId,
    dispatchFetchSleepAction,
    actualDates,
  ]);

  useEffect(() => {
    if (
      actualDates === undefined ||
      actualDates.from === undefined ||
      actualDates.to === undefined ||
      selectedSubjectId === undefined ||
      selectedSubjectId === ""
    )
      return;
    dispatch(
      fetchSleepHistory({
        from: actualDates.from,
        to: actualDates.to,
        subject_id: selectedSubjectId,
        latestSession: false,
        totalSleepTime: sleepHistoryFilters.totalSleepTime,
        timeInBed: sleepHistoryFilters.timeInBed,
        sessionStartTime: sleepHistoryFilters.sessionStartTime || null,
      }),
    );
  }, [sleepHistoryFilters, actualDates, selectedSubjectId, dispatch]);

  const isTimeFilterEnabled =
    sleepHistoryFilters.sessionStartTime.start &&
    sleepHistoryFilters.sessionStartTime.end;

  const isNoSleepData = () => {
    const sessionsToUse = isTimeFilterEnabled ? filteredSessions : sessions;
    return (
      sessionsToUse !== undefined &&
      (sessionsToUse?.length === 0 ||
        sessionsToUse.filter(
          (item) =>
            new Date(item.session_start) > new Date(selectedDates.from) &&
            new Date(item.session_start) < new Date(selectedDates.to),
        ).length === 0)
    );
  };

  const onNextPrev = (type: "next" | "prev") => {
    if (currentSession === undefined || sessions === undefined) return;

    const targetSessions = isTimeFilterEnabled ? filteredSessions : sessions;

    const index = targetSessions.findIndex(
      (item: ISession) => item.id === currentSession.id,
    );

    const adjacency = {
      next: 1,
      prev: -1,
    };
    const adjacentIndex = index + adjacency[type];

    if (adjacentIndex < 0 || adjacentIndex >= targetSessions.length) return;

    setCurrentSession(targetSessions[adjacentIndex]);
  };

  useEffect(() => {
    if (sessions === undefined || currentSession === undefined) return;

    const targetSessions = isTimeFilterEnabled ? filteredSessions : sessions;
    const index = targetSessions.findIndex(
      (item: ISession) => item.id === currentSession.id,
    );

    setDisablePrev(index - 1 < 0);
    setDisableNext(index + 1 >= targetSessions.length);
  }, [sessions, filteredSessions, currentSession, isTimeFilterEnabled]);

  useEffect(() => {
    LOCAL_STORAGE.setSleepHistoryDateFilterType(selectedDates.type);
  }, [selectedDates]);

  // store filters
  useEffect(() => {
    LOCAL_STORAGE.setSleepHistorySettings(sleepHistoryFilters);
  }, [sleepHistoryFilters]);

  // store chart type
  useEffect(() => {
    LOCAL_STORAGE.setSleepHistoryChartType(activeChart);
  }, [activeChart]);

  return (
    <>
      <SleepHistoryHeader
        title={userDisplayName || ""}
        showSleepHistory={showSleepHistory()}
        viewMode={viewMode}
        setViewMode={setViewMode}
        setCurrentSession={setCurrentSession}
      />
      {viewMode === "history" && (
        <HistoryMode
          disableNext={disableNext}
          disablePrev={disablePrev}
          currentSession={currentSession}
          setCurrentSession={setCurrentSession}
          sessions={sessions}
          onNextPrev={onNextPrev}
          isNoSleepData={isNoSleepData()}
          sleepStagesOptions={sleepStagesOptions}
          sleepVitalsOptions={sleepVitalsOptions}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          activeChart={activeChart}
          setActiveChart={setActiveChart}
          sleepHistoryFilters={sleepHistoryFilters}
          setSleepHistoryFilters={setSleepHistoryFilters}
        />
      )}
      {viewMode === "last_night" && (
        <LatestNightMode
          disableNext={disableNext}
          disablePrev={disablePrev}
          subjectId={selectedSubjectId}
          setCurrentSession={setCurrentSession}
        />
      )}
    </>
  );
}

export default SleepHistoryScreen;
