import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import Blue from "theme/Colors/Blue";
import Green from "theme/Colors/Green";
import Neutral from "theme/Colors/Neutral";
import Orange from "theme/Colors/Orange";
import Primary from "theme/Colors/Primary";
import Purple from "theme/Colors/Purple";
import Red from "theme/Colors/Red";
import Teal from "theme/Colors/Teal";
import Yellow from "theme/Colors/Yellow";

export const appTheme = createTheme({
  palette: {
    white: "#FFFFFF",
    primary: {
      main: Primary[70],
    },
    neutral: Neutral,
    main: Primary,
    blue: Blue,
    green: Green,
    orange: Orange,
    purple: Purple,
    red: Red,
    teal: Teal,
    yellow: Yellow,
    lightBlue: "#F6FAFB",
    chart: {
      legend: {
        selected: {
          light: grey["900"],
          dark: grey["100"],
        },
        unselected: {
          light: grey["400"],
          dark: grey["600"],
        },
        hover: {
          light: grey["400"],
          dark: grey["600"],
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1120,
      lg: 1300,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0 15px",
        },
      },
    },
  },
});
