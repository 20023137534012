import { Grid } from "@mui/material";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import { IUser } from "interfaces/user.interface";
import { InputLabelStyled } from "styled/CommonStyled";
import { ApplyMargin } from "styled/StylesStyled";

function UserPreference({ onSubmit }: { onSubmit: SubmitHandler<IUser> }) {
  const { t } = useTranslation();

  const { handleSubmit, control } = useFormContext<IUser>();

  const LANG_OPTIONS = [
    {
      id: "1",
      name: t("Language for Health and Welfare"),
      value: "nb_healthwelfare",
    },
    {
      id: "3",
      name: t("Language English"),
      value: "en",
    },
    {
      id: "4",
      name: t("Language Deutch"),
      value: "de",
    },
    {
      id: "5",
      name: t("Language French"),
      value: "fr",
    },
  ];

  return (
    <Grid container rowSpacing={0} columnSpacing={2.4}>
      <Grid item xs={12} md={8}>
        <ApplyMargin top={4}>
          <InputLabelStyled maginbottom={6}>{t("Language")}</InputLabelStyled>
        </ApplyMargin>
        <Controller
          name="preferences.language"
          control={control}
          render={({ field, fieldState }) => (
            <SMFormDropDown
              field={field}
              fieldState={fieldState}
              options={LANG_OPTIONS}
              width="100%"
              onUpdate={() => {
                handleSubmit(onSubmit)();
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default UserPreference;
