import { Slider, sliderClasses } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { SyntheticEvent } from "react";

interface Props {
  field: any;
  min?: number;
  max?: number;
  steps: number;
  displayFormater?: (value: number) => string;
  valueLabelDisplay?: "off" | "on" | "auto";
  onChangeCommitted: (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[],
  ) => void;
}

export const SliderStyled = styled(Slider)(() => {
  const theme = useTheme();
  return {
    width: "90%",
    color: theme.palette.main[70],
    [`& .${sliderClasses.valueLabel}`]: {
      left: "calc(-50% + -5px)",
      background: theme.palette.main[70],
      padding: 4,
      "& *": {
        background: theme.palette.main[70],
        textAlign: "center",
        zIndex: 99,
        color: "#FFF",
        fontWeight: "bold",
        padding: 0,
        fontSize: 12,
      },
    },
    [`& .${sliderClasses.thumb}`]: {
      width: 12,
      height: 12,
      "&:hover": {
        boxShadow: "0px 0px 0px 8px rgba(25, 118, 210, 0.16)",
      },
    },
    [`& .${sliderClasses.track}`]: {
      borderRadius: 3,
    },
    [`& .${sliderClasses.rail}`]: {},
  };
});

function SMFormDiscreteSlider({
  field,
  min,
  max,
  steps,
  displayFormater,
  onChangeCommitted,
  valueLabelDisplay,
}: Props) {
  const getDisplayValue = (curValue: number): string => {
    if (displayFormater) {
      return displayFormater(curValue);
    }
    return `${curValue}`;
  };

  return (
    <SliderStyled
      step={steps}
      {...field}
      onChangeCommitted={onChangeCommitted}
      min={min || 1}
      max={max || 3}
      aria-labelledby="discrete-slider"
      getAriaValueText={getDisplayValue}
      valueLabelFormat={getDisplayValue}
      valueLabelDisplay={valueLabelDisplay || "auto"}
    />
  );
}
export default SMFormDiscreteSlider;
