import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import * as LOCAL_STORAGE from "utils/localStorage";
import { PROD_API_BASE_URL } from "constants/constants";

const getBaseUrl = () => {
  if (import.meta.env.VITE_API_END_POINTS) {
    return LOCAL_STORAGE.getCustomAPIEndPoint() || PROD_API_BASE_URL;
  }

  return PROD_API_BASE_URL;
};

const rawBaseQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders(headers) {
    headers.set("Accept", "application/json");

    const token = Cookies.get("access_token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = getBaseUrl();

  const adjustedArgs =
    typeof args === "string"
      ? baseUrl
      : { ...args, url: new URL("api/v1" + args.url, baseUrl).toString() };

  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const baseApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});
