import { Alert, AlertTitle, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullScreenLoader from "components/FullScreenLoader/FullScreenLoader";
import { useAuth } from "hooks/useAuth";

function LoginCallbackScreen() {
  const { t } = useTranslation();
  const { loginError } = useAuth();

  if (loginError) {
    return (
      <Box sx={{ padding: 5, background: "#FFF" }}>
        <Alert severity="error">
          <AlertTitle>{t("login_error_title")}</AlertTitle>
          {t("login_error_message")}
          <br />
        </Alert>
        <br />
        <Link to={"/auth/login"}>{t("go_to_login_page")}</Link>
      </Box>
    );
  }
  return <FullScreenLoader />;
}
export default LoginCallbackScreen;
