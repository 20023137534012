import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import { styled } from "@mui/material/styles";

const FlexCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const TextCenter = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.neutral[80],
  margin: 0,
  padding: 0,
  textAlign: "center",
}));

const Chip = styled("div")(({ theme }) => ({
  borderRadius: 16,
  color: theme.palette.neutral[80],
  fontSize: 14,
  width: 70,
  textAlign: "center",
  padding: "2px 12px",
  borderWidth: 1,
  borderStyle: "solid",
}));

const ChipOnline = styled(Chip)(({ theme }) => ({
  background: theme.palette.green[10],
  borderColor: theme.palette.green[20],
}));

const ChipOffline = styled(Chip)(({ theme }) => ({
  background: theme.palette.red[10],
  borderColor: theme.palette.red[20],
}));

const ChipMale = styled(Chip)(({ theme }) => ({
  background: theme.palette.blue[5],
  borderColor: theme.palette.blue[10],
}));

const ChipFemale = styled(Chip)(({ theme }) => ({
  background: theme.palette.teal[5],
  borderColor: theme.palette.teal[10],
}));

export const MemoizedDeviceStatus = memo(function DeviceStatus({
  deviceStatus,
}: {
  deviceStatus: boolean | null;
}) {
  const { t } = useTranslation();

  return (
    <FlexCenter>
      {deviceStatus === null && <TextCenter>-</TextCenter>}
      {deviceStatus === false && <ChipOffline>{t("offline")}</ChipOffline>}
      {deviceStatus === true && <ChipOnline>{t("online")}</ChipOnline>}
      <Box sx={{ visibility: "hidden" }} width="24px" />
    </FlexCenter>
  );
});

export const MemoizedSex = memo(function Sex({ gender }: { gender: string }) {
  const { t } = useTranslation();

  if (gender === "male") {
    return (
      <FlexCenter>
        <ChipMale>{t("Male")}</ChipMale>
        <Box sx={{ visibility: "hidden" }} width="24px" />
      </FlexCenter>
    );
  }

  if (gender === "female") {
    return (
      <FlexCenter>
        <ChipFemale>{t("Female")}</ChipFemale>
        <Box sx={{ visibility: "hidden" }} width="24px" />
      </FlexCenter>
    );
  }

  return <></>;
});
