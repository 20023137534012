import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import SMConfirmDialog from "components/SMDialogs/SMConfirmDialog";
import Maintitle from "components/SMTitles/MainTitle";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import {
  deleteUser,
  getAllUsers,
} from "store/actions/administration/userActions";
import { ApplyMargin } from "styled/StylesStyled";
import { useNavigate } from "hooks/useNavigate";
import { toastSuccessPositionCenter } from "utils/toast.util";
import UserInformation from "./components/UserInformation";
import { useAppDispatch, useAppSelector } from "store";

export const UserViewPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const usersState = useAppSelector((state) => state.usersReducer);

  const authState = useAppSelector((state) => state.authReducerV1);

  useEffect(() => {
    if (user && authState.user) {
      if (!authState.user?.zones?.data?.length) {
        setDisabled(true);
      }
      if (user?.id && authState.user?.id === user?.id) {
        setDisabled(true);
      }

      const authUserZoneIds = authState.user.zones.data.map(
        (zone: IZone) => zone.id,
      );
      const userZonePaths = user?.zones.data.map((zone: IZone) => zone.path);
      for (let i = 0; i < userZonePaths.length; i += 1) {
        const nodePath = userZonePaths[i];
        const paths = nodePath.split(".");
        const intersection = paths.filter((value: string) =>
          authUserZoneIds.includes(value),
        );
        if (!intersection.length) {
          setDisabled(true);
          break;
        }
      }
    }
  }, [authState, user]);

  // load users if not loaded
  useEffect(() => {
    if (usersState === undefined || Object.keys(usersState).length === 0) {
      dispatch(getAllUsers());
    }
  }, [dispatch, usersState]);

  // filter current subject
  useEffect(() => {
    if (usersState && usersState.users) {
      const usr = usersState.users.find((item: IUser) => item.id === id);

      if (usr) {
        setUser(usr);
      }
    }
  }, [usersState, id]);

  // delete subject callback
  const userDeleteCB = () => {
    // show toast
    toastSuccessPositionCenter(t("employee_deleted_successfully"));
    navigate("/administration/zones/users");
  };

  const getUserName = (usrObj?: IUser) => {
    if (!usrObj) {
      return "";
    }
    if (usrObj.first_name || usrObj.last_name) {
      return `${usrObj.first_name || ""} ${usrObj.last_name || ""}`;
    }
    return usrObj.email;
  };

  // delete subject
  const onDelete = () => {
    if (user) {
      dispatch(deleteUser(user, userDeleteCB));
    }
  };

  if (!user) {
    return (
      <BoxPadding pad="0px 24px 24px 4px">{t("no_user_found")}</BoxPadding>
    );
  }
  if (!authState?.user) {
    return <div />;
  }
  return (
    <BoxPadding pad="0px 24px 24px 4px">
      <ApplyMargin bottom={21} top={24}>
        <Maintitle text={getUserName(user)} showBack />
      </ApplyMargin>
      <Grid container rowSpacing={1.2} columnSpacing={3}>
        <Grid item xs={12} md={12}>
          {user && authState.user?.id && (
            <UserInformation
              user={user}
              disabled={disabled}
              authUser={authState.user}
              // authUserHigherRole={authUserHigherRole}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {user && (
            <SMConfirmDialog
              title={t("delete_employee")}
              dialogDesc={t("are_you_sure_you_want_to_delete_this_employee")}
              onDelete={onDelete}
              buttonOk={t("Delete")}
              buttonCancel={t("Cancel")}
              okButtonBg="red"
            />
          )}
        </Grid>
      </Grid>
    </BoxPadding>
  );
};

export default UserViewPage;
