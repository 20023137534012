import _ from "lodash";

import { ITenant } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { authServices } from "services/auth/authService";
import { type AppDispatch } from "store";

import * as types from "../../actionTypes/authActionTypes";

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

/**
 * Redux action to fetch all tenants
 *
 * @return type - redux action type |
 */
export const fetchTenantsAction = (): types.fetchTenantsActionType => {
  return {
    type: types.FETCH_TENANTS,
  };
};

export const resetAuthStateAction = (): types.resetAuthStateActionType => {
  return {
    type: types.RESET_AUTH_STATE,
  };
};

export const resetTenantsAction = (): types.resetTenantsStateActionType => {
  return {
    type: types.RESET_TENANTS,
  };
};
/**
 * Redux  success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const fetchTenantsActionSuccess = (
  data: ITenant[],
): types.fetchTenantsSuccessActionType => {
  return {
    type: types.FETCH_TENANTS_SUCCESS,
    payload: data,
  };
};

// fetch tenants
export const fetchTenants = (email: string) => {
  return function (dispatch: AppDispatch) {
    authServices.fetchTenants(email).then((response: any) => {
      if (response?.data) {
        dispatch(fetchTenantsActionSuccess(response.data));
      }
    });
  };
};

/**
 * Redux action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const getAuthUserAction = (): types.getAuthUserActionType => {
  return {
    type: types.GET_AUTH_USER,
  };
};

/**
 * Redux  success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const getAuthUserSuccessAction = (
  user: IUser,
): types.getAuthUserSuccessActionType => {
  return {
    type: types.GET_AUTH_USER_SUCCESS_ACTION,
    payload: user,
  };
};

export const updateAuthUserAction = (): types.updateAuthUserActionType => {
  return {
    type: types.UPDATE_AUTH_USER,
  };
};

/**
 * Redux success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const updateAuthUserSuccessAction = (
  user: IUser,
): types.updateAuthUserSuccessActionType => {
  return {
    type: types.UPDATE_AUTH_USER_SUCCESS,
    payload: user,
  };
};

export const getAuthUserZonesSuccessAction = (
  zones: IZone[],
): types.getAuthUserZonesSuccessActionType => {
  return {
    type: types.GET_AUTH_ZONES_SUCCESS_ACTION,
    payload: zones,
  };
};

// getAuthUserZones
export const getAuthUserZones = () => {
  return function (dispatch: AppDispatch) {
    authServices.getAuthUserZones().then((response: { data: IZone[] }) => {
      if (response?.data) {
        dispatch(getAuthUserZonesSuccessAction(response?.data));
      }
    });
  };
};

// fetch tenants
export const getAuthUser = () => {
  return function (dispatch: AppDispatch) {
    dispatch(getAuthUserAction());
    authServices.getAuthUser().then((response: { data: IUser }) => {
      if (response?.data) {
        const userZones = _.orderBy(
          response?.data?.zones?.data || [],
          (item) => item.name.toLowerCase(),
          ["asc"],
        );
        dispatch(
          getAuthUserSuccessAction({
            ...response.data,
            zones: { data: userZones, object: response?.data?.zones?.object },
          }),
        );

        // get the updated list of user zones
        dispatch(getAuthUserZones());
      } else {
        // clearCookies();
      }
    });
  };
};

//
export const updateAuthUser = (user: IUser) => {
  return function (dispatch: AppDispatch) {
    dispatch(updateAuthUserAction());
    authServices.updateAuthUser(user).then((response: { data: IUser }) => {
      if (response?.data) {
        dispatch(updateAuthUserSuccessAction(response.data));
      } else {
        // clearCookies();
      }
    });
  };
};

export const resetAuthState = () => {
  return function (dispatch: AppDispatch) {
    dispatch(resetAuthStateAction());
  };
};

export const resetTenantState = () => {
  return function (dispatch: AppDispatch) {
    dispatch(resetTenantsAction());
  };
};

export const passWordReset = (callback?: () => void) => {
  return function () {
    authServices.passwordReset().then(() => {
      if (callback) {
        callback();
      }
    });
  };
};
