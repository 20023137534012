import { Dispatch, RefObject, SetStateAction, createContext } from "react";

import { IZone } from "interfaces/zone.interface";
import { INightReportFilterSettings } from "interfaces/nightReport.interface";

export type DashboardContextType = {
  selectedZones: IZone[] | undefined;
  zoneEntity: { [key: string]: IZone };
  setSelectedZones: Dispatch<SetStateAction<IZone[] | undefined>>;
  handlePrint: (() => void) | undefined;
  componentRef: RefObject<HTMLDivElement | null>;
  selectedDate: Date;
  nightReportFilter: INightReportFilterSettings;
  setNightReportFilter: Dispatch<SetStateAction<INightReportFilterSettings>>;
};

export const DashboardContext = createContext<DashboardContextType | null>(
  {} as DashboardContextType,
);
