import { isEqual, parse } from "date-fns";
import { enGB, nb, de, fr } from "date-fns/locale";
import i18n from "../i18n/config";

export const localeMap: { [key: string]: string } = {
  en: "en-gb",
  de: "de-de",
  fr: "fr-fr",
  nb_private_customer: "nb",
  nb_sleep_research: "nb",
  nb_healthwelfare: "nb",
};

export const getLocale = () => {
  const lang: string = i18n.language || "en";
  return localeMap[lang];
};

export const getFnsLocale = (appLocale: string) => {
  const locale = localeMap[appLocale];

  switch (locale) {
    case "nb":
      return nb;
    case "en-gb":
      return enGB;
    case "de-de":
      return de;
    case "fr-fr":
      return fr;
    default:
      return enGB;
  }
};

export const secondsToTime = (secs: number) => {
  let hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  if (seconds > 30) {
    minutes += 1;

    if (minutes == 60) {
      hours += 1;
      minutes = 0;
    }
  }

  return {
    h: hours,
    m: minutes,
  };
};

export const toIsoString = (date: Date) => {
  const string = date.toISOString();

  // Remove trailing Z to convert to ISO local timezone
  return string.substring(0, string.length - 1);
};

export const timesEquals = (time1: string, time2: string): boolean => {
  //the format might be both HH:mm or HH:mm:ss
  const format1 = time1.split(":").length === 3 ? "HH:mm:ss" : "HH:mm";
  const datetime1 = parse(time1, format1, new Date());
  const format2 = time2.split(":").length === 3 ? "HH:mm:ss" : "HH:mm";
  const datetime2 = parse(time2, format2, new Date());

  return isEqual(datetime1, datetime2);
};
