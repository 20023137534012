import Switch, { switchClasses } from "@mui/material/Switch";
import { useTheme, styled } from "@mui/material/styles";
import { ChangeEvent } from "react";

export const SwitchStyled = styled(Switch)(() => {
  const theme = useTheme();
  return {
    [`&.${switchClasses.root}`]: {
      width: "48px",
      height: "24px",
      padding: 0,
      borderRadius: "24px",

      [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        paddingLeft: 2,

        [`&.${switchClasses.checked}`]: {
          transform: "translateX(32px)",
          color: theme.palette.common.white,

          [`& + .${switchClasses.track}`]: {
            backgroundColor: theme.palette.main[70],
            opacity: 1,
          },
        },
      },
      [`& .${switchClasses.track}`]: {
        backgroundColor: theme.palette.neutral[10],
        transition: "none",
        opacity: 1,
      },
      [`& .${switchClasses.thumb}`]: {
        width: "12px",
        height: "12px",
        marginTop: "6px",
        backgroundColor: theme.palette.common.white,
      },
      [`& .${switchClasses.input}`]: {
        width: "81px",
        height: "24px",
      },
    },
  };
});

interface IProps {
  field: any;
  onUpdate: (checked: boolean) => void;
}
function OnOffFormSwitch({ field, onUpdate }: IProps) {
  const onChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    field.onChange(event, checked);
    if (onUpdate) {
      onUpdate(checked);
    }
  };
  return <SwitchStyled {...field} checked={field.value} onChange={onChange} />;
}

export default OnOffFormSwitch;
