import DataUsageIcon from "@mui/icons-material/DataUsage";
import FaceIcon from "@mui/icons-material/Face";
import PersonIcon from "@mui/icons-material/Person";

import { AppRoute } from "interfaces/router.interface";

import DeviceViewPage from "./deviceView/DeviceViewPage";
import DevicesLandingPage from "./devices/DevicesLandingPage";
import SubjectLandingPage from "./subjects/SubjectLandingPage";
import SubjectViewPage from "./subjectView/SubjectViewPage";
import UserLandingPage from "./users/UserLandingPage";
import UserViewPage from "./userView/UserViewPage";

export const zonesChildRoutes: AppRoute[] = [
  {
    path: "devices",
    element: <DevicesLandingPage />,
    link: {
      name: "Devices",
      href: "devices",
      icon: <DataUsageIcon />,
    },
  },
  {
    path: "subjects",
    element: <SubjectLandingPage />,
    link: {
      name: "Subjects",
      href: "subjects",
      icon: <FaceIcon />,
    },
  },
  {
    path: "users",
    element: <UserLandingPage />,
    link: {
      name: "employees",
      href: "users",
      icon: <PersonIcon />,
    },
  },
  {
    path: "devices/:id",
    element: <DeviceViewPage />,
  },
  {
    path: "subjects/:id",
    element: <SubjectViewPage />,
  },

  {
    path: "users/:id",
    element: <UserViewPage />,
  },
];
