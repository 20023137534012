import { ReactElement } from "react";
import { Typography } from "@mui/material";

type SingleTextFieldProps = {
  title: string;
  text: string | number;
};

function SingleTextField({ title, text }: SingleTextFieldProps): ReactElement {
  return (
    <div>
      <Typography align="center">{title}</Typography>
      {/* @ts-ignore -- TODO: Replace dangerouslySetInnerHTML with children */}
      <Typography
        variant="h4"
        align="center"
        sx={{ overflowWrap: "break-word" }}
        dangerouslySetInnerHTML={{ __html: text || "-" }}
      />
      {/* >{text || " - "}
      </Typography> */}
    </div>
  );
}

export default SingleTextField;
