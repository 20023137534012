import { Button, Grid } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import WIPMessage from "components/WIPMessage/WIPMessage";
import { AUTHORIZE_API, REDIRECT_URL } from "constants/auth.constants";
import { ITenant } from "interfaces/auth.interface";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  enhanceAuthorizeUrl,
  generateCodeChallenge,
  generateState,
  getAuthBaseUrl,
} from "utils/auth/auth.util";
import * as LOCAL_STORAGE from "utils/localStorage";

interface ILogin {
  client_id: string;
}

const LoginWrapper = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  };
});

// grid-template-columns: repeat( 12, minmax(250px, 1fr) );
const TenantsGrid = styled("div")(() => {
  return {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit,minmax(250px,1fr))`,
    gridColumnGap: "16px",
    rowGap: "16px",
    padding: "0 20px",
  };
});

const TenantBox = styled(Button)(() => {
  const theme = useTheme();
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 128,
    fontSize: 14,
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    textAlign: "center",
    textTransform: "none",
    border: `.5px solid ${theme.palette.neutral[10]}`,
    fontWeight: "500",
    color: theme.palette.neutral[80],
    letterSpacing: "0.1px",
    "&:hover": {
      border: `0.5px solid ${theme.palette.main[70]}`,
      background: theme.palette.main[4],
    },
  };
});

const StyledP = styled("p")(() => {
  const theme = useTheme();
  return {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 32,
    lineHeight: "40px",
    color: theme.palette.neutral[80],
    margin: 0,
    marginBottom: 12,
    textAlign: "center",
  };
});

const SubTitle = styled("p")(() => {
  const theme = useTheme();
  return {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 32,
    margin: 0,
    letterSpacing: 0.5,
    lineHeight: "24px",
    color: theme.palette.neutral[50],
    textAlign: "center",
  };
});

function LoginScreen() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [userTenants, setUserTenants] = useState<ITenant[]>();

  // set local storage
  useEffect(() => {
    if (location.state?.email) {
      localStorage.setItem("LAST_USED_EMAIL", location.state.email);
    }
  }, [location.state?.email]);

  const { control } = useForm<ILogin>({
    defaultValues: {
      client_id: "",
    },
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onClickTenant = useCallback(
    (clientId: string) => {
      if (clientId !== "0") {
        const generatedState = generateState();
        const code_verifier = generateState();
        generateCodeChallenge(code_verifier).then((code_challenge: string) => {
          sessionStorage.setItem("state", generatedState);
          sessionStorage.setItem("code_verifier", code_verifier);
          sessionStorage.setItem("client_id", clientId);

          // store client id in local storage
          LOCAL_STORAGE.setClientId(clientId);
          // set client id in local storage
          const url = enhanceAuthorizeUrl({
            clientId,
            state: generatedState,
            code_challenge,
            authorizeUrl: getAuthBaseUrl() + AUTHORIZE_API,
            redirectUri: REDIRECT_URL,
            loginHint: location.state?.email ?? "",
          });
          window.open(url, "_self");
        });
      }
    },
    [location.state?.email],
  );

  useEffect(() => {
    const tenants = location.state?.tenants;

    if (!tenants || tenants.length === 0) {
      navigate("/auth/login");
    } else if (tenants.length === 1) {
      onClickTenant(tenants[0].client_id);
    } else {
      setUserTenants(tenants);
    }
  }, [location.state?.tenants, navigate, onClickTenant]);

  if (!userTenants) {
    return <div />;
  }

  return (
    <LoginWrapper>
      <WIPMessage />
      <Grid container justifyContent="center" columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={12}>
          <StyledP>{t("select_a_tenant")}</StyledP>
          <SubTitle>{t("choose_a_workspace")}</SubTitle>
          <Controller
            name="client_id"
            control={control}
            render={() => (
              <TenantsGrid>
                {userTenants?.map((tenant: ITenant) => {
                  return (
                    <TenantBox
                      onClick={() => onClickTenant(tenant.client_id)}
                      key={tenant.tenant_id}
                    >
                      {" "}
                      {tenant.tenant_name}
                    </TenantBox>
                  );
                })}
              </TenantsGrid>
            )}
          />
        </Grid>
      </Grid>
    </LoginWrapper>
  );
}
export default LoginScreen;
