import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useLocation } from "react-router-dom";

import { isAllowed } from "authorization/authorization.util";
import { PROD_API_BASE_URL } from "constants/constants";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { IAccessTokenPayload } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { jwtDecode } from "jwt-decode";
import { getAuthUser } from "store/actions/auth/authActions";
import { clearCookies, shouldRefreshAccessToken } from "utils/auth/auth.util";
import { useNavigate } from "./useNavigate";
import * as LOCAL_STORAGE from "utils/localStorage";
import { removeLanguagePreference, setLanguage } from "utils/localStorage";
import { useAppDispatch, useAppSelector } from "store";
import { refreshAuthToken, type RefreshTokenData } from "api/authentication";

const refreshAccessTokenQuery = (data: RefreshTokenData) => {
  refreshAuthToken(data)
    .then((response) => {
      if (response.data) {
        if (response.data.access_token && response.data.refresh_token) {
          const accessTokenLifetime = response.data.expires_in || 0;
          const accessTokenExpiryDate = new Date();
          accessTokenExpiryDate.setTime(
            accessTokenExpiryDate.getTime() + accessTokenLifetime * 1000,
          );
          Cookies.set("access_token", response.data.access_token, {
            expires: accessTokenExpiryDate,
          });
          Cookies.set("userId", response.data.userId, {
            expires: accessTokenExpiryDate,
          });
          Cookies.set("refresh_token", response.data.refresh_token);
        }
      }
    })
    .catch(() => {
      clearCookies();
    });
};

const refreshAccessToken = (navigate: NavigateFunction) => {
  const client_id = LOCAL_STORAGE.getClientId();
  const refresh_token = Cookies.get("refresh_token");

  if (client_id && refresh_token) {
    refreshAccessTokenQuery({ client_id, refresh_token });
  } else {
    navigate("/auth/login");
  }
};

export const useAuthenticatedUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const location = useLocation();
  const [useTokenRefresh, setUseTokenRefresh] = useState<boolean>(false);
  const [intervalId] = useState<string | undefined>();
  const [authUser, setAuthUser] = useState<IUser | false>();
  const authState = useAppSelector((state) => state.authReducerV1);

  useEffect(() => {
    if (import.meta.env.VITE_API_END_POINTS) {
      const baseURL = LOCAL_STORAGE.getCustomAPIEndPoint() || PROD_API_BASE_URL;
      axios.defaults.baseURL = baseURL;
    } else {
      axios.defaults.baseURL = PROD_API_BASE_URL;
    }
    if (Cookies.get("access_token")) {
      dispatch(getAuthUser());
    } else {
      setAuthUser(false);
    }
  }, [dispatch]);

  const onFocus = useCallback(() => {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      clearCookies();
      setAuthUser(false);
    }
    if (document.visibilityState === "visible") {
      if (accessToken) {
        dispatch(getAuthUser());
      }
    }
  }, [dispatch]);
  // refresh on auth user on
  useEffect(() => {
    document.addEventListener("visibilitychange", onFocus);
    return () => {
      document.removeEventListener("visibilitychange", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    if (authState.user) {
      setAuthUser(authState.user);
      // set email
      Cookies.set("logged_user_email", authState.user.email);
      // set the language preference
      const language = authState.user.preferences?.language;

      if (language && language !== i18n.language) {
        setLanguage(authState.user.preferences?.language);
        i18n.changeLanguage(authState.user.preferences?.language);
      }

      if (
        !isAllowed(authState.user, [
          ZoneUserRolesEnum.DATA_READER,
          ZoneUserRolesEnum.DATA_READER_LIMITED,
          ZoneUserRolesEnum.SERVICE_MONITOR,
        ])
      ) {
        if (location.pathname.includes("monitor/overview")) {
          navigate("/administration/zones/devices");
        }
      }

      if (
        authState.user.roles &&
        shouldRefreshAccessToken(authState.user.roles)
      ) {
        setUseTokenRefresh(true);
      } else {
        setUseTokenRefresh(false);
      }
    } else if (Object.keys(authState).length !== 0) {
      setAuthUser(false);
      setUseTokenRefresh(false);
      clearCookies();

      // remove language preference
      removeLanguagePreference();
    }
  }, [authState.user, i18n, location.pathname, authState, navigate]);

  // Refresh access token periodically
  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    if (accessToken && useTokenRefresh) {
      const { iat, exp } = jwtDecode<IAccessTokenPayload>(accessToken);
      if (iat && exp) {
        const delaySeconds = (exp - iat) / 2;
        setInterval(() => refreshAccessToken(navigate), delaySeconds * 1000);
      }
    } else if (intervalId) {
      clearInterval(intervalId);
    }
  }, [useTokenRefresh, intervalId, navigate]);

  return { authUser };
};
