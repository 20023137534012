import "./i18n/config";
import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import axios from "axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { getFnsLocale } from "./utils/date.util";

import { IColor } from "interfaces/color.interface";
import { IWIPMessage } from "interfaces/wipMessage.interface";
import { WIPMessageUrl } from "constants/constants";
import { appTheme } from "theme/default/somnofy";
import { jwtInterceptor } from "interceptors/axios.interceptor";

import { GlobalContext } from "./global/GlobalContext";
import AdminLayoutNew from "./layouts/AdminLayout";
import AuthLayoutNew from "./layouts/AuthNew";
import HomeLayout from "./layouts/Home";
import RequireLogin from "./services/utils/RequireLogin";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-mono";
import { useTranslation } from "react-i18next";

// for to stop typescript complaining about document's full screen methods
declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    webkitCancelFullScreen?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitEnterFullscreen?: () => Promise<void>;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    white: string;
    neutral: IColor;
    main: Omit<IColor, 110 | 0>;
    blue: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    green: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    orange: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    purple: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    red: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    teal: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    yellow: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    lightblue: string;
    chart: {
      legend: {
        selected: {
          light: string;
          dark: string;
        };
        unselected: {
          light: string;
          dark: string;
        };
        hover: {
          light: string;
          dark: string;
        };
      };
    };
  }
  interface PaletteOptions {
    white: string;
    neutral: IColor;
    main: Omit<IColor, 110 | 0>;
    blue: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    green: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    orange: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    purple: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    red: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    teal: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    yellow: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    lightBlue: string;
    chart: {
      legend: {
        selected: {
          light: string;
          dark: string;
        };
        unselected: {
          light: string;
          dark: string;
        };
        hover: {
          light: string;
          dark: string;
        };
      };
    };
  }
}

// Sentry integration
if (import.meta.env.VITE_SENTRY_ENV) {
  Sentry.init({
    dsn: "https://1e6cf856b7504037a3ace15b2d2e811d@o197106.ingest.sentry.io/4504558544945152",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENV,
    release: import.meta.env.VITE_GIT_SHA,
  });
}

// APP
function App() {
  jwtInterceptor();
  const { i18n } = useTranslation();

  const [appWarningMessage, setAppWarningMessage] =
    useState<IWIPMessage | null>(null);
  const filePath =
    import.meta.env.MODE === "production" ? "prod.json" : "dev.json";
  useEffect(() => {
    axios({
      method: "get",
      url: `${WIPMessageUrl}/${filePath}`,
      timeout: 30000,
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
    })
      .then((response) => {
        if (response?.data) {
          setAppWarningMessage(response?.data);
        }
      })
      .catch((error) => {
        console.log("Eror while loading warning message", error);
      });
  }, [filePath]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={getFnsLocale(i18n.language)}
          >
            <ToastContainer
              hideProgressBar
              position="bottom-right"
              autoClose={7000}
              toastClassName="toast-custom-styles"
              style={{
                width: "auto",
                right: 64,
                bottom: 24,
              }}
            />
            <GlobalContext.Provider
              value={{
                appWarningMessage,
              }}
            >
              <Routes>
                <Route path="auth/*" element={<AuthLayoutNew />} />
                <Route path="home/*" element={<HomeLayout />} />
                <Route
                  path="*"
                  element={
                    <RequireLogin>
                      <AdminLayoutNew />
                    </RequireLogin>
                  }
                />
              </Routes>
            </GlobalContext.Provider>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
