import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { ArrowStraightIcon } from "components/icons";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import { IZone } from "interfaces/zone.interface";
import { SMTableWrapperBox, TableTitle } from "styled/CommonStyled";
import { StyledP } from "styled/StylesStyled";

const ArrowStraightStyled = styled(ArrowStraightIcon)(() => ({
  marginRight: 8,
  marginLeft: 8,
}));

interface NoDeviceInZoneProps {
  zoneEntity: { [key: string]: IZone };
  paths: string[];
  bg?: "white";
}
const NoDeviceInZone = ({ zoneEntity, paths, bg }: NoDeviceInZoneProps) => {
  const { t } = useTranslation();
  const showArrow = (path: string) => paths.indexOf(path) !== paths.length - 1;
  return (
    <SMTableWrapperBox sx={{ width: "100%" }}>
      <TableTitle
        bg={bg ?? "light"}
        border={bg ?? "light"}
        sx={{
          padding: "16.5px 20px",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        {zoneEntity &&
          paths
            ?.filter((path: string) => zoneEntity[path])
            .map((path: string) => (
              <span key={zoneEntity[path].name}>
                {zoneEntity[path].name}{" "}
                {showArrow(path) && <ArrowStraightStyled />}{" "}
              </span>
            ))}
      </TableTitle>
      <BoxPadding pad="20px 16px 16px 16px" sx={{ background: "#FFF" }}>
        <Box>
          <StyledP>{t("there_are_no_devices_in_this_zone")}</StyledP>
        </Box>
      </BoxPadding>
    </SMTableWrapperBox>
  );
};
export default NoDeviceInZone;
