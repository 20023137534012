import { Button } from "@mui/material";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogActions, {
  dialogActionsClasses,
} from "@mui/material/DialogActions";
import DialogContent, {
  dialogContentClasses,
} from "@mui/material/DialogContent";
import DialogContentText, {
  dialogContentTextClasses,
} from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, styled } from "@mui/material/styles";
import { useState } from "react";

import { WarningIcon } from "components/icons";
import SMButtonNew from "components/SMButton/SMButtonNew";
import SMTextField from "components/SMTextField/SMTextField";

const DialogStyled = styled(Dialog)(() => {
  const theme = useTheme();
  return {
    padding: "8px",
    [`& .${dialogClasses.root}`]: {
      padding: 8,
    },
    [`& .${dialogClasses.paper}`]: {
      "& h2": {
        fontSize: 18,
        color: theme.palette.neutral[80],
        fontWeight: 500,
        textDecoration: "none",
        textTransform: "none",
        padding: "24px 24px 16px 24px",
      },
      "& span": {
        "& svg": {
          float: "left",
          marginTop: 3,
          marginRight: 10,
        },
      },
      [`& .${dialogContentClasses.root}`]: {
        paddingTop: 0,
        marginBottom: 0,
        color: theme.palette.neutral[80],
        [`& .${dialogContentTextClasses.root}`]: {
          fontSize: 16,
          width: "100%",
          "& > p": {
            marginBottom: 8,
          },
        },
      },
      [`& .${dialogActionsClasses.root}`]: {
        padding: "0 24px 24px 24px",
      },
    },
  };
});

export const ButtonStyled = styled(Button)(({
  bgcolor,
}: {
  bgcolor?: "red" | "green";
}) => {
  const palette = useTheme().palette;
  const color = bgcolor === "green" ? palette.green[50] : palette.red[50];
  const hoverColor = bgcolor === "green" ? palette.green[1] : palette.red[1];
  return {
    color: color,
    fontSize: 14,
    letterSpacing: "0.5px",
    textTransform: "none",
    float: "right",
    whiteSpace: "nowrap",
    "&:hover": {
      background: hoverColor,
    },
  };
});

const DialogContentTextStyled = styled(DialogContentText)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    "& p": {
      color: theme.palette.neutral[80],
    },
    "& span": {
      color: theme.palette.red[50],
    },
  };
});

function SMConfirmDialog({
  dialogDesc,
  title,
  onDelete,
  confirmPlaceHolder,
  confirmTextDesc,
  confirmText,
  buttonOk,
  buttonCancel,
  okButtonBg = "green",
  onCancelCallBack,
  disableDelete,
  bgColor = "red",
  disableWarning = true,
  disableTitle = false,
}: {
  title: string;
  dialogDesc: string;
  onDelete: () => void;
  confirmPlaceHolder?: string;
  confirmTextDesc?: string;
  confirmText?: string;
  buttonOk: string;
  buttonCancel: string;
  okButtonBg?: "green" | "red" | "none";
  onCancelCallBack?: () => void;
  disableDelete?: boolean;
  bgColor?: "red" | "green";
  disableWarning?: boolean;
  disableTitle?: boolean;
}) {
  const [confirm, setConfirm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setConfirm("");
    if (onCancelCallBack) {
      onCancelCallBack();
    }
  };

  const handleDelete = () => {
    handleClose();
    if (onDelete) {
      onDelete();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const getOpacity = () => {
    if (confirmText) {
      return confirm === confirmText ? 1 : 0.5;
    }
    return 1;
  };
  const getCursor = () => {
    if (confirmText) {
      return confirm === confirmText ? "pointer" : "not-allowed";
    }
    return "pointer";
  };
  return (
    <>
      <ButtonStyled
        onClick={handleClickOpen}
        disabled={disableDelete}
        sx={{ opacity: disableDelete ? 0.5 : 1 }}
        bgcolor={bgColor}
      >
        {title}
      </ButtonStyled>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {!disableWarning && (
            <span>
              <WarningIcon />
            </span>
          )}{" "}
          {!disableTitle && title}
        </DialogTitle>
        <DialogContent>
          <DialogContentTextStyled
            dangerouslySetInnerHTML={{
              __html: dialogDesc,
            }}
            sx={{
              marginBottom: "20px",
            }}
          />
          {confirmTextDesc && (
            <>
              <DialogContentTextStyled
                dangerouslySetInnerHTML={{
                  __html: confirmTextDesc,
                }}
                sx={{ marginBottom: "4px" }}
              />
              <SMTextField
                placeholder={confirmPlaceHolder}
                onUpdate={(val: string) => setConfirm(val)}
                defaultValue={confirm}
                width={304}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <SMButtonNew
            onClick={handleClose}
            text={buttonCancel}
            background="none"
          />
          <SMButtonNew
            onClick={() => {
              if (confirmText && confirmTextDesc) {
                if (confirm === confirmText) {
                  return handleDelete();
                }
                return null;
              }
              return handleDelete();
            }}
            text={buttonOk}
            opacity={getOpacity()}
            cursor={getCursor()}
            background={okButtonBg}
          />
        </DialogActions>
      </DialogStyled>
    </>
  );
}
export default SMConfirmDialog;
