import { Box, Theme } from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { MouseEvent } from "react";

import SMTableHeader, {
  HeadCell,
} from "components/SMTableComponents/SMTableHeader";
import { useNavigate } from "hooks/useNavigate";
import UseAdminContext from "hooks/UseAdminContext";
import { useSortableDeviceList } from "hooks/UseSortableDeviceList";
import { Order } from "interfaces/componentsTypes/tableComponentsTypes";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { IZone } from "interfaces/zone.interface";
import { TableBodyStyled } from "styled/TableElementStyled";
import * as LOCAL_STORAGE from "utils/localStorage";

import useFixedHeader from "hooks/UseFixedHeader";
import { MemoizedDeviceStatus } from "../subjects/SubjectTableRowComponets";

type IProps = {
  devices: IDevice[];
  zoneEntity: Record<string, IZone>;
  subjectEntity: Record<string, ISubject>;
};

function DeviceList({ devices, zoneEntity, subjectEntity }: IProps) {
  const { t } = useTranslation();
  const appTheme: Theme = useTheme();
  let settings = LOCAL_STORAGE.getAdminSettings() || {};
  const navigate = useNavigate();

  // Set default sort order in localStorage Admin Settings
  if (!settings.device_sort_config) {
    LOCAL_STORAGE.setAdminSettings({
      ...settings,
      device_sort_config: {
        key: "name",
        direction: "asc",
      },
    });
    settings = LOCAL_STORAGE.getAdminSettings();
  }

  const { items, requestSort, sortConfig } = useSortableDeviceList(
    devices,
    subjectEntity,
    settings.device_sort_config,
    zoneEntity,
  );
  const { setSelectedZone } = UseAdminContext();

  const getHeadCells = (): HeadCell[] => {
    return [
      {
        id: "name",
        label: t("Device Name / Location"),
      },
      {
        id: "zone",
        label: t("Zone"),
      },
      {
        id: "subject_id",
        label: t("Subject ID"),
      },
      {
        id: "serial_number",
        label: t("Serial Number"),
      },
      {
        id: "status",
        label: t("Status"),
        textCenter: true,
      },
    ];
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    // set local storage
    const currentSettings = LOCAL_STORAGE.getAdminSettings();
    LOCAL_STORAGE.setAdminSettings({
      ...currentSettings,
      device_sort_config: {
        key: property,
        direction: sortConfig?.direction === "asc" ? "desc" : "asc",
      },
    });
    requestSort(property);
  };

  const handleClick = (event: MouseEvent<unknown>, row: IDevice) => {
    // change the selected zone
    if (setSelectedZone) {
      setSelectedZone(row.zone_id ?? "");
    }
    navigate(`/administration/zones/devices/${row.id}`);
  };

  const { headerRef, tableWrapperRef, lastChildRef } = useFixedHeader();

  return (
    <div style={{ padding: 1 }}>
      <Box sx={{ width: "100%" }} ref={tableWrapperRef}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <SMTableHeader
              order={sortConfig?.direction || ("asc" as Order)}
              orderBy={(sortConfig?.key as string) || ""}
              onRequestSort={handleRequestSort}
              headCells={getHeadCells()}
              headerRef={headerRef}
            />
            <TableBodyStyled>
              {items.map((device: IDevice, index: number) => (
                <tr
                  key={device.id}
                  onClick={(event) => handleClick(event, device)}
                  ref={index === items.length - 1 ? lastChildRef : null}
                >
                  <td>{device.name}</td>
                  <td>{zoneEntity[device.zone_id ?? ""]?.name || ""}</td>
                  <td>
                    {device.subject_id
                      ? subjectEntity[device.subject_id]?.identifier
                      : t("none_unassign_device")}
                  </td>
                  <td>
                    <Box
                      sx={{
                        color: appTheme.palette.neutral[80],
                        fontSize: 16,
                        fontFamily: "Roboto Mono, monospace",
                      }}
                    >
                      {device.serial_number || "-"}
                    </Box>
                  </td>
                  <td align="center">
                    <MemoizedDeviceStatus deviceStatus={device.online} />
                  </td>
                </tr>
              ))}
            </TableBodyStyled>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default DeviceList;
