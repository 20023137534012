import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";

import { useListSessionsSessionsGetQuery } from "api";

import { ErrorPage } from "components/pages/ErrorPage";

import SessionDetailedView from "./components/SessionDetailedView";
import { NoDataPage } from "../../components/pages/NoDataPage";
import { toIsoString } from "../../utils/date.util";
import { ISession } from "../../interfaces/sleephistory.interface";

interface LatestNightModeProps {
  subjectId?: string;
  setCurrentSession: (session?: ISession) => void;
  disableNext: boolean;
  disablePrev: boolean;
}

export const LatestNightMode = ({
  subjectId,
  setCurrentSession,
  disableNext,
  disablePrev,
}: LatestNightModeProps) => {
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useListSessionsSessionsGetQuery(
    {
      subjectId: subjectId ?? "",
      // This is the only valid type for now
      type: "vitalthings-somnofy-sm100-session",
      // Get sessions with a minimum length of 12 000 seconds
      timeInBed: JSON.stringify({ gte: 12_000 }),
      // Get any session back to 1970
      from: toIsoString(new Date(0)),
      // Only get the most recent session
      limit: 1,
    },
    {
      skip: !subjectId,
    },
  );

  if (isLoading) {
    return (
      <LinearProgress
        variant={"indeterminate"}
        color={"inherit"}
        sx={{ marginInline: 2 }}
      />
    );
  }

  if (isError) {
    return (
      <ErrorPage
        title={t("Failed to load data")}
        buttonOnClick={refetch}
        buttonText={t("Try again")}
      />
    );
  }

  if (!data?.data?.length) {
    return <NoDataPage title={t("no_sleep_data_to_show")} />;
  }

  return (
    <SessionDetailedView
      disableNext={disableNext}
      disablePrev={disablePrev}
      session={data.data[0]}
      setCurrentSession={setCurrentSession}
    />
  );
};
