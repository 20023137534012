import { Box, IconButton, TextField, TextFieldProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  ReactNode,
  useRef,
  useState,
} from "react";
import { ControllerRenderProps } from "react-hook-form";

import { CheckMarkIcon, CrossIcon } from "components/icons";
import {
  ControllerFieldState,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";
import { InlineButtonsStyled } from "styled/CommonStyled";
import { ErrorMessage } from "styled/StylesStyled";

export const TextFieldWrapper = styled("div")(() => {
  return {
    position: "relative",
  };
});

export const TextFieldStyled = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== "focusedStyle" && prop !== "borderRed" && prop !== "disabledInput",
})(({
  focusedStyle = 0,
  borderRed,
  width,
  masked_input,
  disabledInput,
}: {
  focusedStyle?: number;
  borderRed: number;
  width?: number | string;
  masked_input: number;
  disabledInput?: number;
}) => {
  const theme = useTheme();
  const border = () => {
    if (borderRed) {
      return `1px solid ${theme.palette.red[50]}`;
    }
    if (focusedStyle) {
      return `1px solid ${theme.palette.main[70]}`;
    }
    return `1px solid ${theme.palette.neutral[5]}`;
  };
  return {
    WebkitTextSecurity: masked_input ? "disc" : "none",
    "& .Mui-disabled": {
      // background: theme.palette.neutral[5],
      color: theme.palette.neutral[80],
      WebkitTextFillColor: theme.palette.neutral[80],
      cursor: "not-allowed",
    },
    width: width || "100%",
    minWidth: 150,
    height: 48,
    border: border(),
    background: disabledInput ? theme.palette.neutral[5] : "none",
    boxShadow: focusedStyle
      ? `0px 0px 4px ${
          borderRed ? theme.palette.red[50] : theme.palette.main[70]
        }`
      : "",
    borderRadius: focusedStyle ? "2px" : 4,
    "& input": {
      padding: "12px 8px",
      fontSize: 16,
      color: theme.palette.neutral[70],
      "&.Mui-disabled": {
        cursor: "not-allowed",
        // background: theme.palette.neutral[5]
      },
    },
    "& input:disabled": {},
  };
});

type ISMTextFieldProps = Omit<TextFieldProps, "variant" | "autoComplete"> & {
  field: ControllerRenderProps<any, any>;
  formState: UseFormStateReturn<FieldValues>;
  fieldState: ControllerFieldState;
  inlineEdit?: boolean;
  onCancel?: () => void;
  onUpdate?: () => void;
  withFocusStyle?: boolean;
  width?: number | string;
  maskedInput?: boolean;
  endAdornment?: ReactNode;
  leadingComponent?: ReactNode;
  disabled?: boolean;
};

function SMFormTextField({
  field,
  formState,
  fieldState,
  inlineEdit = false,
  onCancel,
  onUpdate,
  withFocusStyle,
  width,
  endAdornment,
  maskedInput,
  leadingComponent,
  ...textFieldProps
}: ISMTextFieldProps) {
  field.value = field.value ?? "";
  const textRef = useRef<HTMLElement>(null);
  const [showInlineButtons, setShowInlineButtons] = useState<boolean>(false);
  const onFocus = () => {
    if (inlineEdit) {
      setShowInlineButtons(true);
    }
  };

  const cancel = (event: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    resetField(event, true);
  };

  const onOk = (event: MouseEvent<HTMLButtonElement>) => {
    const { errors } = formState;
    event?.preventDefault();
    if (errors[field.name]) {
      textRef.current?.focus();
    } else if (onUpdate) {
      onUpdate();
      resetField(event);
    }
  };

  const resetField = (
    event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLElement>,
    callCancel?: boolean,
  ) => {
    if (inlineEdit) {
      setShowInlineButtons(false);
      if (onCancel && callCancel) {
        onCancel();
      }
    }
  };

  const onBlurEvt = (event: FocusEvent<HTMLElement>) => {
    if (!event?.currentTarget?.parentNode?.contains(event?.relatedTarget)) {
      resetField(event, true);
      if (field && field.onBlur) {
        field.onBlur();
      }
    }
  };

  const { error } = fieldState;
  return (
    <>
      <TextFieldWrapper onBlur={onBlurEvt}>
        <TextFieldStyled
          {...textFieldProps}
          variant="standard"
          autoComplete="off"
          width={width}
          type={textFieldProps.type || "text"}
          InputProps={{
            disableUnderline: true,
            endAdornment: endAdornment || null,
          }}
          {...field}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event);
          }}
          inputRef={textRef}
          onKeyDown={onFocus}
          onFocus={onFocus}
          focusedStyle={showInlineButtons || withFocusStyle ? 1 : 0}
          borderRed={error?.message ? 1 : 0}
          disabledInput={textFieldProps.disabled ? 1 : 0}
          key={field.name}
          sx={
            leadingComponent
              ? { ...textFieldProps.sx, float: "left" }
              : { ...textFieldProps.sx }
          }
          masked_input={maskedInput ? 1 : 0}
        />
        {showInlineButtons ? (
          <InlineButtonsStyled>
            <IconButton aria-label="ok" onMouseDown={onOk}>
              <CheckMarkIcon />
            </IconButton>
            <IconButton aria-label="reset" onMouseDown={cancel}>
              <CrossIcon />
            </IconButton>
          </InlineButtonsStyled>
        ) : (
          ""
        )}
        {leadingComponent || <></>}
        {leadingComponent ? <Box sx={{ clear: "both" }} /> : <></>}
        {error?.message ? <ErrorMessage>{error?.message}</ErrorMessage> : ""}
      </TextFieldWrapper>
    </>
  );
}
export default SMFormTextField;
