import {
  getAdminRedirectPath,
  isAllowed,
} from "authorization/authorization.util";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store";

interface ProtectedRouteProps {
  roles?: ZoneUserRolesEnum[];
}

const RequireAuth = ({ roles }: ProtectedRouteProps) => {
  const authUser = useAppSelector((state) => state.authReducerV1.user);

  const userIsAuthorized = authUser && isAllowed(authUser, roles);
  if (userIsAuthorized) return <Outlet />;

  return <Navigate to={getAdminRedirectPath(authUser)} />;
};

export default RequireAuth;
