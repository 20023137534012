import LoginCallbackScreen from "../@modules/auth/LoginCallbackScreen";
import LoginLanding from "../@modules/auth/LoginLanding";
import LoginScreen from "../@modules/auth/LoginScreen";
import { AppRoute } from "interfaces/router.interface";

// The routes
const CustomRoutes: AppRoute[] = [
  {
    path: "login",
    element: <LoginLanding />,
  },
  {
    path: "tenants",
    element: <LoginScreen />,
  },
  {
    path: "callback",
    element: <LoginCallbackScreen />,
  },
];

export default CustomRoutes;
