import FilterListIcon from "@mui/icons-material/FilterList";
import { Menu } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import SMButtonWithIcon from "components/SMButton/SMButtonWithIcon";
import { IFilter } from "interfaces/filters.interface";

import HourSlider from "./SMMenuComponents/HourSlider";
import {
  getSessionStartTimeOptions,
  getSleephistrotFilterOptions,
} from "./SMMenuComponents/MenuOptions";
import MenuOptionsComponent from "./SMMenuComponents/MenuOptionsComponent";
import TimeRange from "./SMMenuComponents/TimeRange";
import { NestedMenuItem } from "./SMNestedMenu";

type SleepHistoryFilterDropdownProps = {
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: (update: (prev: IFilter) => IFilter) => void;
};

function SleepHistoryFilterDropdown({
  sleepHistoryFilters,
  setSleepHistoryFilters,
}: SleepHistoryFilterDropdownProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubMenuEl, setIsSubMenuEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setIsSubMenuEl(null);
    setAnchorEl(null);
  };

  //
  const setState = (
    key: keyof IFilter,
    val: string,
    dropdownOpen?: boolean,
  ) => {
    setSleepHistoryFilters((prev: IFilter) => {
      return {
        ...prev,
        [key]: val,
      };
    });
    if (!dropdownOpen) {
      handleClose();
    }
  };

  const setSessionStartState = (
    key: keyof IFilter,
    val: string,
    dropdownOpen?: boolean,
  ) => {
    const [start, end] = val.split("_");
    setSleepHistoryFilters((prev: IFilter) => {
      return {
        ...prev,
        [key]: {
          start,
          end,
        },
      };
    });
    if (!dropdownOpen) {
      handleClose();
    }
  };

  const getSelectedSessionStartTime = () => {
    return sleepHistoryFilters.sessionStartTime.start &&
      sleepHistoryFilters.sessionStartTime.end
      ? `${sleepHistoryFilters.sessionStartTime.start}_${sleepHistoryFilters.sessionStartTime.end}`
      : "";
  };

  const onCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    filter: keyof IFilter,
  ) => {
    if (!e.target.checked) {
      if (filter === "sessionStartTime") {
        setSessionStartState(filter, "_", true);
      } else {
        setState(filter, "", true);
      }
    }
  };

  if (!sleepHistoryFilters) {
    return <></>;
  }
  return (
    <>
      <SMButtonWithIcon
        text={t("filters")}
        onClick={handleClick}
        startIcon={<FilterListIcon />}
        border
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ padding: 0 }}
      >
        <NestedMenuItem
          label={t("time_in_bed")}
          parentMenuOpen={open}
          subMenuEl={isSubMenuEl}
          setSubMenuEl={setIsSubMenuEl}
          checkbox
          checked={sleepHistoryFilters.timeInBed !== ""}
          onCheckboxChange={(e: ChangeEvent<HTMLInputElement>) =>
            onCheckboxChange(e, "timeInBed")
          }
        >
          <MenuOptionsComponent
            options={getSleephistrotFilterOptions()}
            customComponent={
              <HourSlider
                onSelect={(val: string) => setState("timeInBed", val)}
                selected={sleepHistoryFilters.timeInBed}
              />
            }
            title={t("show_sessions_with_time_in_bed")}
            onSelect={(val: string) => setState("timeInBed", val)}
            selected={sleepHistoryFilters.timeInBed}
          />
        </NestedMenuItem>

        <NestedMenuItem
          label={t("time_asleep")}
          parentMenuOpen={open}
          subMenuEl={isSubMenuEl}
          setSubMenuEl={setIsSubMenuEl}
          checkbox
          checked={sleepHistoryFilters.totalSleepTime !== ""}
          onCheckboxChange={(e: ChangeEvent<HTMLInputElement>) =>
            onCheckboxChange(e, "totalSleepTime")
          }
        >
          <MenuOptionsComponent
            options={getSleephistrotFilterOptions()}
            customComponent={
              <HourSlider
                onSelect={(val: string) => setState("totalSleepTime", val)}
                selected={sleepHistoryFilters.totalSleepTime}
              />
            }
            title={t("show_sessions_with_total_sleep_time")}
            onSelect={(val: string) => setState("totalSleepTime", val)}
            selected={sleepHistoryFilters.totalSleepTime}
          />
        </NestedMenuItem>

        <NestedMenuItem
          label={t("session_start_time")}
          parentMenuOpen={open}
          subMenuEl={isSubMenuEl}
          setSubMenuEl={setIsSubMenuEl}
          checkbox
          checked={
            sleepHistoryFilters.sessionStartTime.start !== "" &&
            sleepHistoryFilters.sessionStartTime.end !== ""
          }
          onCheckboxChange={(e: ChangeEvent<HTMLInputElement>) =>
            onCheckboxChange(e, "sessionStartTime")
          }
        >
          <MenuOptionsComponent
            options={getSessionStartTimeOptions()}
            customComponent={
              <TimeRange
                onSelect={(val: string) =>
                  setSessionStartState("sessionStartTime", val)
                }
                selected={getSelectedSessionStartTime()}
              />
            }
            title={t("show_sessions_with_start_time")}
            selected={getSelectedSessionStartTime()}
            onSelect={(val: string) =>
              setSessionStartState("sessionStartTime", val)
            }
          />
        </NestedMenuItem>
      </Menu>
    </>
  );
}
export default SleepHistoryFilterDropdown;
