import { Checkbox, Grid } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import SMNoDataBox from "components/SMDynamicTable/SMNoDataBox";
import UseAdminContext from "hooks/UseAdminContext";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { getAllDevicesNew } from "store/actions/administration/deviceActions";
import { getAllSubjects } from "store/actions/administration/subjectActions";
import { useAppDispatch, useAppSelector } from "store";
import {
  FormControlLabelStyled,
  SMTableWrapperBox,
  TableTitle,
} from "styled/CommonStyled";
import * as LOCAL_STORAGE from "utils/localStorage";
import DeviceList from "./DeviceList";

const DevicesLandingPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [subjectEntity, setSubjectEntity] = useState<{
    [key: string]: ISubject;
  }>({});

  const [filteredDevices, setFilteredDevices] = useState<IDevice[]>();

  const { selectedZone, zoneEntity } = UseAdminContext();

  const devicesState = useAppSelector((state) => state.deviceReducer);
  const subjectState = useAppSelector((state) => state.subjectReducer);

  const adminSettings = LOCAL_STORAGE.getAdminSettings() || {};
  const [showChildren, setShowChildren] = useState<boolean>(
    adminSettings.includeDeviceChildren || false,
  );

  useEffect(() => {
    if (zoneEntity && selectedZone && zoneEntity[selectedZone]) {
      dispatch(getAllSubjects(`${zoneEntity[selectedZone].path}.*`));
      dispatch(getAllDevicesNew(`${zoneEntity[selectedZone].path}.*`));
    }
  }, [selectedZone, zoneEntity, dispatch]);

  useEffect(() => {
    if (!devicesState) return;
    const filtered = devicesState.devices?.filter((item: IDevice) =>
      showChildren
        ? (item.path?.split(".") || []).indexOf(selectedZone) !== -1
        : item.zone_id === selectedZone,
    );
    setFilteredDevices(filtered);
  }, [devicesState, selectedZone, showChildren]);

  useEffect(() => {
    if (!subjectState) return;

    const entity: { [key: string]: ISubject } = {};
    subjectState.subjects?.forEach((item: ISubject) => {
      entity[item.id] = item;
    });
    setSubjectEntity(entity);
  }, [subjectState]);

  if (!selectedZone) {
    console.error("selectedZone is not properly defined");
  }

  const SMCheckBox = () => (
    <Grid container justifyContent="flex-end" sx={{ marginBottom: "8px" }}>
      <FormControlLabelStyled
        checked
        onChange={(e: SyntheticEvent<Element, Event>, checked: boolean) => {
          const settings = LOCAL_STORAGE.getAdminSettings();
          LOCAL_STORAGE.setAdminSettings({
            ...settings,
            includeDeviceChildren: checked,
          });
          setShowChildren(checked);
        }}
        control={<Checkbox checked={showChildren} />}
        label={t("include_devices_in_children_zones")}
      />
    </Grid>
  );

  return !filteredDevices?.length ? (
    <BoxPadding pad="0px 24px 24px 4px">
      <SMCheckBox />
      <SMNoDataBox
        message={t("there_are_no_devices_in_this_zone")}
        title={(zoneEntity && zoneEntity[selectedZone]?.name) || ""}
        bodyBg="white"
      />
    </BoxPadding>
  ) : (
    <BoxPadding pad="0px 24px 24px 4px">
      <SMCheckBox />
      <SMTableWrapperBox>
        <TableTitle
          bg="light"
          border="light"
          sx={{
            padding: "16.5px 20px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          {(zoneEntity && zoneEntity[selectedZone]?.name) || ""}
        </TableTitle>
        <div style={{ padding: 1 }}>
          {zoneEntity && (
            <DeviceList
              devices={filteredDevices}
              zoneEntity={zoneEntity}
              subjectEntity={subjectEntity}
            />
          )}
        </div>
      </SMTableWrapperBox>
    </BoxPadding>
  );
};

export default DevicesLandingPage;
