import { useTranslation } from "react-i18next";
import { endOfDay, interval, startOfDay, subDays } from "date-fns";

import { DateRangePicker } from "components/DateRangePicker/DateRangePicker";
import { toIsoString } from "utils/date.util";

import type { SleepHistorySelectedDates } from "../sleepHistoryTypes";

const getInterval = (days: number) => {
  return interval(startOfDay(subDays(new Date(), days)), endOfDay(new Date()));
};

type SMRangePickerProps = {
  selectedDates: SleepHistorySelectedDates;
  setSelectedDates: (dates: SleepHistorySelectedDates) => void;
};

function SMRangePicker({
  selectedDates,
  setSelectedDates,
}: SMRangePickerProps) {
  const { t } = useTranslation();

  return (
    <DateRangePicker
      value={{
        type: selectedDates.type ?? "90",
        ...interval(new Date(selectedDates.from), new Date(selectedDates.to)),
      }}
      onChange={(value) =>
        setSelectedDates({
          type: value.type,
          from: toIsoString(value.start),
          to: toIsoString(value.end),
        })
      }
      options={[
        {
          key: "90",
          label: t("in_the_last_90_days"),
          value: getInterval(90),
        },
        {
          key: "30",
          label: t("in_the_last_30_days"),
          value: getInterval(30),
        },
        {
          key: "14",
          label: t("in_the_last_14_days"),
          value: getInterval(14),
        },
        {
          key: "7",
          label: t("in_the_last_7_days"),
          value: getInterval(7),
        },
        {
          key: "3",
          label: t("in_the_last_3_days"),
          value: getInterval(3),
        },
      ]}
    />
  );
}

export default SMRangePicker;
