import { useEffect } from "react";

import RouteTabsNew from "components/RouteTabs/RouteTabsNew";
import { useNavigate } from "hooks/useNavigate";
import UseAdminContext from "hooks/UseAdminContext";
import { IZone } from "interfaces/zone.interface";
import { getAuthUser } from "store/actions/auth/authActions";
import {
  IconButtonStyled,
  PositionAbsolute,
  PositionRelative,
  SettingsIconStyled,
} from "styled/CommonStyled";

import { zonesChildRoutes as routes } from "./zone.routes";
import { useAppDispatch, useAppSelector } from "store";

export const ZoneLandingPage = () => {
  const { selectedZone, setSelectedZone } = UseAdminContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authState = useAppSelector((state) => state.authReducerV1);

  const handleClickOpen = () => {
    navigate("/administration/settings");
  };

  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedZone && setSelectedZone) {
      const authZones =
        authState.user?.zones?.data?.map((item: IZone) => item.id) || [];
      setSelectedZone(authZones[0]);
    }
  }, [authState, selectedZone, setSelectedZone]);

  return (
    <PositionRelative>
      <RouteTabsNew routes={routes} />
      <PositionAbsolute right={24} top={19}>
        <IconButtonStyled onClick={handleClickOpen}>
          <SettingsIconStyled style={{ cursor: "pointer" }} />
        </IconButtonStyled>
      </PositionAbsolute>
    </PositionRelative>
  );
};

export default ZoneLandingPage;
