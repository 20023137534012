import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    findTenantByIdTenantsIdGet: build.query<
      FindTenantByIdTenantsIdGetApiResponse,
      FindTenantByIdTenantsIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.id}` }),
    }),
    listZonesZonesGet: build.query<
      ListZonesZonesGetApiResponse,
      ListZonesZonesGetApiArg
    >({
      query: () => ({ url: `/zones` }),
    }),
    createZoneZonesPost: build.mutation<
      CreateZoneZonesPostApiResponse,
      CreateZoneZonesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/zones`,
        method: "POST",
        body: queryArg.zoneCreate,
      }),
    }),
    listZoneTreeZonesTreeGet: build.query<
      ListZoneTreeZonesTreeGetApiResponse,
      ListZoneTreeZonesTreeGetApiArg
    >({
      query: () => ({ url: `/zones/tree` }),
    }),
    findZoneByIdZonesIdGet: build.query<
      FindZoneByIdZonesIdGetApiResponse,
      FindZoneByIdZonesIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}` }),
    }),
    deleteZoneZonesIdDelete: build.mutation<
      DeleteZoneZonesIdDeleteApiResponse,
      DeleteZoneZonesIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}`, method: "DELETE" }),
    }),
    updateZoneZonesIdPatch: build.mutation<
      UpdateZoneZonesIdPatchApiResponse,
      UpdateZoneZonesIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/zones/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.zoneUpdate,
      }),
    }),
    zoneParentZonesIdParentGet: build.query<
      ZoneParentZonesIdParentGetApiResponse,
      ZoneParentZonesIdParentGetApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}/parent` }),
    }),
    listZoneChildrenZonesIdChildrenGet: build.query<
      ListZoneChildrenZonesIdChildrenGetApiResponse,
      ListZoneChildrenZonesIdChildrenGetApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}/children` }),
    }),
    listZoneDescendantsZonesIdDescendantsGet: build.query<
      ListZoneDescendantsZonesIdDescendantsGetApiResponse,
      ListZoneDescendantsZonesIdDescendantsGetApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}/descendants` }),
    }),
    listZoneAncestorsZonesIdAncestorsGet: build.query<
      ListZoneAncestorsZonesIdAncestorsGetApiResponse,
      ListZoneAncestorsZonesIdAncestorsGetApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}/ancestors` }),
    }),
    zoneTenantZonesIdTenantGet: build.query<
      ZoneTenantZonesIdTenantGetApiResponse,
      ZoneTenantZonesIdTenantGetApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.id}/tenant` }),
    }),
    listSubjectsSubjectsGet: build.query<
      ListSubjectsSubjectsGetApiResponse,
      ListSubjectsSubjectsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects`,
        params: {
          path: queryArg.path,
          exclude_assigned_to_device_type: queryArg.excludeAssignedToDeviceType,
          embed: queryArg.embed,
        },
      }),
    }),
    createSubjectSubjectsPost: build.mutation<
      CreateSubjectSubjectsPostApiResponse,
      CreateSubjectSubjectsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects`,
        method: "POST",
        body: queryArg.subjectCreate,
      }),
    }),
    findSubjectByIdSubjectsIdGet: build.query<
      FindSubjectByIdSubjectsIdGetApiResponse,
      FindSubjectByIdSubjectsIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects/${queryArg.id}`,
        params: {
          embed: queryArg.embed,
        },
      }),
    }),
    deleteSubjectSubjectsIdDelete: build.mutation<
      DeleteSubjectSubjectsIdDeleteApiResponse,
      DeleteSubjectSubjectsIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    updateSubjectSubjectsIdPatch: build.mutation<
      UpdateSubjectSubjectsIdPatchApiResponse,
      UpdateSubjectSubjectsIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.subjectUpdate,
        params: {
          embed: queryArg.embed,
        },
      }),
    }),
    listDevicesDevicesGet: build.query<
      ListDevicesDevicesGetApiResponse,
      ListDevicesDevicesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/devices`,
        params: {
          path: queryArg.path,
          types: queryArg.types,
        },
      }),
    }),
    registerOrCreateADeviceDevicesPost: build.mutation<
      RegisterOrCreateADeviceDevicesPostApiResponse,
      RegisterOrCreateADeviceDevicesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/devices`,
        method: "POST",
        body: queryArg.deviceCreateOrRegister,
      }),
    }),
    findDeviceByIdDevicesIdGet: build.query<
      FindDeviceByIdDevicesIdGetApiResponse,
      FindDeviceByIdDevicesIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/devices/${queryArg.id}` }),
    }),
    deleteDeviceDevicesIdDelete: build.mutation<
      DeleteDeviceDevicesIdDeleteApiResponse,
      DeleteDeviceDevicesIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/devices/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    updateDeviceDevicesIdPatch: build.mutation<
      UpdateDeviceDevicesIdPatchApiResponse,
      UpdateDeviceDevicesIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/devices/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.deviceUpdate,
      }),
    }),
    listUsersUsersGet: build.query<
      ListUsersUsersGetApiResponse,
      ListUsersUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users`,
        params: {
          embed: queryArg.embed,
        },
      }),
    }),
    createUserUsersPost: build.mutation<
      CreateUserUsersPostApiResponse,
      CreateUserUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users`,
        method: "POST",
        body: queryArg.userCreate,
      }),
    }),
    findUserByIdUsersIdGet: build.query<
      FindUserByIdUsersIdGetApiResponse,
      FindUserByIdUsersIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}`,
        params: {
          embed: queryArg.embed,
        },
      }),
    }),
    deleteUserUsersIdDelete: build.mutation<
      DeleteUserUsersIdDeleteApiResponse,
      DeleteUserUsersIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.id}`, method: "DELETE" }),
    }),
    updateUserUsersIdPatch: build.mutation<
      UpdateUserUsersIdPatchApiResponse,
      UpdateUserUsersIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.userUpdate,
      }),
    }),
    resetPasswordUsersIdResetPasswordPost: build.mutation<
      ResetPasswordUsersIdResetPasswordPostApiResponse,
      ResetPasswordUsersIdResetPasswordPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/reset-password`,
        method: "POST",
      }),
    }),
    listUserZonesUsersIdZonesGet: build.query<
      ListUserZonesUsersIdZonesGetApiResponse,
      ListUserZonesUsersIdZonesGetApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.id}/zones` }),
    }),
    addUserZoneAssociationUsersUserIdZonesZoneIdPost: build.mutation<
      AddUserZoneAssociationUsersUserIdZonesZoneIdPostApiResponse,
      AddUserZoneAssociationUsersUserIdZonesZoneIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/zones/${queryArg.zoneId}`,
        method: "POST",
      }),
    }),
    removeUserZoneAssociationUsersUserIdZonesZoneIdDelete: build.mutation<
      RemoveUserZoneAssociationUsersUserIdZonesZoneIdDeleteApiResponse,
      RemoveUserZoneAssociationUsersUserIdZonesZoneIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/zones/${queryArg.zoneId}`,
        method: "DELETE",
      }),
    }),
    liveMonitoringLiveMonitoringGet: build.query<
      LiveMonitoringLiveMonitoringGetApiResponse,
      LiveMonitoringLiveMonitoringGetApiArg
    >({
      query: (queryArg) => ({
        url: `/live-monitoring`,
        params: {
          zones: queryArg.zones,
          device_types: queryArg.deviceTypes,
          include_unassigned_devices: queryArg.includeUnassignedDevices,
        },
      }),
    }),
    generateReportsReportsGet: build.query<
      GenerateReportsReportsGetApiResponse,
      GenerateReportsReportsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/reports`,
        params: {
          subjects: queryArg.subjects,
          report_date: queryArg.reportDate,
        },
      }),
    }),
    listSessionsSessionsGet: build.query<
      ListSessionsSessionsGetApiResponse,
      ListSessionsSessionsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/sessions`,
        params: {
          type: queryArg["type"],
          subject_id: queryArg.subjectId,
          from: queryArg["from"],
          to: queryArg.to,
          state: queryArg.state,
          sort: queryArg.sort,
          limit: queryArg.limit,
          time_in_bed: queryArg.timeInBed,
          time_asleep: queryArg.timeAsleep,
        },
      }),
    }),
    findSessionSessionsSessionIdGet: build.query<
      FindSessionSessionsSessionIdGetApiResponse,
      FindSessionSessionsSessionIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/sessions/${queryArg.sessionId}`,
        params: {
          include_epoch_data: queryArg.includeEpochData,
        },
      }),
    }),
    listNotificationHandlersNotificationHandlersGet: build.query<
      ListNotificationHandlersNotificationHandlersGetApiResponse,
      ListNotificationHandlersNotificationHandlersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-handlers`,
        params: {
          zone_id: queryArg.zoneId,
          type: queryArg["type"],
        },
      }),
    }),
    createNotificationHandlerNotificationHandlersPost: build.mutation<
      CreateNotificationHandlerNotificationHandlersPostApiResponse,
      CreateNotificationHandlerNotificationHandlersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-handlers`,
        method: "POST",
        body: queryArg.notificationHandlerCreate,
      }),
    }),
    findNotificationHandlerNotificationHandlersIdGet: build.query<
      FindNotificationHandlerNotificationHandlersIdGetApiResponse,
      FindNotificationHandlerNotificationHandlersIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/notification-handlers/${queryArg.id}` }),
    }),
    deleteNotificationHandlerNotificationHandlersIdDelete: build.mutation<
      DeleteNotificationHandlerNotificationHandlersIdDeleteApiResponse,
      DeleteNotificationHandlerNotificationHandlersIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-handlers/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    updateNotificationHandlerNotificationHandlersIdPatch: build.mutation<
      UpdateNotificationHandlerNotificationHandlersIdPatchApiResponse,
      UpdateNotificationHandlerNotificationHandlersIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-handlers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.notificationHandlerUpdateModels,
      }),
    }),
    testNotificationHandlerNotificationHandlersIdTestPost: build.mutation<
      TestNotificationHandlerNotificationHandlersIdTestPostApiResponse,
      TestNotificationHandlerNotificationHandlersIdTestPostApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-handlers/${queryArg.id}/test`,
        method: "POST",
        body: queryArg.notificationHandlerTest,
      }),
    }),
    listRulesRulesGet: build.query<
      ListRulesRulesGetApiResponse,
      ListRulesRulesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/rules`,
        params: {
          entity_id: queryArg.entityId,
          type: queryArg["type"],
        },
      }),
    }),
    createRuleRulesPost: build.mutation<
      CreateRuleRulesPostApiResponse,
      CreateRuleRulesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/rules`,
        method: "POST",
        body: queryArg.ruleCreateModels,
      }),
    }),
    listRulePresetsRulesPresetsGet: build.query<
      ListRulePresetsRulesPresetsGetApiResponse,
      ListRulePresetsRulesPresetsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/rules/presets`,
        params: {
          rule_type: queryArg.ruleType,
          rule_preset_type: queryArg.rulePresetType,
        },
      }),
    }),
    findRuleRulesIdGet: build.query<
      FindRuleRulesIdGetApiResponse,
      FindRuleRulesIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/rules/${queryArg.id}` }),
    }),
    deleteRuleRulesIdDelete: build.mutation<
      DeleteRuleRulesIdDeleteApiResponse,
      DeleteRuleRulesIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/rules/${queryArg.id}`, method: "DELETE" }),
    }),
    updateRuleRulesIdPatch: build.mutation<
      UpdateRuleRulesIdPatchApiResponse,
      UpdateRuleRulesIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/rules/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.ruleUpdateModels,
      }),
    }),
    listLogsNotificationLogsGet: build.query<
      ListLogsNotificationLogsGetApiResponse,
      ListLogsNotificationLogsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-logs`,
        params: {
          entity_id: queryArg.entityId,
          limit: queryArg.limit,
          from: queryArg["from"],
          to: queryArg.to,
          starting_after: queryArg.startingAfter,
          ending_before: queryArg.endingBefore,
          sort: queryArg.sort,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type FindTenantByIdTenantsIdGetApiResponse =
  /** status 200 Successful Response */ ResponseTenantPublic;
export type FindTenantByIdTenantsIdGetApiArg = {
  id: string;
};
export type ListZonesZonesGetApiResponse =
  /** status 200 Successful Response */ ListResponseZone;
export type ListZonesZonesGetApiArg = void;
export type CreateZoneZonesPostApiResponse =
  /** status 201 Successful Response */ ResponseZone;
export type CreateZoneZonesPostApiArg = {
  zoneCreate: ZoneCreate;
};
export type ListZoneTreeZonesTreeGetApiResponse =
  /** status 200 Successful Response */ ListResponseZoneWithChildren;
export type ListZoneTreeZonesTreeGetApiArg = void;
export type FindZoneByIdZonesIdGetApiResponse =
  /** status 200 Successful Response */ ResponseZone;
export type FindZoneByIdZonesIdGetApiArg = {
  id: string;
};
export type DeleteZoneZonesIdDeleteApiResponse = unknown;
export type DeleteZoneZonesIdDeleteApiArg = {
  id: string;
};
export type UpdateZoneZonesIdPatchApiResponse =
  /** status 200 Successful Response */ ResponseZone;
export type UpdateZoneZonesIdPatchApiArg = {
  id: string;
  zoneUpdate: ZoneUpdate;
};
export type ZoneParentZonesIdParentGetApiResponse =
  /** status 200 Successful Response */ ResponseZone;
export type ZoneParentZonesIdParentGetApiArg = {
  id: string;
};
export type ListZoneChildrenZonesIdChildrenGetApiResponse =
  /** status 200 Successful Response */ ListResponseZone;
export type ListZoneChildrenZonesIdChildrenGetApiArg = {
  id: string;
};
export type ListZoneDescendantsZonesIdDescendantsGetApiResponse =
  /** status 200 Successful Response */ ListResponseZone;
export type ListZoneDescendantsZonesIdDescendantsGetApiArg = {
  id: string;
};
export type ListZoneAncestorsZonesIdAncestorsGetApiResponse =
  /** status 200 Successful Response */ ListResponseZone;
export type ListZoneAncestorsZonesIdAncestorsGetApiArg = {
  id: string;
};
export type ZoneTenantZonesIdTenantGetApiResponse =
  /** status 200 Successful Response */ ResponseTenant;
export type ZoneTenantZonesIdTenantGetApiArg = {
  id: string;
};
export type ListSubjectsSubjectsGetApiResponse =
  /** status 200 Successful Response */ ListResponseSubject;
export type ListSubjectsSubjectsGetApiArg = {
  /** Filter subjects based on the zone path.
        Path nodes should be separated by ".", use ".*" to include subjects from all descendant zones.
    
        Examples:
        - "zone_00default" will return all direct subjects in the zone with id "zone_00default"
        - "zone_00default.*" will return all subjects fom the zone with id "zone_00default" and all descendant
        zones (i.e. "zone_00default.zone_level2.zone_level3")
         */
  path?: string;
  excludeAssignedToDeviceType?: DeviceType;
  embed?: SubjectEmbed[];
};
export type CreateSubjectSubjectsPostApiResponse =
  /** status 201 Successful Response */ ResponseSubject;
export type CreateSubjectSubjectsPostApiArg = {
  subjectCreate: SubjectCreate;
};
export type FindSubjectByIdSubjectsIdGetApiResponse =
  /** status 200 Successful Response */ ResponseSubject;
export type FindSubjectByIdSubjectsIdGetApiArg = {
  id: string;
  embed: null | SubjectEmbed[];
};
export type DeleteSubjectSubjectsIdDeleteApiResponse = unknown;
export type DeleteSubjectSubjectsIdDeleteApiArg = {
  id: string;
};
export type UpdateSubjectSubjectsIdPatchApiResponse =
  /** status 200 Successful Response */ ResponseSubject;
export type UpdateSubjectSubjectsIdPatchApiArg = {
  id: string;
  embed: null | SubjectEmbed[];
  subjectUpdate: SubjectUpdate;
};
export type ListDevicesDevicesGetApiResponse =
  /** status 200 Successful Response */ ListResponseDevice;
export type ListDevicesDevicesGetApiArg = {
  /** Filter devices based on the zone path.
        Path nodes should be separated by ".", use ".*" to include devices from all descendant zones.
    
        Examples:
        - "zone_00default" will return all direct devices in the zone with id "zone_00default"
        - "zone_00default.*" will return all devices fom the zone with id "zone_00default" and all descendant
        zones (i.e. "zone_00default.zone_level2.zone_level3")
         */
  path: null | string;
  /** Type of device */
  types: null | DeviceType[];
};
export type RegisterOrCreateADeviceDevicesPostApiResponse =
  /** status 201 Successful Response */ ResponseDevice;
export type RegisterOrCreateADeviceDevicesPostApiArg = {
  deviceCreateOrRegister:
    | DeviceGenericCreate
    | DeviceVitalThingsGuardianH10Register
    | DeviceVitalThingsSomnofySm100Register;
};
export type FindDeviceByIdDevicesIdGetApiResponse =
  /** status 200 Successful Response */ ResponseDevice;
export type FindDeviceByIdDevicesIdGetApiArg = {
  id: string;
};
export type DeleteDeviceDevicesIdDeleteApiResponse = unknown;
export type DeleteDeviceDevicesIdDeleteApiArg = {
  id: string;
};
export type UpdateDeviceDevicesIdPatchApiResponse =
  /** status 200 Successful Response */
  any | /** status 201 Created */ ResponseDevice;
export type UpdateDeviceDevicesIdPatchApiArg = {
  id: string;
  deviceUpdate:
    | DeviceGenericUpdate
    | DeviceVitalThingsGuardianH10Update
    | DeviceVitalThingsSomnofySm100Update;
};
export type ListUsersUsersGetApiResponse =
  /** status 200 Successful Response */ ListResponseUser;
export type ListUsersUsersGetApiArg = {
  embed: null | UserEmbed[];
};
export type CreateUserUsersPostApiResponse =
  /** status 201 Successful Response */ ResponseUser;
export type CreateUserUsersPostApiArg = {
  userCreate: UserCreate;
};
export type FindUserByIdUsersIdGetApiResponse =
  /** status 200 Successful Response */ ResponseUser;
export type FindUserByIdUsersIdGetApiArg = {
  id: string;
  embed: null | UserEmbed[];
};
export type DeleteUserUsersIdDeleteApiResponse = unknown;
export type DeleteUserUsersIdDeleteApiArg = {
  id: string;
};
export type UpdateUserUsersIdPatchApiResponse =
  /** status 201 Successful Response */ ResponseUser;
export type UpdateUserUsersIdPatchApiArg = {
  id: string;
  userUpdate: UserUpdate;
};
export type ResetPasswordUsersIdResetPasswordPostApiResponse =
  /** status 200 Successful Response */ ActionResponse;
export type ResetPasswordUsersIdResetPasswordPostApiArg = {
  id: string;
};
export type ListUserZonesUsersIdZonesGetApiResponse =
  /** status 200 Successful Response */ ListResponseZone;
export type ListUserZonesUsersIdZonesGetApiArg = {
  id: string;
};
export type AddUserZoneAssociationUsersUserIdZonesZoneIdPostApiResponse =
  unknown;
export type AddUserZoneAssociationUsersUserIdZonesZoneIdPostApiArg = {
  userId: string;
  zoneId: string;
};
export type RemoveUserZoneAssociationUsersUserIdZonesZoneIdDeleteApiResponse =
  unknown;
export type RemoveUserZoneAssociationUsersUserIdZonesZoneIdDeleteApiArg = {
  userId: string;
  zoneId: string;
};
export type LiveMonitoringLiveMonitoringGetApiResponse =
  /** status 200 Successful Response */ ListResponseLiveMonitorItem;
export type LiveMonitoringLiveMonitoringGetApiArg = {
  zones: string[];
  deviceTypes: null | DeviceType[];
  includeUnassignedDevices: null | boolean;
};
export type GenerateReportsReportsGetApiResponse =
  /** status 200 Successful Response */ ListResponseReport;
export type GenerateReportsReportsGetApiArg = {
  subjects: string[];
  /** Date of the report in yyyy-mm-dd format */
  reportDate: null | string;
};
export type ListSessionsSessionsGetApiResponse =
  /** status 200 Successful Response */ ListResponseVitalThingsSomnofySm100Session;
export type ListSessionsSessionsGetApiArg = {
  type: SessionType;
  subjectId: string;
  from?: string;
  to?: string;
  state?: SessionState;
  sort?: Sort;
  limit?: number;
  timeInBed?: string;
  timeAsleep?: string;
};
export type FindSessionSessionsSessionIdGetApiResponse =
  /** status 200 Successful Response */ ResponseVitalThingsSomnofySm100Session;
export type FindSessionSessionsSessionIdGetApiArg = {
  sessionId: string;
  includeEpochData: null | boolean;
};
export type ListNotificationHandlersNotificationHandlersGetApiResponse =
  /** status 200 Successful Response */ ListResponseNotificationHandler;
export type ListNotificationHandlersNotificationHandlersGetApiArg = {
  /**
   */
  zoneId: string;
  type: null | NotificationHandlerType;
};
export type CreateNotificationHandlerNotificationHandlersPostApiResponse =
  /** status 201 Successful Response */ ReponseNotificationHandler;
export type CreateNotificationHandlerNotificationHandlersPostApiArg = {
  notificationHandlerCreate: NotificationHandlerCreate;
};
export type FindNotificationHandlerNotificationHandlersIdGetApiResponse =
  /** status 200 Successful Response */ ReponseNotificationHandler;
export type FindNotificationHandlerNotificationHandlersIdGetApiArg = {
  id: string;
};
export type DeleteNotificationHandlerNotificationHandlersIdDeleteApiResponse =
  unknown;
export type DeleteNotificationHandlerNotificationHandlersIdDeleteApiArg = {
  id: string;
};
export type UpdateNotificationHandlerNotificationHandlersIdPatchApiResponse =
  /** status 200 Successful Response */ ReponseNotificationHandler;
export type UpdateNotificationHandlerNotificationHandlersIdPatchApiArg = {
  id: string;
  notificationHandlerUpdateModels: NotificationHandlerUpdateModels;
};
export type TestNotificationHandlerNotificationHandlersIdTestPostApiResponse =
  /** status 200 Successful Response */ ResponseNotificationHandlerTestResponse;
export type TestNotificationHandlerNotificationHandlersIdTestPostApiArg = {
  id: string;
  notificationHandlerTest: NotificationHandlerTest;
};
export type ListRulesRulesGetApiResponse =
  /** status 200 Successful Response */ ListResponseRule;
export type ListRulesRulesGetApiArg = {
  entityId: string;
  type: null | RuleType;
};
export type CreateRuleRulesPostApiResponse =
  /** status 201 Successful Response */ ReponseMonitoringRule;
export type CreateRuleRulesPostApiArg = {
  ruleCreateModels: RuleCreateModels;
};
export type ListRulePresetsRulesPresetsGetApiResponse =
  /** status 200 Successful Response */ ListResponseRulePreset;
export type ListRulePresetsRulesPresetsGetApiArg = {
  ruleType: RuleType;
  rulePresetType: RulePresetType;
};
export type FindRuleRulesIdGetApiResponse =
  /** status 200 Successful Response */ ReponseMonitoringRule;
export type FindRuleRulesIdGetApiArg = {
  id: string;
};
export type DeleteRuleRulesIdDeleteApiResponse = unknown;
export type DeleteRuleRulesIdDeleteApiArg = {
  id: string;
};
export type UpdateRuleRulesIdPatchApiResponse =
  /** status 200 Successful Response */ ReponseMonitoringRule;
export type UpdateRuleRulesIdPatchApiArg = {
  id: string;
  ruleUpdateModels: RuleUpdateModels;
};
export type ListLogsNotificationLogsGetApiResponse =
  /** status 200 Successful Response */ ListResponseNotificationLog;
export type ListLogsNotificationLogsGetApiArg = {
  /** The entity to filter for, either zone_id, subject_id or device_id */
  entityId: string;
  /** A limit on the number of objects to be returned, between 1 and 100. */
  limit: null | number;
  /** From time in ISO8601 format */
  from: null | string;
  /** To time in ISO8601 format */
  to: null | string;
  /** A cursor for use in pagination. starting_after is an object ID that defines your place in the list. For instance, if you make a list request and receive 100 objects, ending with obj_foo, your subsequent call can include starting_after=obj_foo in order to fetch the next page of the list. */
  startingAfter: null | string;
  /** A cursor for use in pagination. ending_before is an object ID that defines your place in the list. For instance, if you make a list request and receive 100 objects, starting with obj_bar, your subsequent call can include ending_before=obj_bar in order to fetch the previous page of the list. */
  endingBefore: null | string;
  sort: null | Sort;
};
export type Entity = "subject" | "user" | "zone";
export type ValueType = "string" | "integer" | "float" | "datetime";
export type DropdownOption = {
  name: {
    [key: string]: string;
  };
  value: string;
};
export type CustomDropdownField = {
  id: null | string;
  key: string;
  entity: Entity;
  required: boolean;
  title: {
    [key: string]: string;
  };
  info: {
    [key: string]: string;
  };
  type: "dropdown";
  value_type: ValueType;
  placeholder: {
    [key: string]: string;
  };
  options: DropdownOption[];
  default: null | string | number | number | string;
};
export type CustomTextinputField = {
  id: null | string;
  key: string;
  entity: Entity;
  required: boolean;
  title: {
    [key: string]: string;
  };
  info: {
    [key: string]: string;
  };
  type: "textinput";
  value_type: "string";
  placeholder: {
    [key: string]: string;
  };
  default: null | string;
};
export type CustomSliderField = {
  id: null | string;
  key: string;
  entity: Entity;
  required: boolean;
  title: {
    [key: string]: string;
  };
  info: {
    [key: string]: string;
  };
  type: "slider";
  value_type: "float" | "integer";
  min: number | number;
  max: number | number;
  step_size: null | number | number;
  default: null | number | number;
};
export type TenantPublic = {
  id: string;
  name: string;
  custom_fields:
    | null
    | (
        | ({
            type: "dropdown";
          } & CustomDropdownField)
        | ({
            type: "textinput";
          } & CustomTextinputField)
        | ({
            type: "slider";
          } & CustomSliderField)
      )[];
};
export type ResponseTenantPublic = {
  data: null | TenantPublic;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail: null | ValidationError[];
};
export type Zone = {
  id: string;
  object: null | "zone";
  tenant_id: string;
  parent_id: null | string;
  path: string;
  level: null | number;
  name: string;
  description: null | string;
  /** You can use this parameter to attach key-value data.
    You can specify up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.
    
    Metadata is useful for storing additional, structured information on an object. As an example, you could store
    your user's emergency contact name or corresponding unique identifiers from your system.
    Metadata is not used by the backend, and won't be seen by your users unless you choose to show it to them.
    
    Do not store any sensitive information (social security numbers, bank account numbers, etc.) as metadata.
     */
  metadata: null | object;
  settings: null | object;
  created_at: string;
  updated_at: string;
};
export type ListResponseZone = {
  object: null | "list";
  data: null | Zone[];
};
export type ResponseZone = {
  data: null | Zone;
};
export type ZoneCreate = {
  parent_id: null | string;
  name: string;
  description: null | string;
  /** You can use this parameter to attach key-value data.
    You can specify up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.
    
    Metadata is useful for storing additional, structured information on an object. As an example, you could store
    your user's emergency contact name or corresponding unique identifiers from your system.
    Metadata is not used by the backend, and won't be seen by your users unless you choose to show it to them.
    
    Do not store any sensitive information (social security numbers, bank account numbers, etc.) as metadata.
     */
  metadata: null | object;
  settings: null | object;
};
export type ZoneWithChildren = {
  id: string;
  object: null | "zone";
  tenant_id: string;
  parent_id: null | string;
  path: string;
  level: null | number;
  name: string;
  description: null | string;
  /** You can use this parameter to attach key-value data.
    You can specify up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.
    
    Metadata is useful for storing additional, structured information on an object. As an example, you could store
    your user's emergency contact name or corresponding unique identifiers from your system.
    Metadata is not used by the backend, and won't be seen by your users unless you choose to show it to them.
    
    Do not store any sensitive information (social security numbers, bank account numbers, etc.) as metadata.
     */
  metadata: null | object;
  settings: null | object;
  children: null | ZoneWithChildren[];
  created_at: string;
  updated_at: string;
};
export type ListResponseZoneWithChildren = {
  object: null | "list";
  data: null | ZoneWithChildren[];
};
export type ZoneUpdate = {
  name: null | string;
  description: null | string;
  metadata: null | object;
  settings: null | object;
};
export type Tenant = {
  id: string;
  object: null | "tenant";
  name: string;
  description: null | string;
  custom_fields:
    | null
    | (
        | ({
            type: "dropdown";
          } & CustomDropdownField)
        | ({
            type: "textinput";
          } & CustomTextinputField)
        | ({
            type: "slider";
          } & CustomSliderField)
      )[];
  metadata: null | object;
  created_at: string;
  updated_at: string;
};
export type ResponseTenant = {
  data: null | Tenant;
};
export type Sex = "male" | "female";
export type DeviceGeneric = {
  /** Each resource has an id element which contains the "logical id" of the resource assigned by the
            server responsible for storing it. Resources always have a known logical id except for a few special cases
            (e.g. when a new resource is being sent to a server to assign a logical id in the create interaction). The
            logical id is unique within the space of all resources of the same type on the same server. Once assigned by
            the server, the id is never changed.
             */
  id: string;
  object: null | "device.generic";
  type: "generic";
  virtual: boolean;
  manufacturer: null | string;
  product: null | string;
  part_number: null | string;
  serial_number: null | string;
  name: null | string;
  /** Device notes and comments */
  note: null | string;
  tenant_id: null | string;
  zone_id: null | string;
  path: null | string;
  path_level: null | number;
  subject_id: null | string;
  metadata: null | object;
  latest_connectivity_event_at: null | string;
  online: null | boolean;
};
export type VitalThingsSomnofySm100SettingsUserTimeFormat = "12" | "24";
export type VitalThingsSomnofySm100SettingsUserTime = {
  format: null | VitalThingsSomnofySm100SettingsUserTimeFormat;
  timezone: null | string;
};
export type VitalThingsSomnofySm100SettingsUserDisplayMode =
  | "normal"
  | "circle"
  | "off";
export type VitalThingsSomnofySm100SettingsUserDisplay = {
  mode: null | VitalThingsSomnofySm100SettingsUserDisplayMode;
  intensity: null | number;
};
export type VitalThingsSomnofySm100SettingsUserAudio = {
  enabled: null | boolean;
};
export type VitalThingsSomnofySm100SettingsUserRadar = {
  sensitivity: null | number;
  distance_limit: null | number;
};
export type TimeSpan = {
  start: string;
  end: string;
};
export type VitalThingsSomnofySm100SettingsUserScheduleItem = {
  enabled: null | boolean;
  enable_session: null | boolean;
  timespan: null | TimeSpan;
};
export type VitalThingsSomnofySm100SettingsUserExternalDeviceBleItem = {
  name: null | string;
};
export type VitalThingsSomnofySm100SettingsUserExternalDevices = {
  ble: null | VitalThingsSomnofySm100SettingsUserExternalDeviceBleItem[];
};
export type VitalThingsSomnofySm100SettingsUser = {
  time: null | VitalThingsSomnofySm100SettingsUserTime;
  display: null | VitalThingsSomnofySm100SettingsUserDisplay;
  audio: null | VitalThingsSomnofySm100SettingsUserAudio;
  radar: null | VitalThingsSomnofySm100SettingsUserRadar;
  schedule: null | VitalThingsSomnofySm100SettingsUserScheduleItem[];
  external_devices: null | VitalThingsSomnofySm100SettingsUserExternalDevices;
};
export type VitalThingsSomnofySm100SettingsDevice = {
  movement_alarm: null | boolean;
  live_sensor_state_enabled_until: null | string;
};
export type VitalThingsSomnofySm100Settings = {
  user: null | VitalThingsSomnofySm100SettingsUser;
  device: null | VitalThingsSomnofySm100SettingsDevice;
};
export type DeviceVitalThingsSomnofySm100 = {
  /** Each resource has an id element which contains the "logical id" of the resource assigned by the
            server responsible for storing it. Resources always have a known logical id except for a few special cases
            (e.g. when a new resource is being sent to a server to assign a logical id in the create interaction). The
            logical id is unique within the space of all resources of the same type on the same server. Once assigned by
            the server, the id is never changed.
             */
  id: string;
  object: "device.vitalthings-somnofy-sm100";
  type: "vitalthings-somnofy-sm100";
  virtual: boolean;
  manufacturer: null | string;
  product: null | string;
  part_number: null | string;
  serial_number: null | string;
  name: null | string;
  /** Device notes and comments */
  note: null | string;
  tenant_id: null | string;
  zone_id: null | string;
  path: null | string;
  path_level: null | number;
  subject_id: null | string;
  metadata: null | object;
  settings: null | VitalThingsSomnofySm100Settings;
  latest_connectivity_event_at: null | string;
  online: null | boolean;
};
export type EmbeddedDevices = {
  object: null | "list";
  data: null | (DeviceGeneric | DeviceVitalThingsSomnofySm100)[];
};
export type Subject = {
  /** Each resource has an id element which contains the "logical id" of the resource assigned by the
            server responsible for storing it. Resources always have a known logical id except for a few special cases
            (e.g. when a new resource is being sent to a server to assign a logical id in the create interaction). The
            logical id is unique within the space of all resources of the same type on the same server. Once assigned by
            the server, the id is never changed.
             */
  id: string;
  object: null | "subject";
  tenant_id: string;
  zone_id: string;
  path: string;
  level: null | number;
  custom_fields: null | object;
  /** An identifier for this patient. This is a business identifier, not a resource identifier. Subjects
            should almost always be assigned specific alphanumerical identifiers. */
  identifier: string;
  /** A name associated with the individual. */
  name: null | string;
  sex: null | Sex;
  /** The year of birth for the individual. */
  birth_year: null | number;
  height: null | number;
  weight: null | number;
  /** Whether this subject record is in active use.
                Many systems use this property to mark as non-current subjects, such as those that have not been seen for a
                period of time based on an organization's business rules. It is often used to filter subject lists to
                exclude inactive subjects.
                Deceased subjects may also be marked as inactive for the same reasons, but may be active for some time after
                death. */
  active: null | boolean;
  /** You can use this parameter to attach key-value data.
    You can specify up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.
    
    Metadata is useful for storing additional, structured information on an object. As an example, you could store
    your user's emergency contact name or corresponding unique identifiers from your system.
    Metadata is not used by the backend, and won't be seen by your users unless you choose to show it to them.
    
    Do not store any sensitive information (social security numbers, bank account numbers, etc.) as metadata.
     */
  metadata: null | object;
  created_at: string;
  updated_at: string;
  devices: null | EmbeddedDevices;
};
export type ListResponseSubject = {
  object: null | "list";
  data: null | Subject[];
};
export type DeviceType =
  | "generic"
  | "vitalthings-somnofy-sm100"
  | "vitalthings-guardian-h10";
export type SubjectEmbed = "devices";
export type ResponseSubject = {
  data: null | Subject;
};
export type SubjectCreate = {
  zone_id: string;
  /** An identifier for this patient. This is a business identifier, not a resource identifier. Subjects
            should almost always be assigned specific alphanumerical identifiers. */
  identifier: string;
  /** A name associated with the individual. */
  name: null | string;
  sex: null | Sex;
  /** The year of birth for the individual. */
  birth_year: null | number;
  height: null | number;
  weight: null | number;
  /** Whether this subject record is in active use.
                Many systems use this property to mark as non-current subjects, such as those that have not been seen for a
                period of time based on an organization's business rules. It is often used to filter subject lists to
                exclude inactive subjects.
                Deceased subjects may also be marked as inactive for the same reasons, but may be active for some time after
                death. */
  active: null | boolean;
  custom_fields: null | object;
  /** You can use this parameter to attach key-value data.
    You can specify up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.
    
    Metadata is useful for storing additional, structured information on an object. As an example, you could store
    your user's emergency contact name or corresponding unique identifiers from your system.
    Metadata is not used by the backend, and won't be seen by your users unless you choose to show it to them.
    
    Do not store any sensitive information (social security numbers, bank account numbers, etc.) as metadata.
     */
  metadata: null | object;
};
export type SubjectUpdate = {
  zone_id: null | string;
  /** An identifier for this patient. This is a business identifier, not a resource identifier. Subjects
            should almost always be assigned specific alphanumerical identifiers. */
  identifier: null | string;
  /** A name associated with the individual. */
  name: null | string;
  sex: null | Sex;
  /** The year of birth for the individual. */
  birth_year: null | number;
  height: null | number;
  weight: null | number;
  /** Whether this subject record is in active use.
                Many systems use this property to mark as non-current subjects, such as those that have not been seen for a
                period of time based on an organization's business rules. It is often used to filter subject lists to
                exclude inactive subjects.
                Deceased subjects may also be marked as inactive for the same reasons, but may be active for some time after
                death. */
  active: null | boolean;
  custom_fields: null | object;
  /** You can use this parameter to attach key-value data.
    You can specify up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.
    
    Metadata is useful for storing additional, structured information on an object. As an example, you could store
    your user's emergency contact name or corresponding unique identifiers from your system.
    Metadata is not used by the backend, and won't be seen by your users unless you choose to show it to them.
    
    Do not store any sensitive information (social security numbers, bank account numbers, etc.) as metadata.
     */
  metadata: null | object;
};
export type VitalThingsGuardianH10SettingsUser = {
  time: null | VitalThingsSomnofySm100SettingsUserTime;
  radar: null | VitalThingsSomnofySm100SettingsUserRadar;
  schedule: null | VitalThingsSomnofySm100SettingsUserScheduleItem[];
};
export type VitalThingsGuardianH10Settings = {
  user: null | VitalThingsGuardianH10SettingsUser;
};
export type DeviceVitalThingsGuardianH10 = {
  /** Each resource has an id element which contains the "logical id" of the resource assigned by the
            server responsible for storing it. Resources always have a known logical id except for a few special cases
            (e.g. when a new resource is being sent to a server to assign a logical id in the create interaction). The
            logical id is unique within the space of all resources of the same type on the same server. Once assigned by
            the server, the id is never changed.
             */
  id: string;
  object: null | "device.vitalthings-guardian-h10";
  type: "vitalthings-guardian-h10";
  virtual: boolean;
  manufacturer: null | string;
  product: null | string;
  part_number: null | string;
  serial_number: null | string;
  name: null | string;
  /** Device notes and comments */
  note: null | string;
  tenant_id: null | string;
  zone_id: null | string;
  path: null | string;
  path_level: null | number;
  subject_id: null | string;
  metadata: null | object;
  settings: null | VitalThingsGuardianH10Settings;
  latest_connectivity_event_at: null | string;
  online: null | boolean;
};
export type ListResponseDevice = {
  object: null | "list";
  data:
    | null
    | (
        | DeviceGeneric
        | DeviceVitalThingsGuardianH10
        | DeviceVitalThingsSomnofySm100
      )[];
};
export type ResponseDevice = {
  data:
    | null
    | DeviceGeneric
    | DeviceVitalThingsGuardianH10
    | DeviceVitalThingsSomnofySm100;
};
export type DeviceGenericCreate = {
  type: "generic";
  zone_id: string;
  virtual: null | boolean;
  manufacturer: null | string;
  product: null | string;
  part_number: null | string;
  serial_number: null | string;
  name: null | string;
  /** Device notes and comments */
  note: null | string;
  metadata: null | object;
};
export type DeviceVitalThingsGuardianH10Register = {
  type: "vitalthings-guardian-h10";
  zone_id: string;
  serial_number: string;
};
export type DeviceVitalThingsSomnofySm100Register = {
  type: "vitalthings-somnofy-sm100";
  zone_id: string;
  serial_number: string;
};
export type DeviceGenericUpdate = {
  type: "generic";
  zone_id: null | string;
  subject_id: null | string;
  virtual: null | boolean;
  manufacturer: null | string;
  product: null | string;
  part_number: null | string;
  serial_number: null | string;
  name: null | string;
  /** Device notes and comments */
  note: null | string;
  metadata: null | object;
};
export type DeviceVitalThingsGuardianH10Update = {
  type: "vitalthings-guardian-h10";
  zone_id: null | string;
  subject_id: null | string;
  name: null | string;
  note: null | string;
  metadata: null | object;
  settings: null | VitalThingsGuardianH10Settings;
};
export type DeviceVitalThingsSomnofySm100Update = {
  type: "vitalthings-somnofy-sm100";
  zone_id: null | string;
  subject_id: null | string;
  name: null | string;
  note: null | string;
  metadata: null | object;
  settings: null | VitalThingsSomnofySm100Settings;
};
export type UserState = "pending" | "active" | "inactive";
export type UserRole =
  | "OWNER"
  | "ADMIN"
  | "MANAGER"
  | "MANAGER_LIMITED"
  | "DATA_READER"
  | "DATA_READER_LIMITED"
  | "SERVICE_MONITOR";
export type EmbeddedZones = {
  object: null | "list";
  data: null | Zone[];
};
export type User = {
  id: string;
  object: null | "user";
  state: UserState;
  email: string;
  first_name: null | string;
  last_name: null | string;
  profession: null | string;
  roles: UserRole[];
  zones: null | EmbeddedZones;
  preferences: null | object;
  metadata: null | object;
  created_at: string;
  updated_at: string;
};
export type ListResponseUser = {
  object: null | "list";
  data: null | User[];
};
export type UserEmbed = "zones";
export type ResponseUser = {
  data: null | User;
};
export type UserCreate = {
  email: string;
  first_name: null | string;
  last_name: null | string;
  profession: null | string;
  roles: null | UserRole[];
  preferences: null | object;
  metadata: null | object;
};
export type UserUpdate = {
  email: null | string;
  first_name: null | string;
  last_name: null | string;
  profession: null | string;
  roles: null | UserRole[];
  preferences: null | object;
  metadata: null | object;
};
export type Result = "failed" | "success";
export type ActionResponse = {
  result: Result;
};
export type NotFound = {
  detail: null | string;
};
export type LiveMonitorItemState =
  | "device_offline"
  | "device_inactive"
  | "device_sleep_tracking_disabled"
  | "not_in_bed"
  | "awake_in_bed"
  | "asleep";
export type LiveMonitorItemZone = {
  id: string;
  path: string;
  name: string;
};
export type LiveMonitorItemDevice = {
  id: string;
  type: string;
  serial_number: null | string;
  name: null | string;
};
export type LiveMonitorItemSubject = {
  id: string;
  identifier: string;
  sex: null | Sex;
  /** The year of birth for the individual. */
  birth_year: null | number;
};
export type LiveMonitorItemMetrics = {
  respiration_rate_pooled_mean: null | number;
  respiration_rate_pool_size: null | number;
  latest_movement_mean: null | number;
  latest_spo2_mean: null | number;
};
export type LiveMonitorItem = {
  state: LiveMonitorItemState;
  state_changed_at: null | string;
  latest_epoch_timestamp: null | string;
  zone: LiveMonitorItemZone;
  device: LiveMonitorItemDevice;
  subject: null | LiveMonitorItemSubject;
  metrics: null | LiveMonitorItemMetrics;
  updated_at: string;
};
export type ListResponseLiveMonitorItem = {
  object: null | "list";
  data: null | LiveMonitorItem[];
};
export type NightReportSubject = {
  id: string;
  identifier: string;
  sex: null | Sex;
  /** The year of birth for the individual. */
  birth_year: null | number;
};
export type NightReportDevice = {
  id: string;
  type: string;
  serial_number: null | string;
  name: null | string;
};
export type NightReportState =
  | "PRESENCE_AND_SLEEP"
  | "NO_PRESENCE"
  | "NO_SLEEP";
export type NightReportMetricBase = {
  /** The value for the parameter for the report night. */
  value: number;
  /** The baseline for the parameter. */
  baseline: null | number;
  /** The difference between the value and the baseline. */
  deviation: null | number;
  /** The deviation in percent. None if baseline=0. */
  deviation_pct: null | number;
  /** Should the deviation be alerted? */
  deviation_alert: null | boolean;
};
export type NightReport = {
  /** Subject metadata for the person the report is generated for. */
  subject: null | NightReportSubject;
  /** Device metadata for the device used for the selected session. */
  device: null | NightReportDevice;
  /** Session ID for the session the report is generated for. */
  session_id: null | string;
  /** Start time of time night report. */
  timeframe_start: string;
  /** End time of time night report. */
  timeframe_end: string;
  /** Date of the night report. */
  report_date: string;
  /** Does the night include sleep data, other data or no data. */
  state: NightReportState;
  /** Is the report session still in progress or is it ended. */
  selected_session_is_in_progress: null | boolean;
  /** How many nights were used to calculate the baselines for which deviations and alerts are derived. */
  baseline_night_count: null | number;
  /** Metrics for respiration rate. */
  respiration_rate: null | NightReportMetricBase;
  /** Metrics for epochs with movement percent. */
  movement: null | NightReportMetricBase;
  /** Metrics for total time asleep. */
  time_asleep: null | NightReportMetricBase;
  /** Metrics for no presence count. */
  out_of_bed: null | NightReportMetricBase;
  /** Metrics for respiration sleep score. */
  sleep_score: null | NightReportMetricBase;
};
export type ListResponseReport = {
  object: null | "list";
  data: null | NightReport[];
};
export type SessionState = "IN_PROGRESS" | "ENDED";
export type VitalThingsSomnofySm100SessionEpochData = {
  /** Timestamp for epoch start time. Same as BaseDataFragment.Environment.timestamp. */
  timestamp: string[];
  /** Mean of signal_quality (BaseData.Motion) [unitless, 0-10] */
  signal_quality_mean: number[];
  /** Mean respiration rate during epoch [RPM] */
  respiration_rate_mean: number[];
  /** Variance of respiration rate during epoch [RPM^2] */
  respiration_rate_var: number[];
  /** Mean of movement_fast (BaseData.Motion) during epoch [unitless, 0-100] */
  movement_fast_mean: number[];
  /** Percent of epoch with nonzero movement_fast (BaseData.Motion) [0-100%] */
  movement_fast_nonzero_pct: null | number[];
  /** Mean heart rate during epoch [BPM] */
  heart_rate_mean: null | number[];
  /** Variance of heart rate during epoch [BPM^2] */
  heart_rate_var: null | number[];
  /** Mean heart rate from external device during epoch [BPM] */
  external_heart_rate_mean: null | number[];
  /** Variance of heart rate from external device during epoch [BPM^2] */
  external_heart_rate_var: null | number[];
  /** Mean SpO2 from external device [0-100%] */
  external_spo2_mean: null | number[];
  /** Variance of SpO2 from external device during epoch */
  external_spo2_var: null | number[];
  /** Minimum value of SpO2 from external device during epoch [0-100%] */
  external_spo2_min: null | number[];
  /** Mean distance from sensor during epoch [m] */
  distance_mean: number[];
  /** Number of radar measurements in epoch */
  motion_data_count: number[];
  /** Mean ambient light during epoch [lux] */
  light_ambient_mean: number[];
  /** Mean sound amplitude during epoch [dBA] */
  sound_amplitude_mean: number[];
  /** Mean ambient temperature during epoch [deg C] */
  temperature_ambient_mean: number[];
  /** Mean air pressure during epoch [Pa] */
  pressure_mean: number[];
  /** Mean air humidity during epoch [0-100%] */
  humidity_mean: number[];
  /** Mean indoor air quality during epoch [unitless, 0-500] */
  indoor_air_quality_mean: number[];
  /** Length of epoch [s] */
  epoch_duration: number[];
  /** Sleep stage scored by the sleep stage classifier ML algorithm [0-7] */
  sleep_stage: number[];
};
export type VitalThingsSomnofySm100Session = {
  object: null | "vitalthings-somnofy-sm100-session";
  id: string;
  state: SessionState;
  subject_id: string;
  device_serial_number: string;
  /** Number of epochs in the session [unitless] */
  epoch_count: number;
  /** Start time of the session (first measurement) */
  session_start: string;
  /** Session start in seconds after midnight (negative if before) [seconds] */
  relative_session_start: number;
  /** End time of the session (last measurement + 1s) */
  session_end: string;
  /** Time at last epoch */
  time_at_last_epoch: string;
  /** Intended sleep (last dimmed light or no presence before sleep onset) */
  time_at_intended_sleep: string;
  /** Intended wakeup (first brightened light or no presence after last wakeup) */
  time_at_intended_wakeup: null | string;
  /** Time spent in undefined sleep stage, e.g. if data is missing [seconds] */
  time_in_undefined: number;
  /** When the first sleep in session happens */
  time_at_sleep_onset: null | string;
  /** When the final wakeup in session happens */
  time_at_wakeup: null | string;
  /** Midpoint between sleep onset and wakeup */
  time_at_midsleep: null | string;
  /** Time from intended sleep to sleep onset [seconds] */
  sleep_onset_latency: null | number;
  /** Time from sleep onset to wakeup [seconds] */
  sleep_period: number;
  /** Time between intended sleep and intended wakeup [seconds] */
  time_in_bed: number;
  /** Time spent asleep [seconds] */
  time_asleep: number;
  /** 100 * time_asleep / time_in_bed [0-100%] */
  sleep_efficiency: number;
  /** Time spent in light sleep [seconds] */
  time_in_light_sleep: number;
  /** Time spent in REM sleep [seconds] */
  time_in_rem_sleep: number;
  /** Time spent in deep sleep [seconds] */
  time_in_deep_sleep: number;
  /** Time with no presence (during sleep period) [seconds] */
  time_in_no_presence: number;
  /** Number of separate occurrences of no presence (during sleep period) [unitless] */
  number_of_times_no_presence: number;
  /** Time spent awake during sleep period [seconds] */
  time_wake_after_sleep_onset: null | number;
  /** Number of times awake in sleep period [unitless] */
  number_of_times_awake: number;
  /** Number of times awake for >=5 consecutive epochs in sleep period [unitless] */
  number_of_times_awake_long: number;
  /** Sleep onset latency plus time from wakeup to intended wakeup [seconds] */
  time_wake_pre_post_sleep: null | number;
  /** Time from sleep onset to first REM sleep [seconds] */
  time_from_sleep_onset_to_first_rem: null | number;
  /** Mean movement during sleep period [unitless]. */
  movement_fast_during_sleep_period_mean: null | number;
  /** Mean respiration rate (filtered) during non-REM sleep [RPM] */
  rpm_non_rem_filtered_mean: null | number;
  /** Variance of EpochData.respiration_rate_mean during non-REM sleep [RPM^2] */
  rpm_non_rem_mean_var: null | number;
  /** Current respiration rate baseline from User.SleepHistoryMetrics [RPM] */
  rpm_non_rem_baseline: null | number;
  /** Current respiration rate baseline standard deviation from User.SleepHistoryMetrics [RPM] */
  rpm_non_rem_baseline_std: null | number;
  /** Mean heart rate (filtered) during non-REM sleep [BPM] */
  heart_rate_non_rem_filtered_mean: null | number;
  /** Mean heart rate during non-REM sleep [BPM] */
  heart_rate_non_rem_mean: null | number;
  /** Mean heart rate from external device during non-REM sleep [BPM] */
  external_heart_rate_non_rem_filtered_mean: null | number;
  /** Percent of epochs in session with movement [%] */
  epochs_with_movement_pct: number;
  /** Percent of epochs with movement during time in bed [%] */
  epochs_with_movement_during_time_in_bed_pct: null | number;
  /** Percent of seconds in session with movement [%] */
  time_with_movement_pct: null | number;
  /** Percent of seconds with movement during time in bed [%] */
  time_with_movement_during_time_in_bed_pct: null | number;
  /** Number of sleep cycles >15 min, determined by the occurrence of REM sleep */
  sleep_cycles_count: null | number;
  /** Whether wakeup day is a workday. Assumes workday is the same as weekday [Mon-Fri = True, Sat-Sun = False] */
  is_workday: boolean;
  /** MCTQ Chonotype: Midsleep on free days corrected for sleep debt [hours after midnight] */
  chronotype: null | number;
  /** Difference between midsleep on free days and work days [hours] */
  social_jet_lag: null | number;
  /** Previous chronotype minus midsleep of session [hours] */
  jet_lag: null | number;
  /** Sleep Regularity Index (SRI) for the last 14 days [unitless, -100-100] */
  regularity: null | number;
  /** Sleep score based on time in sleep stages and fragmentation [unitless, 0-100]. Adjusted for user demography. */
  sleep_score_standard: number;
  /** How fragmented the sleep is, based on 90 minute segments. Longer wake periods and fragmentation early in the night are punished harder. [unitless, 0(no fragmenation)-100] */
  sleep_fragmentation: number;
  /** Mean SpO2 from external device during session [0-100] */
  external_spo2_mean: null | number;
  /** Minimum SpO2 from external device during session [0-100] */
  external_spo2_min: null | number;
  /** Mean distance from device during epochs with sleep [m] */
  distance_during_sleep_mean: null | number;
  /** Standard deviation of distance from device during epochs with sleep [m] */
  distance_during_sleep_std: null | number;
  /** Mean air pressure during session [Pa] */
  air_pressure_filtered_mean: number;
  /** Mean ambient light during session [lux] */
  light_ambient_filtered_mean: number;
  /** Mean ambient light from last sleep to intended wakeup [lux] */
  light_ambient_at_wakeup_mean: null | number;
  /** Mean sound amplitude during session [dBA] */
  sound_amplitude_filtered_mean: number;
  /** Mean sound amplitude during sleep [dBA] */
  sound_amplitude_during_sleep_mean: null | number;
  /** Mean amplitude of sounds louder than 40 dBA during sleep [dBA] */
  sound_amplitude_during_sleep_filtered_for_noise_mean: null | number;
  /** Mean sound amplitude during sleep without movement [dBA] */
  sound_amplitude_during_sleep_filtered_for_movement_mean: null | number;
  /** Largest sound amplitude in the 5 last epochs before wakeup [dBA] */
  sound_amplitude_at_wakeup: null | number;
  /** Number of epochs with sound amplitude > 45 dBA [unitless] */
  epochs_with_sound_spikes_during_sleep_count: null | number;
  /** Number of awakenings coinciding with sound amplitude > mean + 10 dBA [unitless] */
  awakenings_caused_by_sound_count: null | number;
  /** Mean ambient temperature during session [deg C] */
  temperature_filtered_mean: number;
  /** Mean indoor air quality during session [unitless, 0-500] */
  indoor_air_quality_filtered_mean: number;
  /** Mean air humidity during session [%] */
  air_humidity_filtered_mean: number;
  epoch_data: null | VitalThingsSomnofySm100SessionEpochData;
};
export type ListResponseVitalThingsSomnofySm100Session = {
  object: null | "list";
  data: null | VitalThingsSomnofySm100Session[];
};
export type SessionType = "vitalthings-somnofy-sm100-session";
export type Sort = "asc" | "desc";
export type ResponseVitalThingsSomnofySm100Session = {
  data: null | VitalThingsSomnofySm100Session;
};
export type TimeRestriction = {
  start: string;
  end: string;
};
export type NotificationHandlerSmsConfigurationRecipient = {
  phone_number: string;
  time_restriction: null | TimeRestriction;
};
export type NotificationHandlerSmsConfiguration = {
  recipients: NotificationHandlerSmsConfigurationRecipient[];
};
export type NotificationHandlerSms = {
  id: string;
  object: null | "notification-handler.sms";
  type: "sms";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  created_at: string;
  updated_at: string;
  configuration: NotificationHandlerSmsConfiguration;
};
export type NotificationHandlerEmailConfiguration = {
  recipients: string[];
};
export type NotificationHandlerEmail = {
  id: string;
  object: null | "notification-handler.email";
  type: "email";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  created_at: string;
  updated_at: string;
  configuration: NotificationHandlerEmailConfiguration;
};
export type NotificationHandlerWebhookConfiguration = {
  url: string;
  headers: null | {
    [key: string]: string;
  };
};
export type NotificationHandlerWebhook = {
  id: string;
  object: null | "notification-handler.webhook";
  type: "webhook";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  created_at: string;
  updated_at: string;
  configuration: NotificationHandlerWebhookConfiguration;
};
export type NotificationHandlerSensioConfiguration = {
  api_base_url: string;
  username: string;
  password: string;
  client_id: string;
  client_secret: string;
  grant_type: null | "password";
};
export type NotificationHandlerSensio = {
  id: string;
  object: null | "notification-handler.sensio";
  type: "sensio";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  created_at: string;
  updated_at: string;
  configuration: NotificationHandlerSensioConfiguration;
};

// eslint-disable-next-line
export type NotificationHandlerSkyresponseConfiguration = {};
export type NotificationHandlerSkyresponse = {
  id: string;
  object: null | "notification-handler.skyresponse";
  type: "skyresponse";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  created_at: string;
  updated_at: string;
  configuration: null | NotificationHandlerSkyresponseConfiguration;
};
export type NotificationHandlerNetNordicConfiguration = {
  api_base_url: string;
  client_id: null | string;
  username: string;
  password: string;
};
export type NotificationHandlerNetNordic = {
  id: string;
  object: null | "notification-handler.netnordic";
  type: "netnordic";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  created_at: string;
  updated_at: string;
  configuration: NotificationHandlerNetNordicConfiguration;
};
export type NotificationHandlerResponseModels =
  | ({
      type: "sms";
    } & NotificationHandlerSms)
  | ({
      type: "email";
    } & NotificationHandlerEmail)
  | ({
      type: "webhook";
    } & NotificationHandlerWebhook)
  | ({
      type: "sensio";
    } & NotificationHandlerSensio)
  | ({
      type: "skyresponse";
    } & NotificationHandlerSkyresponse)
  | ({
      type: "netnordic";
    } & NotificationHandlerNetNordic);
export type ListResponseNotificationHandler = {
  object: null | "list";
  data: null | NotificationHandlerResponseModels[];
};
export type NotificationHandlerType =
  | "sms"
  | "email"
  | "webhook"
  | "sensio"
  | "skyresponse"
  | "netnordic";
export type ReponseNotificationHandler = {
  data: null | NotificationHandlerResponseModels;
};
export type NotificationHandlerSmsCreate = {
  type: "sms";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  configuration: NotificationHandlerSmsConfiguration;
};
export type NotificationHandlerEmailCreate = {
  type: "email";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  configuration: NotificationHandlerEmailConfiguration;
};
export type NotificationHandlerWebhookCreate = {
  type: "webhook";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  configuration: NotificationHandlerWebhookConfiguration;
};
export type NotificationHandlerSensioCreate = {
  type: "sensio";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  configuration: NotificationHandlerSensioConfiguration;
};
export type NotificationHandlerSkyresponseCreate = {
  type: "skyresponse";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  configuration: null | NotificationHandlerSkyresponseConfiguration;
};
export type NotificationHandlerNetNordicCreate = {
  type: "netnordic";
  zone_id: string;
  description: string;
  enabled: null | boolean;
  configuration: NotificationHandlerNetNordicConfiguration;
};
export type NotificationHandlerCreate =
  | ({
      type: "sms";
    } & NotificationHandlerSmsCreate)
  | ({
      type: "email";
    } & NotificationHandlerEmailCreate)
  | ({
      type: "webhook";
    } & NotificationHandlerWebhookCreate)
  | ({
      type: "sensio";
    } & NotificationHandlerSensioCreate)
  | ({
      type: "skyresponse";
    } & NotificationHandlerSkyresponseCreate)
  | ({
      type: "netnordic";
    } & NotificationHandlerNetNordicCreate);
export type NotificationHandlerSmsUpdate = {
  type: "sms";
  enabled: null | boolean;
};
export type NotificationHandlerEmailUpdate = {
  type: "email";
  enabled: null | boolean;
};
export type NotificationHandlerWebhookUpdate = {
  type: "webhook";
  enabled: null | boolean;
};
export type NotificationHandlerSensioUpdate = {
  type: "sensio";
  enabled: null | boolean;
};
export type NotificationHandlerSkyresponseUpdate = {
  type: "skyresponse";
  enabled: null | boolean;
};
export type NotificationHandlerNetNordicUpdate = {
  type: "netnordic";
  enabled: null | boolean;
};
export type NotificationHandlerUpdateModels =
  | ({
      type: "sms";
    } & NotificationHandlerSmsUpdate)
  | ({
      type: "email";
    } & NotificationHandlerEmailUpdate)
  | ({
      type: "webhook";
    } & NotificationHandlerWebhookUpdate)
  | ({
      type: "sensio";
    } & NotificationHandlerSensioUpdate)
  | ({
      type: "skyresponse";
    } & NotificationHandlerSkyresponseUpdate)
  | ({
      type: "netnordic";
    } & NotificationHandlerNetNordicUpdate);
export type NotificationHandlerTestResult = {
  successful: boolean;
  message: null | string;
  detail: null | string;
};
export type NotificationHandlerTestResponse = {
  notification_handler_id: string;
  entity_id: string;
  rule_id: string;
  notification_text: string;
  results: NotificationHandlerTestResult[];
};
export type ResponseNotificationHandlerTestResponse = {
  data: null | NotificationHandlerTestResponse;
};
export type NotificationHandlerTest = {
  entity_id: string;
  rule_id: string;
  notification_text: string;
};
export type RuleNoPresenceConditionsPreset = "default";
export type RuleNoPresenceConditionsOkToAlert = {
  /** Time in epochs with consecutive no presence to transition to alert state */
  no_presence_time_epochs_gte: null | number;
};
export type RuleNoPresenceConditionsAlertToOk = {
  /** Time in epochs with consecutive presence before state transitions from alert to ok */
  presence_time_epochs_gte: null | number;
};
export type RuleNoPresenceConditions = {
  /** Minimum time in epochs with presence (cumulative) before the rule is active */
  initial_presence_time_epochs_gte: null | number;
  ok_to_alert: null | RuleNoPresenceConditionsOkToAlert;
  alert_to_ok: null | RuleNoPresenceConditionsAlertToOk;
};
export type RuleNoPresenceConfiguration = {
  execute_actions_on_alert_to_ok: null | boolean;
};
export type RuleNoPresence = {
  id: string;
  object: null | "rule.no-presence";
  type: "subject.session.epochs.no-presence";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleNoPresenceConditionsPreset;
  conditions: null | RuleNoPresenceConditions;
  configuration: null | RuleNoPresenceConfiguration;
  created_at: string;
  updated_at: string;
};
export type RuleAwakeConditionsPreset = "default";
export type RuleAwakeConditionsOkToAlert = {
  /** Time in epochs with consecutive awake to transition to alert state */
  awake_time_epochs_gte: null | number;
};
export type RuleAwakeConditionsAlertToOk = {
  /** Time in epochs with consecutive sleep before state transitions from alert to ok */
  asleep_time_epochs_gte: null | number;
};
export type RuleAwakeConditions = {
  /** Minimum time in epochs with presence (cumulative) before the rule is active */
  initial_presence_time_epochs_gte: null | number;
  ok_to_alert: null | RuleAwakeConditionsOkToAlert;
  alert_to_ok: null | RuleAwakeConditionsAlertToOk;
};
export type RuleAwakeConfiguration = {
  execute_actions_on_alert_to_ok: null | boolean;
};
export type RuleAwake = {
  id: string;
  object: null | "rule.awake";
  type: "subject.session.epochs.awake";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleAwakeConditionsPreset;
  conditions: null | RuleAwakeConditions;
  configuration: null | RuleAwakeConfiguration;
  created_at: string;
  updated_at: string;
};
export type RuleSoundLevelConditionsPreset = "default";
export type RuleSoundLevelConditions = {
  /** Minimum time in epochs with presence (cumulative) before the rule is active */
  initial_presence_time_epochs_gte: null | number;
  /** Upper threshold for sound alert (float, dB) */
  sound_level_gte: null | number;
};
export type RuleSoundLevelConfiguration = {
  execute_actions_on_alert_to_ok: null | boolean;
};
export type RuleSoundLevel = {
  id: string;
  object: null | "rule.sound-level";
  type: "subject.session.epochs.sound-level";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleSoundLevelConditionsPreset;
  conditions: null | RuleSoundLevelConditions;
  configuration: null | RuleSoundLevelConfiguration;
  created_at: string;
  updated_at: string;
};
export type RuleMovementConditionsPreset = "default";
export type RuleMovementConditions = {
  /** Minimum time in epochs with presence (cumulative) before the rule is active */
  initial_presence_time_epochs_gte: null | number;
  /** Over how many epochs to calculate the mean */
  movement_mean_time_epochs_gte: null | number;
  /** Alert deviation threshold for mean movement (dimensionless, 0-100) */
  movement_mean_gte: null | number;
};
export type RuleMovementConfiguration = {
  execute_actions_on_alert_to_ok: null | boolean;
};
export type RuleMovement = {
  id: string;
  object: null | "rule.movement";
  type: "subject.session.epochs.movement";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleMovementConditionsPreset;
  conditions: null | RuleMovementConditions;
  configuration: null | RuleMovementConfiguration;
  created_at: string;
  updated_at: string;
};
export type RuleDeviceOfflineConditionsPreset = "default";
export type RuleDeviceOfflineConditions = {
  /** Time in seconds since last seen */
  time_since_last_seen_gte: null | number;
};
export type RuleDeviceOfflineConfiguration = {
  execute_actions_on_alert_to_ok: null | boolean;
};
export type RuleDeviceOffline = {
  id: string;
  object: null | "rule.device-offline";
  type: "device.offline";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  conditions_preset: null | RuleDeviceOfflineConditionsPreset;
  conditions: null | RuleDeviceOfflineConditions;
  configuration: null | RuleDeviceOfflineConfiguration;
  created_at: string;
  updated_at: string;
};
export type CronSchedule = {
  /** A string representing a Crontab pattern.
            This may get pretty advanced, like hour='*\/3' (for every three hours)
            or hour='0,8-17/2' (at midnight, and every two hours during office hours) */
  hour: null | string;
  /** A string representing a Crontab pattern. This may get pretty advanced,
            like minute='*\/15' (for every quarter) or minute='1,13,30-45,50-59/2' */
  minute: null | string;
  timezone: null | string;
};
export type RuleMultiDayRespirationRateDeviation = {
  id: string;
  object: null | "rule.multi-day-respiration-rate-deviation";
  type: "subject.sessions.respiration-rate-trend-deviation";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  schedule: CronSchedule;
  created_at: string;
  updated_at: string;
};
export type RuleDeviceEventConditions = {
  /** Events to match. Either full path or wildcard. Examples: 'vitalthings.somnofy.sm100.system.tamper'
            or 'vitalthings.somnofy.sm100.*' */
  type: string[];
};
export type RuleDeviceEvent = {
  id: string;
  object: null | "rule.device-event";
  type: "device.event";
  description: null | string;
  enabled: null | boolean;
  zone_id: null | string;
  subject_id: null | string;
  device_id: null | string;
  inheritance_enabled: boolean;
  inherited: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions: RuleDeviceEventConditions;
  created_at: string;
  updated_at: string;
};
export type RuleResponseModels =
  | ({
      type: "subject.session.epochs.no-presence";
    } & RuleNoPresence)
  | ({
      type: "subject.session.epochs.awake";
    } & RuleAwake)
  | ({
      type: "subject.session.epochs.sound-level";
    } & RuleSoundLevel)
  | ({
      type: "subject.session.epochs.movement";
    } & RuleMovement)
  | ({
      type: "device.offline";
    } & RuleDeviceOffline)
  | ({
      type: "subject.sessions.respiration-rate-trend-deviation";
    } & RuleMultiDayRespirationRateDeviation)
  | ({
      type: "device.event";
    } & RuleDeviceEvent);
export type ListResponseRule = {
  object: null | "list";
  data: null | RuleResponseModels[];
};
export type RuleType =
  | "subject.session.epochs.no-presence"
  | "subject.session.epochs.sound-level"
  | "subject.session.epochs.awake"
  | "subject.session.epochs.movement"
  | "subject.sessions.respiration-rate-trend-deviation"
  | "device.offline"
  | "device.event";
export type ReponseMonitoringRule = {
  data: null | RuleResponseModels;
};
export type RuleNoPresenceCreate = {
  type: "subject.session.epochs.no-presence";
  description: null | string;
  enabled: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleNoPresenceConditionsPreset;
  conditions: null | RuleNoPresenceConditions;
  configuration: null | RuleNoPresenceConfiguration;
  entity_id: string;
};
export type RuleAwakeCreate = {
  type: "subject.session.epochs.awake";
  description: null | string;
  enabled: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleAwakeConditionsPreset;
  conditions: null | RuleAwakeConditions;
  configuration: null | RuleAwakeConfiguration;
  entity_id: string;
};
export type RuleSoundLevelCreate = {
  type: "subject.session.epochs.sound-level";
  description: null | string;
  enabled: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleSoundLevelConditionsPreset;
  conditions: null | RuleSoundLevelConditions;
  configuration: null | RuleSoundLevelConfiguration;
  entity_id: string;
};
export type RuleMovementCreate = {
  type: "subject.session.epochs.movement";
  description: null | string;
  enabled: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions_preset: null | RuleMovementConditionsPreset;
  conditions: null | RuleMovementConditions;
  configuration: null | RuleMovementConfiguration;
  entity_id: string;
};
export type RuleDeviceOfflineCreate = {
  type: "device.offline";
  description: null | string;
  enabled: null | boolean;
  conditions_preset: null | RuleDeviceOfflineConditionsPreset;
  conditions: null | RuleDeviceOfflineConditions;
  configuration: null | RuleDeviceOfflineConfiguration;
  entity_id: string;
};
export type RuleMultiDayRespirationRateDeviationCreate = {
  type: "subject.sessions.respiration-rate-trend-deviation";
  description: null | string;
  enabled: null | boolean;
  schedule: CronSchedule;
  entity_id: string;
};
export type RuleDeviceEventCreate = {
  type: "device.event";
  description: null | string;
  enabled: null | boolean;
  /** The timeframe this rule should be active, null
            or { "start": "00:00", "end": "00:00" } means 24/7. */
  time_restriction: null | TimeRestriction;
  conditions: RuleDeviceEventConditions;
  entity_id: string;
};
export type RuleCreateModels =
  | ({
      type: "subject.session.epochs.no-presence";
    } & RuleNoPresenceCreate)
  | ({
      type: "subject.session.epochs.awake";
    } & RuleAwakeCreate)
  | ({
      type: "subject.session.epochs.sound-level";
    } & RuleSoundLevelCreate)
  | ({
      type: "subject.session.epochs.movement";
    } & RuleMovementCreate)
  | ({
      type: "device.offline";
    } & RuleDeviceOfflineCreate)
  | ({
      type: "subject.sessions.respiration-rate-trend-deviation";
    } & RuleMultiDayRespirationRateDeviationCreate)
  | ({
      type: "device.event";
    } & RuleDeviceEventCreate);
export type RuleConditionsPresetNoPresence = {
  id: string;
  type: "subject.session.epochs.no-presence.conditions";
  name: string;
  preset: RuleNoPresenceConditions;
};
export type RuleConditionsPresetAwake = {
  id: string;
  type: "subject.session.epochs.awake.conditions";
  name: string;
  preset: RuleAwakeConditions;
};
export type RuleConditionsPresetSoundLevel = {
  id: string;
  type: "subject.session.epochs.sound-level.conditions";
  name: string;
  preset: RuleSoundLevelConditions;
};
export type RuleConditionsPresetMovement = {
  id: string;
  type: "subject.session.epochs.movement.conditions";
  name: string;
  preset: RuleMovementConditions;
};
export type RuleConditionsPresetDeviceOffline = {
  id: string;
  type: "device.offline.conditions";
  name: string;
  preset: RuleDeviceOfflineConditions;
};
export type RulePreset =
  | ({
      type: "subject.session.epochs.no-presence.conditions";
    } & RuleConditionsPresetNoPresence)
  | ({
      type: "subject.session.epochs.awake.conditions";
    } & RuleConditionsPresetAwake)
  | ({
      type: "subject.session.epochs.sound-level.conditions";
    } & RuleConditionsPresetSoundLevel)
  | ({
      type: "subject.session.epochs.movement.conditions";
    } & RuleConditionsPresetMovement)
  | ({
      type: "device.offline.conditions";
    } & RuleConditionsPresetDeviceOffline);
export type ListResponseRulePreset = {
  object: null | "list";
  data: null | RulePreset[];
};
export type RulePresetType = "conditions";
export type RuleNoPresenceUpdate = {
  type: "subject.session.epochs.no-presence";
  enabled: null | boolean;
};
export type RuleAwakeUpdate = {
  type: "subject.session.epochs.awake";
  enabled: null | boolean;
};
export type RuleSoundLevelUpdate = {
  type: "subject.session.epochs.sound-level";
  enabled: null | boolean;
};
export type RuleMovementUpdate = {
  type: "subject.session.epochs.movement";
  enabled: null | boolean;
};
export type RuleDeviceOfflineUpdate = {
  type: "device.offline";
  enabled: null | boolean;
};
export type RuleMultiDayRespirationRateDeviationUpdate = {
  type: "subject.sessions.respiration-rate-trend-deviation";
  enabled: null | boolean;
};
export type RuleDeviceEventUpdate = {
  type: "device.event";
  enabled: null | boolean;
};
export type RuleUpdateModels =
  | ({
      type: "subject.session.epochs.no-presence";
    } & RuleNoPresenceUpdate)
  | ({
      type: "subject.session.epochs.awake";
    } & RuleAwakeUpdate)
  | ({
      type: "subject.session.epochs.sound-level";
    } & RuleSoundLevelUpdate)
  | ({
      type: "subject.session.epochs.movement";
    } & RuleMovementUpdate)
  | ({
      type: "device.offline";
    } & RuleDeviceOfflineUpdate)
  | ({
      type: "subject.sessions.respiration-rate-trend-deviation";
    } & RuleMultiDayRespirationRateDeviationUpdate)
  | ({
      type: "device.event";
    } & RuleDeviceEventUpdate);
export type EntityType = "subject" | "device";
export type RuleState = "ok" | "alert" | "pending";
export type RuleMonitorState = {
  state: null | RuleState;
  detail: null | string;
  timestamp: null | string;
  updated_at: null | string;
  latest_state_transition_at: null | string;
};
export type EventDataRule = {
  id: string;
  type: RuleType;
  time_restriction: null | object;
  conditions_preset: null | string;
  conditions: null | object;
  configuration: null | object;
  entity_type: EntityType;
  entity_id: string;
  previous_state: null | RuleMonitorState;
  current_state: null | RuleMonitorState;
};
export type NotificationLogActionResult = {
  timestamp: string;
  type: string;
  type_id: string;
  successful: boolean;
  message: string;
  detail: null | string;
};
export type NotificationLog = {
  id: string;
  object: null | "notification-log";
  zone_id: null | string;
  zone_name: null | string;
  subject_id: null | string;
  subject_identifier: null | string;
  device_id: null | string;
  device_serial_number: null | string;
  event_id: string;
  rule_id: null | string;
  rule: null | EventDataRule;
  actions: null | NotificationLogActionResult[];
  created_at: null | string;
  updated_at: null | string;
};
export type ListResponseNotificationLog = {
  object: null | "list";
  data: null | NotificationLog[];
};
export const {
  useFindTenantByIdTenantsIdGetQuery,
  useListZonesZonesGetQuery,
  useCreateZoneZonesPostMutation,
  useListZoneTreeZonesTreeGetQuery,
  useFindZoneByIdZonesIdGetQuery,
  useDeleteZoneZonesIdDeleteMutation,
  useUpdateZoneZonesIdPatchMutation,
  useZoneParentZonesIdParentGetQuery,
  useListZoneChildrenZonesIdChildrenGetQuery,
  useListZoneDescendantsZonesIdDescendantsGetQuery,
  useListZoneAncestorsZonesIdAncestorsGetQuery,
  useZoneTenantZonesIdTenantGetQuery,
  useListSubjectsSubjectsGetQuery,
  useCreateSubjectSubjectsPostMutation,
  useFindSubjectByIdSubjectsIdGetQuery,
  useDeleteSubjectSubjectsIdDeleteMutation,
  useUpdateSubjectSubjectsIdPatchMutation,
  useListDevicesDevicesGetQuery,
  useRegisterOrCreateADeviceDevicesPostMutation,
  useFindDeviceByIdDevicesIdGetQuery,
  useDeleteDeviceDevicesIdDeleteMutation,
  useUpdateDeviceDevicesIdPatchMutation,
  useListUsersUsersGetQuery,
  useCreateUserUsersPostMutation,
  useFindUserByIdUsersIdGetQuery,
  useDeleteUserUsersIdDeleteMutation,
  useUpdateUserUsersIdPatchMutation,
  useResetPasswordUsersIdResetPasswordPostMutation,
  useListUserZonesUsersIdZonesGetQuery,
  useAddUserZoneAssociationUsersUserIdZonesZoneIdPostMutation,
  useRemoveUserZoneAssociationUsersUserIdZonesZoneIdDeleteMutation,
  useLiveMonitoringLiveMonitoringGetQuery,
  useGenerateReportsReportsGetQuery,
  useListSessionsSessionsGetQuery,
  useFindSessionSessionsSessionIdGetQuery,
  useListNotificationHandlersNotificationHandlersGetQuery,
  useCreateNotificationHandlerNotificationHandlersPostMutation,
  useFindNotificationHandlerNotificationHandlersIdGetQuery,
  useDeleteNotificationHandlerNotificationHandlersIdDeleteMutation,
  useUpdateNotificationHandlerNotificationHandlersIdPatchMutation,
  useTestNotificationHandlerNotificationHandlersIdTestPostMutation,
  useListRulesRulesGetQuery,
  useCreateRuleRulesPostMutation,
  useListRulePresetsRulesPresetsGetQuery,
  useFindRuleRulesIdGetQuery,
  useDeleteRuleRulesIdDeleteMutation,
  useUpdateRuleRulesIdPatchMutation,
  useListLogsNotificationLogsGetQuery,
} = injectedRtkApi;
