import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import VitalthingsPNG from "assets/icons/vitalthings-logo.png";
import {
  AuthorizationContext,
  AuthorizationPermissionsType,
  SubjectPermissionEnum,
  UserPermissionEnum,
  ZonePermissionEnum,
} from "authorization/AuthorizationContext";
import CustomizedMenus from "components/StyledMenu/StyledMenu";
import { useNavigate } from "hooks/useNavigate";
import { SMMenuProps } from "interfaces/menu.interface";
import { AppRoute } from "interfaces/router.interface";
import { IUser } from "interfaces/user.interface";
import { clearLiveMonitoring } from "store/actions/dashboard/monitorActions";
import { clearCookies, getLogoutUrl } from "utils/auth/auth.util";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { useAppDispatch } from "store";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: 28,
  width: 28,
  fontSize: 16,
  backgroundColor: theme.palette.neutral[60],
}));

interface Props {
  screens: AppRoute[];
  loginedUser: IUser | undefined;
}

const StyledAppBar = styled(AppBar)(() => {
  const theme = useTheme();
  return {
    background: theme.palette.white,
    height: 55,
    minHeight: 55,
    boxShadow: "none",
    zIndex: 100,
    paddingRight: 16,

    borderBottom: `1px solid ${theme.palette.neutral[5]}`,
    "& .MuiToolbar-root": {
      minHeight: 55,
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const StyledButton = styled(Button)(({ active }: { active: number }) => {
  const theme = useTheme();
  return {
    // for to fix conflix issue with mui version-4
    "&.MuiButtonBase-root": {
      color: active ? theme.palette.main[70] : theme.palette.neutral[50],
      fontWeight: 500,
      fontSize: 16,
      textTransform: "none",
      marginRight: 43,
      padding: "10px 12px 9px 15px",
      height: 53,
      borderRadius: 0,
      borderBottom: active
        ? `1.5px solid ${theme.palette.main[70]}`
        : `1.5px solid #FFF`,
    },
    color: active ? theme.palette.main[70] : theme.palette.neutral[50],
    fontWeight: 500,
    fontSize: 16,
    textTransform: "none",
    marginRight: 43,
    padding: "10px 12px 9px 15px",
    height: 53,
    borderRadius: 0,
    borderBottom: active
      ? `1.5px solid ${theme.palette.main[70]}`
      : `1.5px solid #FFF`,
    "&:hover": {
      background: `${theme.palette.main[2]}`,
    },
  };
});

const UserNameWrapped = styled("div")(() => {
  const theme = useTheme();
  return {
    maxWidth: 150,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    float: "right",
    color: theme.palette.neutral[80],
    fontSize: 16,
    marginLeft: 8,
    display: "block",
  };
});

function SMAdminAppBar({ screens, loginedUser }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { permissions } = useContext(AuthorizationContext);

  const gotoScreen = (path: string) => {
    dispatch(clearLiveMonitoring());
    navigate(path);
  };

  const onClick = (route: SMMenuProps) => {
    if (route.value === "profile") {
      navigate(`/${route.value}`);
    } else if (route.value === "logoff") {
      clearCookies();
      // dispatch(resetAuthState());
      window.open(getLogoutUrl(), "_self");
    } else {
      navigate(`/administration/${route.value}`);
    }
  };

  const isHidden = (policy: AuthorizationPermissionsType): boolean => {
    if (!permissions) {
      return false;
    }
    return permissions.indexOf(policy) === -1;
  };

  const menus: SMMenuProps[] = [
    {
      label: t("subject"),
      value: "create-subject",
      hide: isHidden(SubjectPermissionEnum["subject.create"]),
      hideDivider: true,
    },
    {
      label: t("employee"),
      value: "create-user",
      hide: isHidden(UserPermissionEnum["user.create"]),
    },
    {
      label: t("Zone"),
      value: "create-zone",
      hide: isHidden(ZonePermissionEnum["zone.create"]),
    },
  ];

  const profileMenu: SMMenuProps[] = [
    {
      label: t("employee_profile"),
      value: "profile",
      hideDivider: true,
      hide: loginedUser?.roles.includes(ZoneUserRolesEnum.SERVICE_MONITOR),
    },
    {
      label: t("Logout"),
      value: "logoff",
    },
  ];

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  const filteredScreen = (routes: AppRoute[]) => {
    return routes.filter((item) => {
      return "link" in item;
    });
  };

  return (
    <div>
      {/* <Shadow /> */}
      <StyledAppBar position="fixed">
        <Toolbar sx={{ display: "flex", gap: 2 }}>
          <img
            src={VitalthingsPNG}
            style={{
              height: "100%",
              paddingLeft: 24,
              paddingTop: 12,
              paddingBottom: 12,
              marginRight: 56,
            }}
            alt="VitalThings Logo"
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            {filteredScreen(screens).map(({ link }) => (
              <StyledButton
                key={link.href}
                onClick={() => gotoScreen(link.href)}
                active={isActive(link.href) ? 1 : 0}
              >
                {/* @ts-ignore TODO: Fix type issue */}
                {t(link.name)}
              </StyledButton>
            ))}
          </Box>
          {menus.filter((menu: SMMenuProps) => !menu.hide)?.length && (
            <CustomizedMenus
              onClick={onClick}
              menus={menus}
              menuLabel={t("Create")}
            />
          )}
          <UserNameWrapped>
            {loginedUser?.first_name} {loginedUser?.last_name}{" "}
          </UserNameWrapped>
          <CustomizedMenus
            onClick={onClick}
            menus={profileMenu}
            manuLabelComponent={
              <IconButton>
                <StyledAvatar>
                  {loginedUser?.first_name?.[0] ?? "U"}
                </StyledAvatar>
              </IconButton>
            }
          />
        </Toolbar>
      </StyledAppBar>
      <div style={{ minHeight: 55 }} />
    </div>
  );
}
export default SMAdminAppBar;
