import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { createRef, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { IHistoryVitalsPlotData } from "interfaces/sleephistory.interface";
import { getLangKey } from "utils/common/locale.util";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";
import type { SleepHistorySelectedDates } from "../sleepHistoryTypes";
import { useEnableHeartRateMean } from "../hooks/useEnableHeartRateMean";
import { useFormatDuration } from "hooks/useFormatDuration";

function SleepHistoryVitalsChartMemo({
  onChartMouseOver,
  plotData,
  selectedDates,
}: {
  onChartMouseOver: (e: Event) => void;
  plotData: IHistoryVitalsPlotData;
  selectedDates: SleepHistorySelectedDates;
}) {
  const { enableHeartRateMean } = useEnableHeartRateMean();

  const startDateMs = new Date(selectedDates.from).getTime();
  const endDateMs = new Date(selectedDates.to).getTime();

  const chartRef = createRef<HighchartsReactRefObject>();
  const { t } = useTranslation();
  const formatDuration = useFormatDuration();
  const theme = useTheme();
  const [showVitals, setShowVitals] = useState<boolean>(true);

  const onResize = useCallback(() => {
    chartRef.current?.chart.reflow();
  }, [chartRef]);

  const { ref } = useResizeDetector({
    handleWidth: true,
    onResize: onResize,
  });

  return (
    <>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <CardHeader title={`${t("Sleep History Vitals")}`} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{`${showVitals ? t("Hide") : t("Show")} ${t(
            "Vital signs",
          )}`}</Typography>
          <IconButton
            onClick={() => setShowVitals(!showVitals)}
            aria-expanded={showVitals}
            aria-label="Show vitals"
          >
            {showVitals ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={showVitals} mountOnEnter unmountOnExit>
        <CardContent>
          <div ref={ref}>
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              constructorType="stockChart"
              options={getOptions({
                plotData,
                onChartMouseOver,
                enableHeartRateMean,
                t,
                theme,
                startDateMs,
                endDateMs,
                formatDuration,
              })}
            />
          </div>
        </CardContent>
      </Collapse>
    </>
  );
}

const SleepHistoryVitalsChart = memo(SleepHistoryVitalsChartMemo);
export default SleepHistoryVitalsChart;

const getOptions = ({
  plotData,
  onChartMouseOver,
  enableHeartRateMean,
  t,
  theme,
  startDateMs,
  endDateMs,
  formatDuration,
}: {
  onChartMouseOver: (e: Event) => void;
  plotData: IHistoryVitalsPlotData;
  enableHeartRateMean: boolean;
  t: any;
  theme: Theme;
  startDateMs: number;
  endDateMs: number;
  formatDuration: (seconds: number) => string;
}) => {
  const colorType = "light";
  const showHeartRate =
    plotData?.nonREMMeanHeartRate.length > 0 && enableHeartRateMean;
  const showExternalHeartRate =
    plotData?.nonREMMeanExternalHeartRate?.length > 0;
  const showExternalSpO2 = plotData?.externalSpO2Mean?.length > 0;
  const vitalSignsSettings =
    getLocalSettings(USER_SETTINGS_CONSTANTS.vital_signs_settings) || {};

  // const localSettings = getLocalSettings(USER_SETTINGS_CONSTANTS.vital_signs_settings) || {};
  if (vitalSignsSettings.resting_respiration_rate === undefined) {
    vitalSignsSettings.resting_respiration_rate = true;
  }
  return {
    chart: {
      type: "spline",
      backgroundColor: "transparent",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: {
      text: null, // 'Somnofy Sleep History'
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      min: startDateMs,
      max: endDateMs,
      minTickInterval:
        plotData?.epochsWithMovementPct?.length <= 1
          ? undefined
          : 24 * 3600 * 1000, // One day in milliseconds
      labels: {
        formatter(a: any) {
          const xVal = new Date(a.value);
          const options: Intl.DateTimeFormatOptions = {
            month: "short",
            day: "numeric",
            timeZone: "Europe/Oslo",
            hourCycle: "h23",
          };
          const currentDate = xVal.toLocaleString(getLangKey(), options);

          return currentDate;
        },
      },
      title: {
        text: t("date"),
      },
      ordinal: false,
    },
    yAxis: [
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 0,
        labels: {
          enabled: false,
        },
      },
      {
        min: 20,
        labels: {
          enabled: false,
        },
      },
      {
        min: 60,
        labels: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: true,
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType],
        textDecoration: "none",
      },
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      series: {
        gapSize: 36 * 60 * 60 * 1000,
        gapUnit: "value",
        dataGrouping: {
          enabled: false,
        },
        marker: {
          enabled: true,
        },
        events: {
          legendItemClick(event: any) {
            if (event?.target?.userOptions?.slug) {
              vitalSignsSettings[event.target.userOptions.slug] =
                event.target.userOptions.visible;

              setLocalSettings({
                [USER_SETTINGS_CONSTANTS.vital_signs_settings]:
                  vitalSignsSettings,
              });
            }
          },
        },
      },
    },
    series: [
      {
        name: t("Resting respiration rate (rpm)"),
        slug: "resting_respiration_rate",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: (e: Event) => {
              onChartMouseOver(e);
            },
          },
        },
        yAxis: 0,
        color: "blue",
        visible: !!vitalSignsSettings.resting_respiration_rate,
        data: plotData.nonREMMeanRespirationRate,
      },
      {
        name: t("Mean heart rate"),
        slug: "mean_heart_rate",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 1,
        color: "red",
        data: plotData.nonREMMeanHeartRate,
        visible: showHeartRate && !!vitalSignsSettings.mean_heart_rate,
        showInLegend: showHeartRate,
      },
      {
        name: t("Mean heart rate external"),
        slug: "mean_heart_rate_external",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 1,
        color: "purple",
        data: plotData.nonREMMeanExternalHeartRate,
        visible:
          showExternalHeartRate &&
          !!vitalSignsSettings.mean_heart_rate_external,
        showInLegend: showExternalHeartRate,
      },
      {
        name: t("Mean SpO2 external"),
        slug: "mean_sp02_external",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 8,
        color: "blue",
        data: plotData.externalSpO2Mean,
        visible: showExternalSpO2 && !!vitalSignsSettings.mean_sp02_external,
        showInLegend: showExternalSpO2,
      },
      {
        name: t("time_asleep"),
        slug: "time_asleep",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 2,
        color: "black",
        visible: !!vitalSignsSettings.time_asleep,
        data: plotData.timeAsleep,
      },
      {
        name: t("Sleep Onset"),
        slug: "sleep_onset",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 2,
        color: "yellow",
        visible: !!vitalSignsSettings.sleep_onset,
        data: plotData.sleepOnset,
      },
      {
        name: t("Sleep Efficiency"),
        slug: "sleep_efficieny",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 3,
        color: "green",
        visible: !!vitalSignsSettings.sleep_efficieny,
        data: plotData.sleepEfficiency,
      },
      {
        name: t("Sleep Score"),
        slug: "sleep_score",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 3,
        color: "orange",
        visible: !!vitalSignsSettings.sleep_score,
        data: plotData.sleepScore,
      },
      {
        name: t("Times awake"),
        slug: "times_awake",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 4,
        color: "brown",
        visible: !!vitalSignsSettings.times_awake,
        data: plotData.numberOfTimesAwakeLong,
      },
      {
        name: t("Times not present"),
        slug: "times_not_present",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 4,
        color: "orange",
        visible: !!vitalSignsSettings.times_not_present,
        data: plotData.numberOfTimesNoPresence,
      },

      {
        name: t("Time with movement"),
        slug: "time_with_movement",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 5,
        color: "black",
        visible: !!vitalSignsSettings.time_with_movement,
        data: plotData.epochsWithMovementPct,
      },
      {
        name: t("illuminance"),
        slug: "light",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 6,
        color: "orange",
        visible: !!vitalSignsSettings.light,
        data: plotData.lightAmbientMean,
      },
      {
        name: t("Sound amplitude"),
        slug: "sound_amplitude",
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        yAxis: 7,
        color: "green",
        visible: !!vitalSignsSettings.sound_amplitude,
        data: plotData.soundAmplitudeMean,
      },
    ],
    tooltip: {
      shared: true,
      split: false,
      crosshairs: true,
      formatter() {
        // @ts-ignore
        const { points } = this;
        // points
        if (!points) {
          return <div>ss</div>;
        }

        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);

        const options: Intl.DateTimeFormatOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hourCycle: "h23",
          timeZone: "Europe/Oslo",
        };
        const currentDate = new Date(timestamp).toLocaleString(
          getLangKey(),
          options,
        );

        const currentDateUppercasefirstChar =
          currentDate.charAt(0).toUpperCase() + currentDate.slice(1);
        let tooltipMarkup: string = pointsLength
          ? `<span style="font-size: 10px;font-weight:bold">${currentDateUppercasefirstChar}</span><br/>`
          : "";
        let index;

        for (index = 0; index < pointsLength; index += 1) {
          if (
            points[index].series.userOptions.slug === "resting_respiration_rate"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} RPM</b><br/>`;
          else if (
            points[index].series.userOptions.slug === "mean_heart_rate" ||
            points[index].series.userOptions.slug === "mean_heart_rate_external"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("bpm")}</b><br/>`;
          else if (
            points[index].series.userOptions.slug === "time_asleep" ||
            points[index].series.userOptions.slug === "sleep_onset"
          ) {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            tooltipMarkup += formatDuration(points[index].y);
            tooltipMarkup += "</b><br/>";
          } else if (points[index].series.userOptions.slug === "light") {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("lux")}</b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "sound_amplitude"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("dba")}</b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "sleep_efficieny" ||
            points[index].series.userOptions.slug === "time_with_movement"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} %</b><br/>`;
          } else
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)}</b><br/>`;
        }
        return tooltipMarkup;
      },
    },
  };
};
