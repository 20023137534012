import { IDevice } from "interfaces/device.interface";

export const RESET_DEVICE_STATE = "RESET_DEVICE_STATE";
export const FETCH_DEVICE = "FETCH_DEVIE";
export const FETCH_DEVICE_SUCCESS = "FETCH_DEVICES_SUCCESS";

export const FETCH_DEVICE_BY_ID = "FETCH_DEVICE_BY_ID";
export const FETCH_DEVICE_BY_ID_SUCCESS = "FETCH_DEVICE_BY_ID_SUCCESS";

export const CREATE_DEVICE = "CREATE_DEVICE";
export const CREATE_DEVICE_SUCCESS = "CREATE_DEVICE_SUCCESS";

export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";

export const RESET_DEVICE_ACTIONS = "RESET_DEVICE_ACTIONS";

export interface resetDeviceStateActionType {
  type: typeof RESET_DEVICE_STATE;
}
export interface resetDeviceActionType {
  type: typeof RESET_DEVICE_ACTIONS;
}
export interface fetchDeviceActionType {
  type: typeof FETCH_DEVICE;
}
export interface fetchDeviceSuccessActionType {
  type: typeof FETCH_DEVICE_SUCCESS;
  payload: IDevice[];
}

export interface createDeviceActionType {
  type: typeof CREATE_DEVICE;
}
export interface createDeviceSuccessActionType {
  type: typeof CREATE_DEVICE_SUCCESS;
  payload: IDevice;
}

export interface updateDeviceActionType {
  type: typeof UPDATE_DEVICE;
}
export interface updateDeviceSuccessActionType {
  type: typeof UPDATE_DEVICE_SUCCESS;
  payload: IDevice;
}

export interface fetchDeviceByIDActionType {
  type: typeof FETCH_DEVICE_BY_ID;
}

export interface fetchDeviceByIDSuccessActionType {
  type: typeof FETCH_DEVICE_BY_ID_SUCCESS;
  payload: IDevice;
}

export type devicesActionTypes =
  | resetDeviceActionType
  | fetchDeviceActionType
  | fetchDeviceSuccessActionType
  | createDeviceActionType
  | createDeviceSuccessActionType
  | updateDeviceActionType
  | updateDeviceSuccessActionType
  | resetDeviceStateActionType
  | fetchDeviceByIDActionType
  | fetchDeviceByIDSuccessActionType;
