export const viewMode = {
  last_night: "last_night",
  history: "history",
} as const;

export type ViewMode = (typeof viewMode)[keyof typeof viewMode];

export type SleepHistorySelectedDates = {
  from: string;
  to: string;
  type?: string;
};

export const chartType = {
  area_chart: "area_chart",
  column_chart: "column_chart",
};

export type ChartType = (typeof chartType)[keyof typeof chartType];

export type HighchartsType = "areaspline" | "column";
