import { Grid } from "@mui/material";
import AutoSuggestFormDropDown from "components/AutoSuggestSelect/AutoSuggestFormDropDown";
import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import SMConfirmDialogNoLabel from "components/SMDialogs/SMConfirmDialogNoLabel";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import SubTitle from "components/SMTitles/SubTitle";
import SMUpdateCancel from "components/SMUpdateCancel/SMUpdateCancel";
import UseAdminContext from "hooks/UseAdminContext";
import { ISubject } from "interfaces/subject.interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  createSubject,
  updateSubject,
} from "store/actions/administration/subjectActions";
import { resetRulesState } from "store/actions/rules/rules";
import { InputLabelStyled } from "styled/CommonStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import { ApplyMargin } from "styled/StylesStyled";
import getErrorInputElement from "utils/common/getErrorInputElement";
import { useNavigate } from "hooks/useNavigate";
import { setZone } from "utils/localStorage";
import { toastSuccessPositionCenter } from "utils/toast.util";
import { generateBirthYearOptions } from "utils/userManagement";
import { getZoneDropDownOptions } from "utils/zone.util";
import { useAppDispatch, useAppSelector } from "store";

export default function SubjectInformation({
  create = false,
  subject,
  disable,
}: {
  create?: boolean;
  subject: ISubject;
  disable?: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const authUserZones = useAppSelector(
    // TODO: Fix defaulting
    (state) => state.authReducerV1.authorizedZones || [],
  );

  const { zones, selectedZone, setSelectedZone } = UseAdminContext();

  const defaultValues = useMemo<ISubject>(
    () => ({
      ...subject,
      zone_id: create ? (selectedZone ?? "") : subject.zone_id,
      name: subject.name ?? "",
      birth_year: subject.birth_year ?? "",
      height: subject.height ?? "",
      weight: subject.weight ?? "",
    }),
    [create, selectedZone, subject],
  );

  const { handleSubmit, control, reset, resetField, getValues } =
    useForm<ISubject>({
      defaultValues: {
        ...defaultValues,
      },
      mode: "onTouched",
    });

  const resetFormFields = useCallback(() => {
    reset({
      ...defaultValues,
    });
  }, [defaultValues, reset]);

  useEffect(() => {
    resetFormFields();
  }, [subject, resetFormFields]);

  const createSubjectSuccessCB = (item: ISubject) => {
    toastSuccessPositionCenter(t("Subject created successfully"));

    if (item && item.id && item.zone_id) {
      if (setSelectedZone) {
        setSelectedZone(item.zone_id);
      }
      setZone(item.zone_id);
      navigate(`/administration/zones/subjects/${item.id}`);
    }
  };

  const updateSubjectErrorCB = () => {
    resetFormFields();
  };

  const successCallback = () => {
    const data: ISubject = getValues();
    if (setSelectedZone) {
      setSelectedZone(data.zone_id);
    }
  };

  const onSubmit: SubmitHandler<ISubject> = (data: ISubject) => {
    if (data.id) {
      // refresh rules if current zone changed
      const refeshRules = data.zone_id !== subject.zone_id;
      dispatchUpdateSubject(data, false, refeshRules);
    } else {
      dispatch(createSubject(data, createSubjectSuccessCB));
      reset();
    }
  };

  // if the zone is changed then we should updated the devices associated
  // also update the rules
  const onUpdateZone = () => {
    const data: ISubject = getValues();
    dispatchUpdateSubject(data, true, true);
  };

  const dispatchUpdateSubject = (
    sub: ISubject,
    removeDevice: boolean,
    refreshRules?: boolean,
  ) => {
    if (refreshRules) {
      dispatch(resetRulesState());
    }
    dispatch(
      updateSubject({
        subject: sub,
        removeDevice,
        updateSubjectErrorCB,
        successCallback,
        refreshRules: !!refreshRules,
      }),
    );
  };

  const cancelZoneUpdate = () => {
    resetField("zone_id");
  };

  const errorFocusOrder: { [key: string]: string } = {
    identifier: "a",
    sex: "b",
    birth_year: "c",
  };

  function onErrors(err: FieldErrors<ISubject>) {
    const { elem, key } = getErrorInputElement<ISubject>(err, errorFocusOrder);
    if (elem) {
      if (key === "birth_year") {
        const firstInput = Array.from(elem.querySelectorAll("input")).find(
          () => true,
        );
        if (firstInput) {
          firstInput.focus();
        }
      } else {
        elem.focus();
      }
    }
  }

  return (
    <div>
      <BoxSubSectionStyled>
        <SubTitle text={t("Subject Information")} />
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <BoxSubSectionContentStyled>
            <Grid container rowSpacing={0} columnSpacing={2.5}>
              <Grid item xs={12} md={6}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Zone")}{" "}
                  </InputLabelStyled>
                </ApplyMargin>
                <Controller
                  name="zone_id"
                  rules={{ required: `${t("error_message_subject_zone_id")}` }}
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <SMFormDropDown
                      field={field}
                      disabled={!!disable}
                      fieldState={fieldState}
                      formState={formState}
                      options={getZoneDropDownOptions(
                        zones,
                        authUserZones.map((item) => item.id) || [],
                      )}
                      width="100%"
                      onUpdate={() => {
                        if (!create) {
                          if (subject?.devices?.data?.length) {
                            setOpenDialog(true);
                          } else if (fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Subject ID")}{" "}
                  </InputLabelStyled>
                  <Controller
                    rules={{
                      required: `${t("error_message_subject_identifier")}`,
                    }}
                    name="identifier"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormTextField
                        field={field}
                        disabled={!!disable}
                        placeholder={t("create_subject_id_placeholder")}
                        formState={formState}
                        fieldState={fieldState}
                        inlineEdit={!create}
                        onCancel={() => {
                          resetField("identifier");
                        }}
                        onUpdate={() => {
                          if (fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>
              <Grid item xs={12} md={6}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Description (optional)")}
                  </InputLabelStyled>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormTextField
                        field={field}
                        formState={formState}
                        disabled={!!disable}
                        fieldState={fieldState}
                        inlineEdit={!create}
                        placeholder={
                          disable
                            ? ""
                            : t("create_subject_description_placeholder")
                        }
                        onCancel={() => {
                          resetField("name");
                        }}
                        onUpdate={() => {
                          if (fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>
              <Grid item xs={12} md={6}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Sex")}
                  </InputLabelStyled>
                  <Controller
                    name="sex"
                    rules={{
                      required: `${t("error_message_subject_sex")}`,
                      validate: {
                        isSexSelected: (value) => {
                          if (value === "male" || value === "female") {
                            return true;
                          }
                          return `${t("error_message_subject_sex")}`;
                        },
                      },
                    }}
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormDropDown
                        field={field}
                        disabled={!!disable}
                        fieldState={fieldState}
                        formState={formState}
                        placeholder={t("Select sex")}
                        options={[
                          {
                            id: "1",
                            name: t("Male"),
                            value: "male",
                          },
                          {
                            id: "2",
                            name: t("Female"),
                            value: "female",
                          },
                        ]}
                        width="100%"
                        onUpdate={() => {
                          if (!create && fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>
              <Grid item xs={12} md={6}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Birth Year")}
                  </InputLabelStyled>

                  <Controller
                    name="birth_year"
                    control={control}
                    rules={{
                      required: `${t("error_message_subject_birth_year")}`,
                    }}
                    render={({ field, fieldState }) => (
                      <AutoSuggestFormDropDown
                        field={field}
                        disabled={!!disable}
                        placeholder={t("create_subject_birth_year_placeholder")}
                        options={generateBirthYearOptions()}
                        fieldState={fieldState}
                        onUpdate={() => {
                          if (!create && fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>
            </Grid>
          </BoxSubSectionContentStyled>
          <SMConfirmDialogNoLabel
            title={t("update_subject_change_zone_popup_title")}
            dialogDesc={`${t("update_subject_change_zone_popup_body")}`}
            onDelete={onUpdateZone}
            buttonOk={t("ok")}
            buttonCancel={t("Cancel")}
            okButtonBg="green"
            open={openDialog}
            setOpen={setOpenDialog}
            onCancel={cancelZoneUpdate}
          />
        </form>
      </BoxSubSectionStyled>
      {create && (
        <SMUpdateCancel
          onCancel={() => navigate(-1)}
          onUpdate={handleSubmit(onSubmit, onErrors)}
          create
        />
      )}
    </div>
  );
}
