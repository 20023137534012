import { styled, SxProps } from "@mui/material/styles";
import { Box, MenuItem, MenuItemProps } from "@mui/material";
import { MouseEvent, ReactNode, RefObject, forwardRef } from "react";

const StyledMenuItem = styled(MenuItem)(() => {
  return {
    padding: "6px 8px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    fontSize: "16px",
    "&:hover": {
      background: "none",
    },
  };
});

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
});

type IconMenuItemProps = {
  MenuItemPropsRest?: MenuItemProps;
  className?: string;
  disabled?: boolean;
  label?: string;
  leftIcon?: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  ref?: RefObject<HTMLLIElement>;
  sx?: SxProps;
};

export const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  function IconMenuItem(
    {
      MenuItemPropsRest,
      className,
      label,
      leftIcon,
      ...props
    }: IconMenuItemProps,
    ref,
  ) {
    const { sx } = props;
    return (
      <StyledMenuItem
        {...MenuItemPropsRest}
        sx={sx}
        ref={ref}
        className={className}
        {...props}
        disableRipple
      >
        <FlexBox>
          {leftIcon}
          <Box
            sx={{
              lineHeight: "20px",
              letterSpacing: "0.25px",
              minWidth: "186px",
              fontSize: "14px",
            }}
          >
            {" "}
            {label}
          </Box>
        </FlexBox>
      </StyledMenuItem>
    );
  },
);
