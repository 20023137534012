import axios from "axios";

import { IZone } from "interfaces/zone.interface";

import { API_SERVICES } from "../../constants/constants";
import {
  handleError,
  handleResponse,
  showErrorFeedback,
} from "../responseHandler";

export const zoneServices = {
  fetchZones,
  createZone,
  updateZone,
  deleteZone,
};

/**
 * This function fetch all the zone
 * @param
 * @return all the zones in the system
 */
async function fetchZones() {
  const url = API_SERVICES.zones;
  return axios({
    method: "get",
    url,
    timeout: 30000,

    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function createZone(data: {
  name: string;
  parent_id?: string | null;
  description?: string | null;
  metadata?: object | null;
}) {
  const url = API_SERVICES.zones;
  return axios({
    method: "post",
    url,
    timeout: 30000,

    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

/**
 * This function update a zone
 * @param name - zone name | parent_id [optional] - parent zone id | description [optional] - description
 * @return updated zone
 */
async function updateZone(data: IZone) {
  const url = `${API_SERVICES.zones}/${data.id}`;
  return axios({
    method: "patch",
    url,
    timeout: 30000,

    data: {
      ...data,
      settings: data.settings ?? null,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

/**
 * This function to delete zone
 * @param id: Id to delete zone
 * @return delete response
 */
async function deleteZone(id: string) {
  const url = `${API_SERVICES.zones}/${id}`;
  return axios({
    method: "delete",
    url,
    timeout: 30000,

    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return error?.response;
    });
}
