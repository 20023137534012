import { Grid } from "@mui/material";
import { Control, SubmitHandler, UseFormHandleSubmit } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SubTitle from "components/SMTitles/SubTitle";
import { ISubject } from "interfaces/subject.interface";
import { ICustomField, ITenantPublic } from "interfaces/tenant.interface";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";

import CustomFieldGridItem from "components/CustomField/CustomField";

export default function AdditionalSubjectInformation({
  create = true,
  tenant,
  onSubmit,
  handleSubmit,
  control,
}: {
  create?: boolean;
  tenant: ITenantPublic;
  onSubmit: SubmitHandler<ISubject>;
  handleSubmit: UseFormHandleSubmit<ISubject, ISubject>;
  control: Control<ISubject>;
}) {
  const subject_custom_fields = tenant?.custom_fields.filter(
    (cf: ICustomField) => cf.entity === "subject",
  );
  const { t } = useTranslation();
  return (
    <div>
      <BoxSubSectionStyled>
        <SubTitle text={t("additional_subject_information_title")} />
        <BoxSubSectionContentStyled>
          <Grid container rowSpacing={0} columnSpacing={2.5}>
            {tenant && tenant?.custom_fields ? (
              Object.keys(subject_custom_fields).map((key: string) => (
                <CustomFieldGridItem
                  key={key}
                  create={create}
                  // @ts-ignore to ignore index type not found
                  custom_field={subject_custom_fields[key]}
                  control={control}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                />
              ))
            ) : (
              <></>
            )}
          </Grid>
        </BoxSubSectionContentStyled>
      </BoxSubSectionStyled>
    </div>
  );
}
