import { ErrorOutline } from "@mui/icons-material";
import {
  DivWrapper,
  ErrorOutlineWrapperStyled,
  ValueWrapperNoWidth,
} from "./components/NightReportStyledComponents";

export const NightReportColumnNoData = ({ message }: { message: string }) => (
  <DivWrapper sx={{ justifyContent: "left" }}>
    <ErrorOutlineWrapperStyled>
      {" "}
      <ErrorOutline />{" "}
    </ErrorOutlineWrapperStyled>
    <div> {message}</div>
  </DivWrapper>
);

export const NightReportColumnNoAlert = ({
  value,
}: {
  value: string | number;
}) => (
  <DivWrapper>
    <ValueWrapperNoWidth
      dangerouslySetInnerHTML={{ __html: `${value}` || "-" }}
    />
  </DivWrapper>
);
