import { useTranslation } from "react-i18next";

import { IFilter } from "interfaces/filters.interface";

import {
  getSessionStartTimeOptions,
  getSleephistrotFilterOptions,
} from "./MenuOptions";
import HourSlider from "./HourSlider";
import MenuOptionsComponent from "./MenuOptionsComponent";
import TimeRange from "./TimeRange";

type ISessionRange = { start: string; end: string };

type RenderSleepHistoryFilterOptionsProps = {
  type: keyof IFilter;
  handleMenuClose: () => void;
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: (update: (prev: IFilter) => IFilter) => void;
};

function RenderSleepHistoryFilterOptions({
  type,
  handleMenuClose,
  sleepHistoryFilters,
  setSleepHistoryFilters,
}: RenderSleepHistoryFilterOptionsProps) {
  const { t } = useTranslation();

  const setFilterState = (key: keyof IFilter, val: string) => {
    let value: string | ISessionRange = val;
    if (key === "sessionStartTime") {
      const [start, end] = val.split("_");
      value = {
        start,
        end,
      };
    }
    setSleepHistoryFilters((prev: IFilter) => {
      return {
        ...prev,
        [key]: value,
      };
    });
    handleMenuClose();
  };

  const getSelectedSessionStartTime = () => {
    return sleepHistoryFilters.sessionStartTime.start &&
      sleepHistoryFilters.sessionStartTime.end
      ? `${sleepHistoryFilters.sessionStartTime.start}_${sleepHistoryFilters.sessionStartTime.end}`
      : "";
  };

  if (type === "timeInBed") {
    return (
      <MenuOptionsComponent
        options={getSleephistrotFilterOptions()}
        customComponent={
          <HourSlider
            onSelect={(val: string) => setFilterState("timeInBed", val)}
            selected={sleepHistoryFilters.timeInBed}
          />
        }
        title={t("show_sessions_with_time_in_bed")}
        onSelect={(val: string) => setFilterState("timeInBed", val)}
        selected={sleepHistoryFilters.timeInBed}
      />
    );
  }
  if (type === "sessionStartTime") {
    return (
      <MenuOptionsComponent
        options={getSessionStartTimeOptions()}
        customComponent={
          <TimeRange
            onSelect={(val: string) => setFilterState("sessionStartTime", val)}
            selected={getSelectedSessionStartTime()}
          />
        }
        title={t("show_sessions_with_start_time")}
        selected={getSelectedSessionStartTime()}
        onSelect={(val: string) => setFilterState("sessionStartTime", val)}
      />
    );
  }
  if (type === "totalSleepTime") {
    return (
      <MenuOptionsComponent
        options={getSleephistrotFilterOptions()}
        customComponent={
          <HourSlider
            onSelect={(val: string) => setFilterState("totalSleepTime", val)}
            selected={sleepHistoryFilters.totalSleepTime}
          />
        }
        title={t("show_sessions_with_total_sleep_time")}
        onSelect={(val: string) => setFilterState("totalSleepTime", val)}
        selected={sleepHistoryFilters.totalSleepTime}
      />
    );
  }
  return <></>;
}

export default RenderSleepHistoryFilterOptions;
