import { useTranslation } from "react-i18next";

import { secondsToTime } from "utils/date.util";
import { useCallback } from "react";

export const useFormatDuration = (): ((seconds: number) => string) => {
  const { t } = useTranslation();

  return useCallback(
    (seconds) => {
      const { h, m } = secondsToTime(seconds);

      const result = [];

      if (h > 0) {
        result.push(h + t("hours_abbreviation_lc"));
      }

      if (m > 0 || !h) {
        result.push(m + t("minutes_abbreviation_lc"));
      }

      return result.join(" ");
    },
    [t],
  );
};
