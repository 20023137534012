import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { ApplyMargin } from "styled/StylesStyled";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import { IZone } from "interfaces/zone.interface";
import Maintitle from "components/SMTitles/MainTitle";

import ZoneInformation from "../zones/zoneSettings/components/ZoneInformation";

function CreateZone() {
  const { t } = useTranslation();
  const [zone, setZone] = useState<IZone>();

  if (zone === undefined) {
    const emptyZone: IZone = {
      id: "",
      name: "",
      description: "",
      parent_id: "",
      object: "zone",
      level: 0,
      path: "",
    };
    setZone(emptyZone);
  }

  return (
    <BoxPadding pad={24}>
      <ApplyMargin bottom={20}>
        <Maintitle text={t("create_zone")} showBack />
      </ApplyMargin>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12} md={12}>
          {zone && <ZoneInformation create zone={zone} />}
        </Grid>
      </Grid>
    </BoxPadding>
  );
}

export default CreateZone;
