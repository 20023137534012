import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import Maintitle from "components/SMTitles/MainTitle";
import UseAdminContext from "hooks/UseAdminContext";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { getDeviceByID } from "store/actions/administration/deviceActions";
import { getAllSubjects } from "store/actions/administration/subjectActions";
import { ChipStyled, LabelStyled } from "styled/CommonStyled";
import { ApplyMargin } from "styled/StylesStyled";
import { getTimeDifferenceAsHM } from "utils/administration/zone.devices";
import DeviceInformation from "./components/DeviceInformation";
import DeviceSettingsComponent from "./components/DeviceSettingsComponent";
import SubjectDropDown from "./components/SubjectDropDown";
import { useAppDispatch, useAppSelector } from "store";

export const DeviceViewPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [device, setDevice] = useState<IDevice>();
  const [subjects, setSubject] = useState<ISubject[]>([]);
  const [nodevice, setNodevice] = useState<boolean>(false);

  const { zones, selectedZone, zoneEntity } = UseAdminContext();

  const deviceState = useAppSelector((state) => state.deviceReducer);

  const subjectState = useAppSelector((state) => state.subjectReducer);

  // get all subjects
  useEffect(() => {
    setNodevice(false);
    if (zoneEntity && selectedZone && zoneEntity[selectedZone]) {
      dispatch(getAllSubjects(zoneEntity[selectedZone].path));
      dispatch(getDeviceByID(id));
    }
  }, [selectedZone, zoneEntity, dispatch, id]);

  // filter current subject
  useEffect(() => {
    if (deviceState && deviceState?.devices) {
      const dvc = deviceState.devices.find((item: IDevice) => item.id === id);

      if (dvc) {
        setDevice(dvc);
      } else {
        setNodevice(true);
      }
    }
  }, [deviceState, id]);

  useEffect(() => {
    if (!subjectState.subjects) return;

    const filteredSubject = subjectState.subjects.filter((item: ISubject) => {
      return (
        item.zone_id === selectedZone &&
        (item?.devices?.data?.length === 0 || item?.devices?.data[0].id === id)
      );
    });
    setSubject(filteredSubject);
  }, [subjectState?.subjects, selectedZone, id]);

  const getStatusElement = () => {
    if (device) {
      const status = device.online ? "online" : "offline";

      return (
        <ChipStyled
          label={t(status)}
          bg={status === "online" ? "green" : "red"}
        />
      );
    }
    return <></>;
  };

  const lastOnline: string | null = getTimeDifferenceAsHM(
    device?.latest_connectivity_event_at || "",
  );

  if (nodevice) {
    return (
      <BoxPadding pad="0px 24px 24px 4px">{t("no_device_found")}</BoxPadding>
    );
  }

  return (
    <BoxPadding pad="0px 24px 24px 4px">
      {device && (
        <ApplyMargin bottom={33} top={1}>
          <Maintitle text={device?.name || ""} showBack />
          <ApplyMargin top={8}>
            <Stack spacing={1} direction="row">
              {getStatusElement()}
              <LabelStyled sx={{ alignItems: "center", display: "flex" }}>
                {`${t("Last online")} ${
                  lastOnline ? `${lastOnline}` : ` ${t("unknown")}`
                }`}{" "}
              </LabelStyled>
            </Stack>
          </ApplyMargin>
        </ApplyMargin>
      )}

      <Grid container rowSpacing={0} columnSpacing={2.5} marginBottom={3}>
        <Grid item xs={12} md={4}>
          {device && <DeviceInformation device={device} zones={zones} />}
        </Grid>
        <Grid item xs={12} md={8}>
          {device && (
            <ApplyMargin bottom={20}>
              <SubjectDropDown device={device} subjects={subjects} />{" "}
            </ApplyMargin>
          )}
          {device && device.settings && (
            <DeviceSettingsComponent device={device} />
          )}
        </Grid>
      </Grid>
    </BoxPadding>
  );
};

export default DeviceViewPage;
