import { axiosGET, axiosGETWithParams } from "services/axios/axios";

import { API_SERVICES } from "../../constants/constants";

export const sleepHistoryServices = {
  fetchSleepHistory,
  fetchSleepDetails,
  fetchSleepHistoryByID,
};

async function fetchSleepHistory({
  from,
  to,
  subject_id,
  totalSleepTime,
  timeInBed,
  sessionStartTime,
  latestSession,
}: {
  from: string;
  to: string;
  subject_id: string;
  latestSession: boolean;
  totalSleepTime?: string | null;
  timeInBed?: string | null;
  sessionStartTime?: { start: string; end: string };
}) {
  const queryParams: { [key: string]: any } = {
    type: "vitalthings-somnofy-sm100-session",
    limit: 200,
    sort: "desc",
    subject_id,
    from,
    to,
  };
  if (!latestSession) {
    if (timeInBed !== null) {
      queryParams.time_in_bed = JSON.stringify({
        gte: timeInBed && !Number.isNaN(timeInBed) ? +timeInBed * 60 : 0,
      });
    }
    if (totalSleepTime !== null) {
      queryParams.time_asleep = JSON.stringify({
        gte:
          totalSleepTime && !Number.isNaN(totalSleepTime)
            ? +totalSleepTime * 60
            : 0,
      });
    }
  } else {
    queryParams.time_in_bed = JSON.stringify({ gte: 12000 });
  }

  if (sessionStartTime?.start && sessionStartTime.end) {
    // no backend handling for this at the moment. so we did it on the front-end
  }
  return axiosGETWithParams(`${API_SERVICES.sessions}`, queryParams);
}

async function fetchSleepHistoryByID(id: string) {
  return axiosGET(`${API_SERVICES.sessions}/${id}`);
}

async function fetchSleepDetails(id: string) {
  return axiosGET(`${API_SERVICES.sessions}/${id}?include_epoch_data=true`);
}
