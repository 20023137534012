import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import { INightReportFilterSettings } from "interfaces/nightReport.interface";
import UseDashboardContext from "hooks/UseDashboardContext";

import FilterTag from "./FilterTag";

function NightReportFilterTags() {
  const { t } = useTranslation();

  const { nightReportFilter, setNightReportFilter } = UseDashboardContext();

  const onDeleteFilter = (
    key: keyof INightReportFilterSettings,
    value: boolean,
  ) => {
    setNightReportFilter?.((prev) => ({ ...prev, [key]: value }));
  };

  if (!nightReportFilter?.showOnlyBadNights) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <FilterTag
        filterLabel={{ text: t("only_view_bad_night_sleep") }}
        background={"teal"}
        onDelete={() => onDeleteFilter("showOnlyBadNights", false)}
        onClick={() => onDeleteFilter("showOnlyBadNights", false)}
      />
    </Box>
  );
}

export default NightReportFilterTags;
