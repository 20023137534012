import { Box, Button, Typography } from "@mui/material";

type ErrorPageProps = {
  title: string;
  buttonText: string;
  buttonOnClick: () => void;
};

export const ErrorPage = ({
  title,
  buttonOnClick,
  buttonText,
}: ErrorPageProps) => (
  <Box
    sx={{ textAlign: "center", alignContent: "center", margin: "20vh auto" }}
  >
    <Typography variant="h5" component="p" gutterBottom>
      {title}
    </Typography>
    <Button variant={"contained"} onClick={buttonOnClick}>
      {buttonText}
    </Button>
  </Box>
);
