import { Grid } from "@mui/material";
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormResetField,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import AutoSuggestFormDropDown from "components/AutoSuggestSelect/AutoSuggestFormDropDown";
import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import SubTitle from "components/SMTitles/SubTitle";
import UseAdminContext from "hooks/UseAdminContext";
import { ISubject } from "interfaces/subject.interface";
import { Dispatch, SetStateAction } from "react";
import { InputLabelStyled } from "styled/CommonStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import { ApplyMargin } from "styled/StylesStyled";
import { generateBirthYearOptions } from "utils/userManagement";
import { getZoneDropDownOptions } from "utils/zone.util";
import { useAppSelector } from "store";

export default function SubjectInformation({
  create,
  subject,
  disable,
  onSubmit,
  handleSubmit,
  control,
  resetField,
  setOpenDialog,
}: {
  create?: boolean;
  subject: ISubject;
  disable?: boolean;
  onSubmit: SubmitHandler<ISubject>;
  handleSubmit: UseFormHandleSubmit<ISubject, ISubject>;
  control: Control<ISubject>;
  resetField: UseFormResetField<ISubject>;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  const authUserZones = useAppSelector(
    // TODO: Fix defaulting
    (state) => state.authReducerV1.authorizedZones ?? [],
  );

  // use context
  const { zones } = UseAdminContext();

  return (
    <div>
      {/* Subject Information */}
      <BoxSubSectionStyled>
        <SubTitle text={t("Subject Information")} />
        <BoxSubSectionContentStyled>
          <Grid container rowSpacing={0} columnSpacing={2.5}>
            <Grid item xs={12} md={6}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>
                  {t("Zone")}{" "}
                </InputLabelStyled>
              </ApplyMargin>
              <Controller
                name="zone_id"
                rules={{ required: `${t("error_message_subject_zone_id")}` }}
                control={control}
                render={({ field, fieldState, formState }) => (
                  <SMFormDropDown
                    field={field}
                    disabled={!!disable}
                    fieldState={fieldState}
                    formState={formState}
                    options={getZoneDropDownOptions(
                      zones,
                      authUserZones.map((item) => item.id) || [],
                    )}
                    width="100%"
                    onUpdate={() => {
                      if (!create) {
                        if (subject?.devices?.data?.length) {
                          setOpenDialog(true);
                        } else {
                          handleSubmit(onSubmit)();
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>
                  {t("Subject ID")}{" "}
                </InputLabelStyled>
                <Controller
                  rules={{
                    required: `${t("error_message_subject_identifier")}`,
                  }}
                  name="identifier"
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <SMFormTextField
                      field={field}
                      disabled={!!disable}
                      placeholder={t("create_subject_id_placeholder")}
                      formState={formState}
                      fieldState={fieldState}
                      inlineEdit={!create}
                      onCancel={() => {
                        resetField("identifier");
                      }}
                      onUpdate={() => {
                        handleSubmit(onSubmit)();
                      }}
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>
            <Grid item xs={12} md={6}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>
                  {t("Description (optional)")}
                </InputLabelStyled>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <SMFormTextField
                      field={field}
                      formState={formState}
                      disabled={!!disable}
                      fieldState={fieldState}
                      inlineEdit={!create}
                      placeholder={
                        disable
                          ? ""
                          : t("create_subject_description_placeholder")
                      }
                      onCancel={() => {
                        resetField("name");
                      }}
                      onUpdate={() => {
                        handleSubmit(onSubmit)();
                      }}
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>
            <Grid item xs={12} md={6}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>{t("Sex")}</InputLabelStyled>
                <Controller
                  name="sex"
                  rules={{
                    required: `${t("error_message_subject_sex")}`,
                    validate: {
                      isSexSelected: (value) => {
                        if (value === "male" || value === "female") {
                          return true;
                        }
                        return `${t("error_message_subject_sex")}`;
                      },
                    },
                  }}
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <SMFormDropDown
                      field={field}
                      disabled={!!disable}
                      fieldState={fieldState}
                      formState={formState}
                      placeholder={t("Select sex")}
                      options={[
                        {
                          id: "1",
                          name: t("Male"),
                          value: "male",
                        },
                        {
                          id: "2",
                          name: t("Female"),
                          value: "female",
                        },
                      ]}
                      width="100%"
                      onUpdate={() => {
                        if (!create) {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>
            <Grid item xs={12} md={6}>
              <ApplyMargin top={16}>
                <InputLabelStyled maginbottom={6}>
                  {t("Birth Year")}
                </InputLabelStyled>

                <Controller
                  name="birth_year"
                  control={control}
                  rules={{
                    required: `${t("error_message_subject_birth_year")}`,
                  }}
                  render={({ field, fieldState }) => (
                    <AutoSuggestFormDropDown
                      field={field}
                      disabled={!!disable}
                      placeholder={t("create_subject_birth_year_placeholder")}
                      options={generateBirthYearOptions()}
                      fieldState={fieldState}
                      onUpdate={() => {
                        if (!create) {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  )}
                />
              </ApplyMargin>
            </Grid>
          </Grid>
        </BoxSubSectionContentStyled>
      </BoxSubSectionStyled>
    </div>
  );
}
