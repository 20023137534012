import { Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import { format } from "date-fns";
import { createRef, memo, useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";
import { getLangKey } from "utils/common/locale.util";

type ClockProps = {
  size: number;
  time: Date | null;
  title: string;
  datetime: boolean;
};

const Clock = ({ size, time, title, datetime }: ClockProps) => {
  const chartRef = createRef<HighchartsReactRefObject>();
  const options = getPlotOptions(size, toTimeObject(time));
  const monthDayString = time
    ? getLangKey() === "en"
      ? format(time, "MMM-d")
      : format(time, "d-MMM")
    : "-";

  const onResize = useCallback(() => {
    chartRef.current?.chart.reflow();
  }, [chartRef]);

  const { ref } = useResizeDetector({
    handleWidth: true,
    onResize: onResize,
  });

  return (
    <div ref={ref}>
      <div>
        <Typography align="center">{title}</Typography>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          constructorType="chart"
          options={options}
        />
        {!datetime ? (
          <Typography variant="h4" align="center">
            {time ? format(time, "HH:mm") : "-"}
          </Typography>
        ) : (
          <Typography variant="h4" align="center">
            {time ? (
              <>
                {format(time, "HH:mm")}
                <br />
                {`(${monthDayString})`}
              </>
            ) : (
              "-"
            )}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default memo(Clock);

const toTimeObject = (time: Date | null) => ({
  hours: time?.getHours() ?? NaN,
  minutes: time?.getMinutes() ?? NaN,
  seconds: time?.getSeconds() ?? NaN,
});

const getPlotOptions = (height: number, clockTime: any) => {
  const clockHours = clockTime.hours + clockTime.minutes / 60;
  const clockMinutes =
    (clockTime.minutes * 12) / 60 + (clockTime.seconds * 12) / 3600;

  return {
    chart: {
      type: "gauge",
      backgroundColor: "transparent",
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height,
    },

    credits: {
      enabled: false,
    },

    title: {
      text: null,
    },

    pane: {
      size: "110%",
    },

    yAxis: {
      labels: {
        distance: -15,
        style: {
          color: "#aaa",
        },
      },
      min: 0,
      max: 12,
      lineWidth: 0,
      showFirstLabel: false,

      minorTickInterval: "auto",
      minorTickWidth: 1,
      minorTickLength: 2,
      minorTickPosition: "inside",
      minorGridLineWidth: 0,
      minorTickColor: "#777",

      tickInterval: 1,
      tickWidth: 2,
      tickPosition: "inside",
      tickLength: 6,
      tickColor: "#666",
      title: null,
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        data: [
          {
            id: "hour",
            y: clockHours,
            dial: {
              radius: "60%",
              baseWidth: 4,
              baseLength: "95%",
              rearLength: 0,
            },
          },
          {
            id: "minute",
            y: clockMinutes,
            dial: {
              baseLength: "95%",
              rearLength: 0,
            },
          },
        ],
        animation: false,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };
};
