import { Navigate, Route, Routes } from "react-router-dom";

import { AppRoute } from "interfaces/router.interface";
import { ContentWrapper } from "styled/ContainerStylesStyled";

import AppVersion from "components/AppVersion/AppVersion";
import { FlexOne } from "components/ResizablePanel/SplitPanelStyledComponents";
import CreateSubject from "../create/CreateSubject";
import CreateUser from "../create/CreateUser";
import CreateZone from "../create/CreateZone";
import ZoneLandingPage from "../zones/ZoneLandingPage";
import ZoneSettingsPage from "../zones/zoneSettings/ZoneSettingsPage";

const adminRoutes: AppRoute[] = [
  {
    path: "zones/*",
    element: <ZoneLandingPage />,
  },
  {
    index: true,
    element: <Navigate to={"/administration/zones/devices"} />,
  },
  {
    path: "create-subject",
    element: <CreateSubject />,
  },
  {
    path: "create-zone",
    element: <CreateZone />,
  },
  {
    path: "create-user",
    element: <CreateUser />,
  },
  {
    path: "settings",
    element: <ZoneSettingsPage />,
  },
];

export const AdminMainContainer = () => {
  return (
    <FlexOne
      bg="light"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "auto",
      }}
    >
      <ContentWrapper
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
        <Routes>
          {adminRoutes.map((route) => (
            <Route key={"path" in route ? route.path : ""} {...route} />
          ))}
        </Routes>
        <AppVersion />
      </ContentWrapper>
    </FlexOne>
  );
};

export default AdminMainContainer;
