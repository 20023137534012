import { ReactElement } from "react";
import { styled } from "@mui/material/styles";

const SomnofyVideoSrc =
  "https://somnofy.ams3.cdn.digitaloceanspaces.com/Film/TheSomnofy_1280x720.mp4";

const StyledRoot = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  overflow: "hidden",
  maxHeight: "100vh",
});

const StyledVideo = styled("video")({
  height: "100vh",
  objectFit: "cover",
  maxWidth: "100vw",
  overflow: "hidden",
});

const LandingComponent = (): ReactElement => {
  return (
    <StyledRoot>
      <StyledVideo autoPlay muted loop>
        <source src={SomnofyVideoSrc} />
      </StyledVideo>
    </StyledRoot>
  );
};

export default LandingComponent;
