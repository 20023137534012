import { useState, useEffect, useId } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import * as LOCAL_STORAGE from "utils/localStorage";
import { INightReportFilterSettings } from "interfaces/nightReport.interface";
import UseDashboardContext from "hooks/UseDashboardContext";

// Menu options
function NightReportFilterDropdown() {
  const { t } = useTranslation();

  const labelId = useId();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { nightReportFilter, setNightReportFilter } = UseDashboardContext();

  // store filter
  useEffect(() => {
    LOCAL_STORAGE.setNightReportFilter(nightReportFilter);
  }, [nightReportFilter]);

  const setState = (key: keyof INightReportFilterSettings, val: boolean) => {
    setNightReportFilter?.((prev) => ({ ...prev, [key]: val }));
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={(event) => setAnchorEl(event.currentTarget)}
        startIcon={<FilterListIcon />}
        variant={"outlined"}
        color={"primary"}
      >
        {t("filters")}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          dense
          onClick={() => {
            setState(
              "showOnlyBadNights",
              !nightReportFilter?.showOnlyBadNights,
            );
            handleClose();
          }}
        >
          <ListItemIcon>
            <Checkbox
              edge={"start"}
              checked={!!nightReportFilter?.showOnlyBadNights}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={t("only_view_bad_night_sleep")} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default NightReportFilterDropdown;
