import { createContext, Dispatch, SetStateAction } from "react";

import { IZone } from "interfaces/zone.interface";

export type AdministrationContextType = {
  zones: IZone[];
  selectedZone: string;
  setSelectedZone?: Dispatch<SetStateAction<string>>;
  zoneEntity?: { [key: string]: IZone };
};

export const AdministrationContext = createContext<AdministrationContextType>({
  zones: [],
  selectedZone: "",
});
