import { useTheme } from "@mui/material/styles";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { createRef, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { SleepStagesOptionsType } from "interfaces/sleephistory.interface";
import {
  chartType as chartTypeOptions,
  ChartType,
  HighchartsType,
  SleepHistorySelectedDates,
} from "../sleepHistoryTypes";
import { useFormatDuration } from "hooks/useFormatDuration";
import { getSleepHistoryStagesChartOptions } from "./SleepHistoryStagesChartOptions";

interface SleepHistoryStagesChartMemoProps {
  onChartMouseOver: (e: Event) => void;
  options: SleepStagesOptionsType;
  selectedDates: SleepHistorySelectedDates;
  chartType: ChartType;
}

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
});

function SleepHistoryStagesChartMemo({
  onChartMouseOver,
  options,
  selectedDates,
  chartType,
}: SleepHistoryStagesChartMemoProps) {
  const highchartsType: HighchartsType =
    chartType === chartTypeOptions.column_chart ? "column" : "areaspline";
  const startDateMs = new Date(selectedDates.from).getTime();
  const endDateMs = new Date(selectedDates.to).getTime();

  const chartRef = createRef<HighchartsReactRefObject>();
  const { t } = useTranslation();
  const formatDuration = useFormatDuration();
  const theme = useTheme();

  const sleepHistoryStagesChartOptions = getSleepHistoryStagesChartOptions({
    onChartMouseOver,
    options,
    t,
    theme,
    chartType,
    highchartsType,
    startDateMs,
    endDateMs,
    formatDuration,
  });

  const onResize = useCallback(() => {
    chartRef.current?.chart.reflow();
  }, [chartRef]);

  const { ref } = useResizeDetector({
    handleWidth: true,
    skipOnMount: true,
    onResize: onResize,
  });

  return (
    <div ref={ref}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        options={sleepHistoryStagesChartOptions}
      />
    </div>
  );
}

const SleepHistoryStagesChart = memo(SleepHistoryStagesChartMemo);
export default SleepHistoryStagesChart;
