import { useMemo, useState } from "react";

import { Order } from "interfaces/componentsTypes/tableComponentsTypes";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { IZone } from "interfaces/zone.interface";

type ISortConfig = {
  key: keyof IDevice | string;
  direction: Order;
};

type KeyOfIDevice = keyof Omit<IDevice, "metadata" | "devices">;
export const useSortableDeviceList = (
  items: IDevice[],
  subjectEntity: Record<string, ISubject>,
  config: ISortConfig | null,
  zoneEntity: Record<string, IZone>,
) => {
  const [sortConfig, setSortConfig] = useState<ISortConfig | null>(config);

  const sortedItems = useMemo(() => {
    const getValue = (item: IDevice): number | string => {
      if (sortConfig !== null) {
        switch (sortConfig.key) {
          case "status":
            return Number(item.online);
          case "zone":
            return zoneEntity[item.zone_id ?? ""]?.name?.toLowerCase() || "";
          case "name":
            return item.name?.toLowerCase() || "";
          case "subject_id":
            return item.subject_id
              ? subjectEntity[item.subject_id]?.identifier.toLowerCase()
              : "";

          default: {
            const val =
              (item[sortConfig.key as KeyOfIDevice] as string | number) || "";
            if (typeof val === "string") {
              return val.toLowerCase();
            }
            return val;
          }
        }
      }
      return "";
    };

    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a: IDevice, b: IDevice) => {
        const left = getValue(a);
        const right = getValue(b);
        if (left < right) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (left > right) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig, subjectEntity, zoneEntity]);

  const requestSort = (key: keyof IDevice | string) => {
    let direction: Order = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
