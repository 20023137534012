import CloseIcon from "@mui/icons-material/Close";
import { Breakpoint, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTheme, styled } from "@mui/material/styles";
import { Dispatch, ReactNode, SetStateAction, useRef } from "react";

const PopoverTextStlyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.main[70],
    cursor: "pointer",
    textTransform: "none",
  };
});

const DialogTitleStyled = styled(DialogTitle)(() => {
  const theme = useTheme();
  return {
    fontSize: 22,
    color: theme.palette.neutral[70],
    textTransform: "none",
    fontWeight: 500,
    margin: 0,
    padding: "32px 32px 25px 32px",
  };
});

const IconButtonStyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    position: "absolute",
    right: 23,
    top: 39,
    minWidth: 24,
    padding: 5,
    "& svg": {
      // width: 14,
      // height: 14,
      borderRadius: 0,
    },
  };
});

export default function SMPopoverDialog({
  popoverText,
  children,
  title,
  open,
  setOpen,
  maxWidth = "lg",
  onClose,
  minHeight,
}: {
  popoverText?: string;
  children?: ReactNode;
  title?: string;
  open: boolean;
  maxWidth?: false | Breakpoint | undefined;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  minHeight?: string | number;
}) {
  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
    if (onClose) {
      onClose();
    }
  };
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref}>
      {popoverText && (
        <PopoverTextStlyled onClick={() => setOpen && setOpen(true)}>
          {popoverText}
        </PopoverTextStlyled>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={maxWidth || "lg"}
        container={ref?.current}
      >
        <DialogTitleStyled sx={{ m: 0, p: 2 }}>
          {title}
          <IconButtonStyled aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButtonStyled>
        </DialogTitleStyled>
        <DialogContent
          // sx={{
          //   marginBottom: "49px",
          //   padding: "0px 33px"
          // }}
          sx={{ minHeight }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}
