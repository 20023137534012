import { TextField } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { ChangeEvent, useEffect, useState } from "react";
import { ErrorMessage } from "styled/StylesStyled";
import debounce from "utils/common/debounce";
import clockIcon from "../../assets/icons/clock-icon.svg";

const TextFieldStyled = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "borderRed",
})(({ borderRed }: { borderRed: number }) => {
  const theme = useTheme();
  return {
    border: "none",
    marginLeft: 0,
    width: "100%",
    "& .Mui-disabled": {
      background: theme.palette.neutral[5],
      color: theme.palette.neutral[80],
      cursor: "not-allowed",
      WebkitTextFillColor: theme.palette.neutral[80],
      borderBottom: "none",
    },
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      backgroundImage: `url(${clockIcon}) no-repeat center center`,
    },
    "& input": {
      padding: "10px 8px",
      borderBottom: borderRed
        ? `1px solid ${theme.palette.red[50]}`
        : `1px solid ${theme.palette.main[10]}`,
      color: theme.palette.neutral[70],
      borderRadius: 2,
      "&:focus": {
        borderBottom: `1px solid ${theme.palette.main[70]}`,
      },
    },
    "& button": {
      padding: 0,
      "& svg": {
        color: theme.palette.main[70],
      },
    },
    "& fieldset": {
      border: "none",
    },
  };
});

interface IProps {
  field: any; // Todo
  formState?: any; // Todo
  onUpdate?: () => void;
  disabled?: boolean;
}

function SMFormTimePicker({ field, formState, onUpdate, disabled }: IProps) {
  const [value, setValue] = useState<string>(field.value);

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const debouncedValueUpdate = debounce(() => {
    if (onUpdate) {
      onUpdate();
    }
  }, 1000);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    setValue(event.target.value);
    if (onUpdate) {
      debouncedValueUpdate();
    }
  };

  return (
    <>
      <TextFieldStyled
        {...field}
        value={value}
        disabled={disabled}
        inputProps={{ type: "time" }}
        onChange={onChange}
        borderRed={formState?.error?.message ? 1 : 0}
      />
      {formState?.error?.message && (
        <ErrorMessage>{formState?.error?.message}</ErrorMessage>
      )}
    </>
  );
}

export default SMFormTimePicker;
