import { Button, SvgIcon } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigate } from "hooks/useNavigate";

import {
  AuthorizationContext,
  DevicePermissionEnum,
  SubjectPermissionEnum,
  UserPermissionEnum,
} from "authorization/AuthorizationContext";
import { AppRoute, AppRouteWithLink } from "interfaces/router.interface";

const RouteContaner = styled("div")(() => {
  return {
    width: "100%",
  };
});

const TabWrapper = styled("div")(() => {
  const theme = useTheme();
  return {
    margin: "0 24px 24px 4px",
    height: 68,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "calc(100%-64px)",
    borderBottom: `1px solid ${theme.palette.neutral[5]}`,
    marginBottom: 16,
  };
});

const TabContentWrapper = styled("div")(() => {
  return {
    width: "100%",
  };
});

const Tabs = styled(Button)(({
  activetab = "false",
}: {
  activetab: string;
}) => {
  const theme = useTheme();
  return {
    color:
      activetab === "true" ? theme.palette.main[70] : theme.palette.neutral[50],
    fontSize: 14,
    padding: "8px 12px",
    marginRight: 16,
    background: activetab === "true" ? theme.palette.white : "none",
    boxShadow: activetab === "true" ? "0px 0.5px 4px rgba(0, 0, 0, 0.25)" : "",
    borderRadius: activetab === "true" ? 4 : 0,
    height: 36,
    marginTop: "1px",
    "&:hover": {
      background: "#FFF",
    },
  };
});

const SVGStyled = styled(SvgIcon)(({
  activetab = 0,
}: {
  activetab: number;
}) => {
  const theme = useTheme();
  return {
    color: activetab ? theme.palette.main[70] : theme.palette.neutral[50],
  };
});

type RouteTabsNewProps = {
  routes: AppRoute[];
};

function RouteTabsNew({ routes }: RouteTabsNewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = useContext(AuthorizationContext);

  const hasPermission = (route: AppRouteWithLink) => {
    if (
      route.link.name === "employees" &&
      permissions.indexOf(UserPermissionEnum["user.list"]) === -1
    ) {
      return false;
    }
    if (
      route.link.name === "Subjects" &&
      permissions.indexOf(SubjectPermissionEnum["subject.list"]) === -1
    ) {
      return false;
    }
    if (
      route.link.name === "Devices" &&
      permissions.indexOf(DevicePermissionEnum["device.list"]) === -1
    ) {
      return false;
    }
    return true;
  };

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <RouteContaner>
      <TabWrapper>
        {routes
          .filter((route) => "link" in route)
          .filter(hasPermission)
          .map(({ link }) => (
            <Tabs
              key={link.href}
              activetab={`${isActive(link.href)}`}
              onClick={() => navigate("/administration/zones/" + link.href)}
              startIcon={
                link.icon && (
                  <SVGStyled activetab={isActive(link.href) ? 1 : 0}>
                    {link.icon}
                  </SVGStyled>
                )
              }
            >
              {/* @ts-ignore TODO: Fix type issue */}
              <span> {t(link.name)}</span>
            </Tabs>
          ))}
      </TabWrapper>
      <TabContentWrapper>
        <Routes>
          {routes.map((route) => (
            <Route key={"path" in route ? route.path : ""} {...route} />
          ))}
        </Routes>
      </TabContentWrapper>
    </RouteContaner>
  );
}
export default RouteTabsNew;
