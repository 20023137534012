import axios from "axios";

import { IDevice, ISettings } from "interfaces/device.interface";

import { API_SERVICES } from "../../constants/constants";
import {
  handleError,
  handleResponse,
  showErrorFeedback,
} from "../responseHandler";
import { SUPPORTED_DEVICE_TYPES } from "constants/deviceConstants";

export const deviceServices = {
  fetchDevices,
  createDevice,
  updateDevice,
  fetchDevice,
};

/**
 * This function fetch all the device
 * @param none
 * @return all the devices in the system
 */
async function fetchDevices(path?: string) {
  const pathParam = path ? `?path=${path}` : "";
  const typesParam = `&types=${SUPPORTED_DEVICE_TYPES.join("&types=")}`;
  const url = `${API_SERVICES.devices}${pathParam}${typesParam}`;

  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function fetchDevice(id?: string) {
  const url = `${API_SERVICES.devices}/${id}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      // Automatic timezone is represented by "" in the backend, but by "Automatic" in the frontend.
      // Empty strings don't play well with forms, so we convert the timezone when fetching from the backend.
      if (response?.data?.data?.settings?.user?.time?.timezone === "") {
        response.data.data.settings.user.time.timezone = "Automatic";
      }
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

/**
 * This function create a device
 * @param name - device object
 * @return created device
 */
async function createDevice(data: IDevice) {
  const url = API_SERVICES.devices;
  return axios({
    method: "post",
    url,
    timeout: 30000,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

/**
 * This function to update a device
 * @param name - device object
 * @return created device
 */
async function updateDevice(
  id: string,
  data: IDevice | ISettings | { [key: string]: any },
) {
  const requestData = {
    ...data,
    // subject_id: !data.subject_id ? null : data.subject_id
  };
  // this is a workaround to convrt the string ('true' | 'false') to boolean, as the current dropdown component doesnt accept boolean as value
  const url = `${API_SERVICES.devices}/${id}`;
  return axios({
    method: "patch",
    url,
    timeout: 30000,
    data: requestData,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      // Automatic timezone is represented by "" in the backend, but by "Automatic" in the frontend.
      // Empty strings don't play well with forms, so we convert the timezone when fetching from the backend.
      if (response?.data?.data?.settings?.user?.time?.timezone === "") {
        response.data.data.settings.user.time.timezone = "Automatic";
      }
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}
