import { Grid } from "@mui/material";
import NoDeviceInZone from "components/NoDeviceInZone/NoDeviceInZone";
import UseDashboardContext from "hooks/UseDashboardContext";
import UseInterval from "hooks/UseInterval";
import { IMonitor } from "interfaces/monitor.interface";
import {
  AlertMetric,
  NightReportSubject,
} from "interfaces/nightReport.interface";
import { IZone } from "interfaces/zone.interface";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { fetchLiveMonitoring } from "store/actions/dashboard/monitorActions";
import SleepHistoryModal from "../SleepHistoryModel";
import MonitorZoneTiles from "./components/MonitorZoneTiles";
import SupportSettingsPopover from "./components/SupportSettingsPopover";
import { useAppDispatch, useAppSelector } from "store";

export interface LiveDataTableProps {
  zone: string;
  data: IMonitor[];
  path: string[];
}
export type DeviationMetric = {
  type: AlertMetric;
  amount: number;
  isPositiveDeviation: boolean;
};

export type DeviationAlerts = {
  subjectID: string;
  movement?: DeviationMetric;
  respiration_rate?: DeviationMetric;
  time_asleep?: DeviationMetric;
};

const SubjectOverview = () => {
  const { selectedZones, zoneEntity } = UseDashboardContext();
  const dispatch = useAppDispatch();
  const [liveMonitorData, setLiveMonitorData] = useState<LiveDataTableProps[]>(
    [],
  );
  const [showHiddenSettings, setShowHiddenSettings] = useState<boolean>(false);
  const [dashboardModalSubject, setDashboardModalSubject] =
    useState<NightReportSubject | null>(null);

  const liveMonitorReducer = useAppSelector(
    (state) => state.liveMonitorReducer,
  );

  const fetchLiveData = useCallback(() => {
    if (selectedZones && selectedZones.length) {
      dispatch(
        fetchLiveMonitoring(selectedZones.map((item: IZone) => item.id)),
      );
    }
  }, [dispatch, selectedZones]);

  const numTimesClicked = useRef(0);

  const onDocumentKeydown = useCallback((event: KeyboardEvent) => {
    if (event.code !== "KeyS" && numTimesClicked.current) {
      numTimesClicked.current = 0;
      return;
    }

    numTimesClicked.current += 1;
    if (numTimesClicked.current === 4) {
      setShowHiddenSettings(true);
      numTimesClicked.current = 0;
    }
  }, []);

  UseInterval(fetchLiveData, 30000);

  useEffect(() => {
    document.addEventListener("keypress", onDocumentKeydown);

    return () => {
      document.removeEventListener("keypress", onDocumentKeydown);
    };
  }, [onDocumentKeydown]);

  useEffect(() => {
    if (selectedZones && selectedZones.length) fetchLiveData();
  }, [selectedZones, fetchLiveData]);

  useEffect(() => {
    if (liveMonitorReducer.liveMonitor) {
      const result = _.groupBy(liveMonitorReducer.liveMonitor, "zone['id']");
      const liveData: LiveDataTableProps[] = [];
      selectedZones?.forEach((zone: IZone) => {
        if (result[zone.id] && zone.path) {
          liveData.push({
            zone: zone.id,
            path: zone?.path?.split("."),
            data: result[zone.id],
          });
        } else {
          liveData.push({
            zone: zone.id,
            path: zone?.path?.split(".") ?? [],
            data: [],
          });
        }
      });
      setLiveMonitorData(liveData);
    }
  }, [liveMonitorReducer.liveMonitor, selectedZones]);

  if (!selectedZones) return <div />;

  return (
    <div>
      <Grid container>
        {zoneEntity &&
          liveMonitorData?.map((liveData: LiveDataTableProps) =>
            liveData.data.length ? (
              <MonitorZoneTiles
                liveData={liveData}
                zoneEntity={zoneEntity}
                onTileClick={(item) => setDashboardModalSubject(item.subject)}
                key={liveData.zone}
              />
            ) : (
              <NoDeviceInZone
                zoneEntity={zoneEntity}
                paths={liveData.path}
                bg="white"
                key={liveData.zone}
              />
            ),
          )}
      </Grid>
      <SupportSettingsPopover
        showHiddenSettings={showHiddenSettings}
        setShowHiddenSettings={setShowHiddenSettings}
      />
      <SleepHistoryModal
        setDashboardModalSubject={setDashboardModalSubject}
        dashboardModalSubject={dashboardModalSubject}
      />
    </div>
  );
};

export default SubjectOverview;
