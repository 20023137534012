import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogActions, {
  dialogActionsClasses,
} from "@mui/material/DialogActions";
import DialogContent, {
  dialogContentClasses,
} from "@mui/material/DialogContent";
import DialogContentText, {
  dialogContentTextClasses,
} from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, styled } from "@mui/material/styles";

import { AlertIcon } from "components/icons";
import SMButtonNew from "components/SMButton/SMButtonNew";
import { Dispatch, SetStateAction } from "react";

const DialogStyled = styled(Dialog)(() => {
  const theme = useTheme();
  return {
    padding: "8px",
    [`& .${dialogClasses.root}`]: {
      padding: 8,
    },
    [`& .${dialogClasses.paper}`]: {
      "& h2": {
        fontSize: 16,
        color: theme.palette.neutral[80],
        fontWeight: 500,
        textDecoration: "none",
        textTransform: "none",
        padding: "24px 24px 16px 24px",
      },
      "& span": {
        "& svg": {
          float: "left",
          marginTop: 3,
          marginRight: 10,
        },
      },
      [`& .${dialogContentClasses.root}`]: {
        paddingTop: 0,
        marginBottom: 0,
        color: theme.palette.neutral[80],
        [`& .${dialogContentTextClasses.root}`]: {
          fontSize: 14,
          width: "100%",
          "& > p": {
            marginBottom: 8,
          },
        },
      },
      [`& .${dialogActionsClasses.root}`]: {
        padding: "0 24px 24px 24px",
      },
    },
  };
});

function SMAlertDialog({
  dialogDesc,
  title,
  buttonOk,
  open,
  setOpen,
  onCancel,
}: {
  title: string;
  dialogDesc: string;
  buttonOk: string;
  open: boolean;
  onCancel?: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <span>
            <AlertIcon />
          </span>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            dangerouslySetInnerHTML={{
              __html: dialogDesc,
            }}
            sx={{
              marginBottom: "20px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <SMButtonNew
            onClick={() => {
              handleClose();
              if (onCancel) {
                onCancel();
              }
            }}
            text={buttonOk}
            background="green"
          />
        </DialogActions>
      </DialogStyled>
    </>
  );
}
export default SMAlertDialog;
