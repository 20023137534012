import { Box, Checkbox } from "@mui/material";
import Menu, { MenuProps, menuClasses } from "@mui/material/Menu";
import { MenuItemProps, menuItemClasses } from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import {
  ChangeEvent,
  Dispatch,
  ElementType,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  RefAttributes,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import { IconMenuItem } from "./IconMenuItem";

const StyledMenu = styled(Menu)(() => {
  const theme = useTheme();
  return {
    [`& .${menuClasses.list}`]: {
      padding: 0,
      display: "flex",
      width: "384px",
      flexDirection: "column",
      alignItems: "flex-start",
      boxShadow:
        "box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);",
    },
    [`& .${menuItemClasses.root}`]: {
      padding: "6px 8px",
      "&:hover": {
        background: theme.palette.main[2],
      },
    },
  };
}) as typeof Menu;

// parent level
const StyledBox = styled(Box)(() => {
  const theme = useTheme();
  return {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.main[2],
    },
  };
});

// parent level
const StyledCheckbox = styled(Checkbox)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    [`&.Mui-checked`]: {
      color: theme.palette.main[70],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
});

export type NestedMenuItemProps = Omit<MenuItemProps, "button"> & {
  parentMenuOpen: boolean;
  component?: ElementType;
  label?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
  tabIndex?: number;
  disabled?: boolean;
  ContainerProps?: HTMLAttributes<HTMLElement> &
    RefAttributes<HTMLElement | null>;
  MenuPropsCustom?: Partial<Omit<MenuProps, "children">>;
  button?: true | undefined;
  subMenuEl: HTMLElement | null;
  setSubMenuEl: Dispatch<SetStateAction<HTMLElement | null>>;
  checkbox?: boolean;
  checked?: boolean;
  onCheckboxChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const NestedMenuItem = forwardRef<HTMLLIElement | null, NestedMenuItemProps>(
  function NestedMenuItem(props: NestedMenuItemProps, ref) {
    const {
      parentMenuOpen,
      label,
      leftIcon = null,
      children,
      className,
      tabIndex: tabIndexProp,
      ContainerProps: ContainerPropsProp = {},
      MenuPropsCustom,
      subMenuEl,
      setSubMenuEl,
      checkbox,
      onCheckboxChange,
      checked: checkBoxChecked,
      ...MenuItemPropsRest
    } = props;

    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = useRef<HTMLLIElement | null>(null);
    useImperativeHandle(ref, () => menuItemRef.current!);

    const containerRef = useRef<HTMLDivElement | null>(null);
    // @ts-ignore TODO: Fix this issue, or replace this component all together
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef<HTMLDivElement | null>(null);

    const toggleSubmenu = (e: MouseEvent<HTMLElement>) => {
      if (subMenuEl === e.currentTarget) {
        setSubMenuEl(null);
      } else {
        setSubMenuEl(e.currentTarget);
      }
    };

    let tabIndex;
    if (!props.disabled) {
      tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }
    const open = !!(subMenuEl && subMenuEl === menuItemRef.current);
    return (
      <Box
        aria-hidden="true"
        {...ContainerProps}
        ref={containerRef}
        tabIndex={tabIndex}
        sx={{ padding: 0 }}
      >
        <StyledBox>
          {checkbox && (
            <StyledCheckbox
              checked={!!checkBoxChecked}
              onChange={(
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => {
                if (checked) {
                  setSubMenuEl(menuItemRef.current);
                }
                if (onCheckboxChange) {
                  onCheckboxChange(event);
                }
              }}
              // onChange={checkBoxChecked ? onCheckboxChange : ()=>setSubMenuEl(menuItemRef.current)}
            />
          )}
          <IconMenuItem
            MenuItemPropsRest={MenuItemPropsRest}
            className={className}
            ref={menuItemRef}
            leftIcon={leftIcon}
            label={label}
            onClick={toggleSubmenu}
          />
        </StyledBox>

        {children && (
          <StyledMenu
            // Set pointer events to 'none' to prevent the invisible Popover div
            // from capturing events for clicks and hovers
            style={{
              borderRadius: "4px",
              boxShadow:
                "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
              pointerEvents: "none",
              width: "100%",
              minWidth: "384px",
            }}
            anchorEl={menuItemRef.current}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            transformOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
            open={open}
            autoFocus={false}
            disableAutoFocus
            disableEnforceFocus
            onClose={() => {
              setSubMenuEl(null);
            }}
            {...MenuPropsCustom}
          >
            <div
              ref={menuContainerRef}
              style={{ pointerEvents: "auto", width: "100%" }}
            >
              {children}
            </div>
          </StyledMenu>
        )}
      </Box>
    );
  },
);

NestedMenuItem.displayName = "NestedMenuItem";
export { NestedMenuItem };
