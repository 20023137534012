import { Box, Typography } from "@mui/material";

type NoDataPageProps = {
  title: string;
};

export const NoDataPage = ({ title }: NoDataPageProps) => (
  <Box
    sx={{ textAlign: "center", alignContent: "center", margin: "20vh auto" }}
  >
    <Typography variant="h5" component="p" gutterBottom>
      {title}
    </Typography>
  </Box>
);
