import { CardContent } from "@mui/material";
import { useEffect, useState } from "react";

import { ISleepEpochPlotData } from "interfaces/sleephistory.interface";
import { fetchSleepEpoch } from "store/actions/sleephistory/sleepEpochActions";
import { useAppDispatch, useAppSelector } from "store";
import { getSleepEpochDataPlotData } from "utils/sleepHistory/sleepEpochData";
import SleepEpochDataChart from "../charts/SleepEpochDataChart";
import { useEnableHeartRateMean } from "../hooks/useEnableHeartRateMean";

function SleepEpochData({ sessionId }: { sessionId: string }) {
  const dispatch = useAppDispatch();
  const [epochPlotData, setEpochPlotData] = useState<ISleepEpochPlotData>();

  const { enableHeartRateMean } = useEnableHeartRateMean();

  const sleepEpochData = useAppSelector(
    (state) => state.sleepEpochReducer.sleepEpoch,
  );

  useEffect(() => {
    if (sleepEpochData && sleepEpochData[sessionId]) {
      //
    } else {
      dispatch(fetchSleepEpoch(sessionId));
    }
  }, [sessionId, sleepEpochData, dispatch]);

  useEffect(() => {
    const epoch = sleepEpochData?.[sessionId];
    if (epoch) {
      const plotData: ISleepEpochPlotData = getSleepEpochDataPlotData(epoch);
      setEpochPlotData(plotData);
    }
  }, [sleepEpochData, sessionId]);

  return (
    <div>
      {epochPlotData && (
        <CardContent>
          <SleepEpochDataChart
            plotData={epochPlotData}
            enableHeartRateMean={enableHeartRateMean}
          />
        </CardContent>
      )}
    </div>
  );
}
export default SleepEpochData;
