import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { getCurrentDay } from "utils/common/locale.util";
import { getLocale } from "utils/date.util";

function DateTimeText() {
  const langKey = getLocale();

  const [currentDateTime, setCurrentDateTime] = useState<string>(
    getCurrentDay(langKey),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDay(langKey));
    }, 10 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [langKey]);

  return <Typography>{currentDateTime}</Typography>;
}
export default DateTimeText;
