import { Button, ButtonProps } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

import { IColorValue } from "interfaces/color.interface";

const ButtonStyled = styled(Button)(({
  bg = "green",
  outlined,
  disabledstyle,
}: {
  bg?: "green" | "red" | "none" | "yellow";
  outlined?: number;
  disabledstyle?: number;
}) => {
  const theme = useTheme();
  const outlineButton = bg === "none" || outlined;
  let background: IColorValue | "none" = "none";
  let hover = theme.palette.main[70];
  if (bg === "green") {
    background = theme.palette.main[70] as IColorValue;
    hover = theme.palette.main[70] as IColorValue;
  } else if (bg === "red") {
    background = theme.palette.red[50];

    hover = theme.palette.red[40];
  } else if (bg === "yellow") {
    background = theme.palette.red[50];

    hover = theme.palette.red[40];
  }

  if (outlineButton) {
    return {
      border: "none",
      color: theme.palette.neutral[70],
      background: "none",
      textTransform: "none",
      padding: "10px 24px",
      cursor: "pointer",
      "&:hover": {
        background: theme.palette.main[2],
        color: theme.palette.neutral[70],
      },
    };
  }
  return {
    background: disabledstyle ? theme.palette.neutral[5] : background,
    border: disabledstyle ? "none" : `1px solid ${background}`,
    color: disabledstyle ? "rgba(23, 23, 23, 0.6)" : "#FFF",
    fontSize: 16,
    padding: "6px 24px",
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      background: hover,
      color: "#FFF",
    },
  };
});

type Iprops = ButtonProps & {
  text: string;
  onClick: (event?: any) => void;
  submit?: boolean;
  background?: "green" | "red" | "none" | "yellow" | any;
  opacity?: number;
  cursor?: "pointer" | "not-allowed";
  outlineBtn?: boolean;
};

// Todo @fasalu, rename
function SMButtonNew({
  text,
  onClick,
  submit,
  background,
  cursor,
  opacity,
  outlineBtn,
  ...buttonProps
}: Iprops) {
  const { startIcon, disabled, sx } = buttonProps;

  return (
    <ButtonStyled
      onClick={() => onClick()}
      startIcon={startIcon}
      disabled={disabled}
      disabledstyle={disabled ? 1 : 0}
      bg={background}
      type={submit ? "submit" : "button"}
      outlined={outlineBtn ? 1 : 0}
      sx={{ opacity, cursor, ...sx }}
    >
      {text}
    </ButtonStyled>
  );
}

export default SMButtonNew;
